
import React, { useContext, useState, useEffect } from "react";
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  Dropdown,
  Collapse,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import setImage from "../../assets/images/set.jpg";
import {
  DevicesContext,
  DevicesActionsContext,
} from "../../Contexts/DevicesContext";
import {
  DeviceSetsActionsContext,
  DeviceSetsContext,
} from "../../Contexts/DeviceSetsContext";

const DeviceSetItemChecked = ({ DeviceSet,onCheck,onRemoveCheck,handlePreview ,checked=false}) => {
  const [isChecked,setIsChecked]=useState(checked);

  useEffect(()=>{
    setIsChecked(checked)
  },[checked])

  return (
    <Card style={{cursor:'pointer',width:150,height:'auto',marginRight:10,marginBottom:10}} 
    onClick={(e)=>{setIsChecked(!isChecked);if(isChecked){onRemoveCheck(DeviceSet.id)}else onCheck(DeviceSet.id)}}>
      
      <div style={{ display: "flex", justifyContent: "space-between",padding:5 }}>
          {isChecked?<span style={{color:'#383'}} className={'fa fa-check-square fa-2x'}/>
          :<span style={{color:'#aaa'}} className={'fa fa-square fa-2x'}/>}
         {/* <Button variant='light' onClick={()=>{handlePreview()}}><i className='fa fa-sitemap'/></Button> */}
           </div><Card.Img variant="top" style={{marginRight:20,marginLeft:20,aspectRatio:1,width:'auto',height:80}} src={setImage} />
      <Card.Body>
        <Card.Title>{DeviceSet.name}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default DeviceSetItemChecked;
