export const DeviceTypes = {
  1: {
    long: "Communication Bridge",
    short: "TTSL CB",
  },
  2: {
    long: "Measurement Bridge",
    short: "TTSL MB",
  },
  4: {
    long: "Sensor Bridge (BA)",
    short: "TTSL SB-C",
  },
  5: {
    long: "Sensor Bridge (BF)",
    short: "TTSL SB-M",
  },
};
export const SensorsTypes = {
  relative_humidity: {
    en: "Relative humidity",
    de: "Relative Luftfeuchtigkeit",
  },
  temperature: { en: "Temperature", de: "Temperatur" },
  amperage: { en: "Amperage", de: "Stromstärke" },
  building_moisture: { en: "Building moisture", de: "Gebäudefeuchtigkeit" },
};
