/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import * as moment from "moment";
import "moment/locale/de";

import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import {
  DeviceGroupContext,
  DeviceGroupActionsContext,
} from "../../Contexts/DeviceGroupContext";
import {
  DashboardActionsContext,
  DashboardContext,
} from "../../Contexts/DashboardContext";
import { DeviceSetsContext } from "../../Contexts/DeviceSetsContext";

const MeasurementGroupItem = () => {
  const { DeviceSetsList } = useContext(DeviceSetsContext);
  const [deviceSetName, setDeviceSetName] = useState("");
  const {
    setChartModalOpened,
    setEditModalOpenMode,
    setInfoModalOpened,
    setSelectedDeviceGroup,
    setChartParams,
    setOpenedGroupId,
  } = useContext(DashboardActionsContext);
  const {
    DeviceGroup,
    Configuration,
    SensorsList,
    ActiveSensorsCount,
    InActiveSensorsCount,
    DataSensorsCount,
    NoDataSensorsCount,
    cssClassName,
    ThresholdSensorsCount,
    AllSensorsList,
    GroupLastValues,
  } = useContext(DeviceGroupContext);
  const { OpenedGroupId } = useContext(DashboardContext);

  useEffect(() => {
    if (DeviceSetsList) {
      let set = DeviceSetsList.find((c) => c.project_id == DeviceGroup.id);
      if (set && set.name) setDeviceSetName(set.name);
    }
  }, [DeviceSetsList]);

  return (
    <tr
      onClick={(e) => {
        e.preventDefault();
        if (OpenedGroupId === DeviceGroup.id) setOpenedGroupId(0);
        else setOpenedGroupId(DeviceGroup.id);
      }}
      style={{ cursor: "pointer" }}
      className={cssClassName}
    >
      <td className="column column1">
        <div className="title">{DeviceGroup.title} </div>
        {Configuration && <div className="desc">{Configuration.location} </div>}
        {<div className="desc">{deviceSetName} </div>}
      </td>
      <td className="column column2">
        {Configuration && Configuration.starts_at ? (
          <div className="title">
            {moment(Configuration.starts_at).fromNow()}
          </div>
        ) : (
          <></>
        )}
        {Configuration && (
          <div className="desc">
            {moment(Configuration.starts_at).format("DD.MM.YYYY HH:mm")}
          </div>
        )}
      </td>
      <td className="column column3">
        {ThresholdSensorsCount > 0 ? (
          <p>
            {ThresholdSensorsCount}{" "}
            {ThresholdSensorsCount > 1 ? "Sensoren" : "Sensor"} außerhalb
            Grenzwert
          </p>
        ) : (
          <>
            {GroupLastValues && ActiveSensorsCount > 0 ? (
              GroupLastValues.length === ActiveSensorsCount ? (
                <div>
                  <i className="fa fa-check status-ok" />
                </div>
              ) : (
                <div>
                  <i className="fa fa-question-circle status-warn" />
                </div>
              )
            ) : (
              <div>
                <i className="fa fa-times status-warn" />
              </div>
            )}
          </>
        )}
      </td>
      <td className="column column4">
        {DataSensorsCount > 0 && (
          <div className="status-ok">
            {DataSensorsCount}{" "}
            {DataSensorsCount > 1 ? "Sensoren sind " : "Sensor ist "} aktiv
          </div>
        )}
        {NoDataSensorsCount > 0 && (
          <div className="status-warn">
            {NoDataSensorsCount}{" "}
            {NoDataSensorsCount > 1 ? "Sensoren" : "Sensor"} - keine{" "}
            {NoDataSensorsCount > 1 ? " aktuellen " : " aktuelle "}
            Daten
          </div>
        )}
        {ActiveSensorsCount === 0 && InActiveSensorsCount === 0 && (
          <div className="column4-label">
            <i className="fa fa-exclamation-circle status-warn" /> Keine Daten
            gefunden
          </div>
        )}
      </td>
      <td className="column column5">
        {AllSensorsList.length > 0 && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (OpenedGroupId === DeviceGroup.id) setOpenedGroupId(0);
              else setOpenedGroupId(DeviceGroup.id);
            }}
            aria-controls={"groupRow" + DeviceGroup.id}
            aria-expanded={OpenedGroupId === DeviceGroup.id}
          >
            <div>
              {OpenedGroupId === DeviceGroup.id ? (
                <i className="fa fa-chevron-circle-up" />
              ) : (
                <i className="fa fa-chevron-circle-down" />
              )}
            </div>
          </a>
        )}
        {SensorsList.length === 0 && <i className="fa fa-times status-warn" />}
      </td>
      <td className="column column6">
        {ActiveSensorsCount > 0 && InActiveSensorsCount === 0 && (
          <div>
            <i className="fa fa-check status-ok" />{" "}
          </div>
        )}
        {InActiveSensorsCount > 0 && (
          <>
            {ActiveSensorsCount > 0 && (
              <div className="status-ok">
                {ActiveSensorsCount}{" "}
                {ActiveSensorsCount > 1 ? "Sensoren" : "Sensor"} verbunden
              </div>
            )}
            <div className="status-warn">
              {InActiveSensorsCount}{" "}
              {InActiveSensorsCount > 1 ? "Sensoren" : "Sensor"} nicht verbunden
            </div>
          </>
        )}
      </td>
      <td className="column column7">
        <ButtonToolbar
          aria-label="Toolbar with button groups"
          style={{ padding: 5 }}
        >
          <ButtonGroup>
            <Button
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDeviceGroup(DeviceGroup);
                setChartParams({
                  type: "group",
                  object: {
                    ...DeviceGroup,
                    start_date: Configuration.starts_at,
                  },
                });
                setChartModalOpened(true);
              }}
            >
              <i className="buttons fa fa-line-chart" />
            </Button>{" "}
            <Button
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDeviceGroup(DeviceGroup);
                setEditModalOpenMode(2);
              }}
            >
              <i className="buttons fa fa-pencil" />
            </Button>{" "}
            <Button
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDeviceGroup(DeviceGroup);
                setInfoModalOpened(true);
              }}
            >
              <i className="buttons fa fa-sitemap" />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </td>
    </tr>
  );
};

export default MeasurementGroupItem;
