
import React, { useContext, useState, useEffect } from "react";
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  Dropdown,
  Collapse,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import setImage from "../../assets/images/set.jpg";
import {
  DevicesContext,
  DevicesActionsContext,
} from "../../Contexts/DevicesContext";
import {
  DeviceSetsActionsContext,
  DeviceSetsContext,
} from "../../Contexts/DeviceSetsContext";
const DeviceSetItem = ({ DeviceSet, style }) => {
  let history = useHistory();
  const {
    dispatchDeviceSet,
    setSelectedDeviceSet,
    setEditDeviceSetModalOpened,
    setInfoModalOpened,
  } = useContext(DeviceSetsActionsContext);

  return (
    <Card style={style}>
      <Card.Img variant="top" src={setImage} />
      <Card.Body>
        <Card.Title>{DeviceSet.name}</Card.Title>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button 
                onClick={(e) => {
                  setSelectedDeviceSet(DeviceSet);
                  history.push('/devices/'+DeviceSet.id)
                }}
                variant="primary" style={{ marginRight: 5 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fa fa-sitemap" style={{ marginRight: 15 }} />
              Geräte
            </div>
          </Button>
          <Dropdown id="dots-dropdown">
            <Dropdown.Toggle variant="light">
              <i className="fa fa-ellipsis-h" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
                onClick={(e) => {
                  setSelectedDeviceSet(DeviceSet);
                  setEditDeviceSetModalOpened(true);
                }}
              ><div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fa fa-info" style={{ width:20, marginRight: 15 }} />
              Info
            </div>
              </Dropdown.Item> */}
              <Dropdown.Item
                onClick={(e) => {
                  setSelectedDeviceSet(DeviceSet);
                  setEditDeviceSetModalOpened(true);
                }}
              ><div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fa fa-pencil" style={{ marginRight: 10 }} />
              Bearbeiten
            </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DeviceSetItem;
