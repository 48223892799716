import React, { useState, useReducer, useEffect, useContext } from "react";
import { api_base_url, api_headers } from "../Configs/Configs";
import Axios from "axios";
import { UserContext } from "./UserContext";
import { DeviceSetsContext } from "./DeviceSetsContext";

export const DevicesContext = React.createContext();
export const DevicesActionsContext = React.createContext();

const DevicesReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.device];
    case "BULK_ADD":
      return [...action.devices];
    case "INSERT":
      return [action.device, ...state];
    case "CLEAR":
      return [];
    case "EDIT":
      var edited = [...state].map((item) => {
        if (item.associated_id === action.device.associated_id) {
          return action.device;
        } else return item;
      });
      return edited;
    case "DELETE":
      var edited = [...state].filter(
        (item) => item.associated_id !== action.device.associated_id
      );
      return edited;
    default:
      return state;
  }
};

export const DevicesContextProvider = (props) => {
  const { User, checkUserSession } = useContext(UserContext);
  const { SelectedDeviceSet } = useContext(DeviceSetsContext);
  const [IsLoading, setIsLoading] = useState(true);
  const [CreateDeviceModalOpened, setCreateDeviceModalOpened] = useState(false);
  const [EditDeviceModalOpened, setEditDeviceModalOpened] = useState(false);
  const [ChartModalOpened, setChartModalOpened] = useState(false);
  const [InfoModalOpened, setInfoModalOpened] = useState(false);

  const [ChartParams, setChartParams] = useState("device");
  const [MeasurandsList, setMeasurandsList] = useState([]);
  const [DevicesList, setDevicesList] = useState([]);
  const [AssociatedDevicesList, setAssociatedDevicesList] = useState([]);
  const [DevicesTreeList, setDevicesTreeList] = useState([]);
  const [DevicesStatusList, dispatchDevice] = useReducer(DevicesReducer, []);

  const [SelectedDevice, setSelectedDevice] = useState();

  useEffect(() => {
    dispatchDevice({ type: "CLEAR" });
    loadData();
  }, [SelectedDeviceSet]);

  const loadData = async () => {
    const fetchData = async () => {
      setIsLoading(true);
      setDevicesList([]);
      if (!checkUserSession()) return;
      if (SelectedDeviceSet) {
        await Axios({
          method: "get",
          withCredentials: true,
          url: `${api_base_url}/apiMeasurementConfiguration/measurands/${User.id}`,
        }).then((response) => {
          if (response.data && response.status === 200) {
            let tempList = [];
            if (
              response.data.status_ok &&
              Array.isArray(response.data.status_ok)
            ) {
              let dtb = response.data.status_ok.find(
                (c) => c.device_set_id == SelectedDeviceSet.id
              );
              if (dtb && dtb.measurands) {
                tempList = tempList.concat(dtb.measurands);
              }
            }
            if (
              response.data.actually_owned &&
              Array.isArray(response.data.actually_owned)
            ) {
              let dtb = response.data.actually_owned.find(
                (c) => c.device_set_id == SelectedDeviceSet.id
              );
              if (dtb && dtb.measurands) {
                tempList = tempList.concat(dtb.measurands);
              }
            }
            if (
              response.data.associated &&
              Array.isArray(response.data.associated)
            ) {
              let dtb = response.data.associated.find(
                (c) => c.device_set_id == SelectedDeviceSet.id
              );
              if (dtb && dtb.measurands) {
                tempList = tempList.concat(dtb.measurands);
              }
            }
            setMeasurandsList(tempList);
          }
        });
      }
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDevices/associated/0/0?step=0`,
      }).then(async (response) => {
        let finalList = [];
        if (
          response.data &&
          response.data.devices &&
          Array.isArray(response.data.devices) &&
          response.status === 200
        ) {
          setAssociatedDevicesList(
            response.data.devices.filter((c) => c.active == 1)
          );
          let devicesList = response.data.devices.filter(
            (c) =>
              (!SelectedDeviceSet || c.device_set_id == SelectedDeviceSet.id) &&
              c.active == 1
          );
          setDevicesList(devicesList);
          await Axios({
            method: "get",
            withCredentials: true,
            url: `${api_base_url}/apiDeviceConfiguration/device_overview/0/0?step=0`,
          }).then((response) => {
            if (
              response.data &&
              response.data.own_devices &&
              Array.isArray(response.data.own_devices) &&
              response.status === 200
            ) {
              let list = response.data.own_devices;
              setDevicesTreeList(list);
              let addedIDs = [];
              if (SelectedDeviceSet) {
                list.forEach((dtb) => {
                  if (dtb.associated_id && dtb.associated_id != "null") {
                    let device = devicesList.find(
                      (c) => c.id == dtb.associated_id
                    );
                    if (device) dtb["device_set_id"] = device.device_set_id;
                    addedIDs.push(dtb.associated_id);
                    dtb.mtbs.forEach((mtb) => {
                      if (mtb.associated_id && mtb.associated_id != "null") {
                        let device = devicesList.find(
                          (c) => c.id == mtb.associated_id
                        );
                        if (device) mtb["device_set_id"] = device.device_set_id;
                        addedIDs.push(mtb.associated_id);
                        mtb.bwbs.forEach((bwb) => {
                          let device = devicesList.find(
                            (c) => c.id == bwb.associated_id
                          );
                          if (device)
                            bwb["device_set_id"] = device.device_set_id;
                          if (bwb.associated_id && bwb.associated_id != "null")
                            addedIDs.push(bwb.associated_id);
                        });
                      }
                    });
                  }
                });
                list
                  .filter(
                    (c) =>
                      !SelectedDeviceSet ||
                      c.device_set_id == SelectedDeviceSet.id
                  )
                  .forEach((element) => {
                    finalList.push(element);
                    // dispatchDevice({ type: "ADD", device: element });
                  });
                devicesList.forEach((item) => {
                  if (
                    !addedIDs.find((c) => c === item.id) &&
                    item.device_type_id == 1
                  ) {
                    let device = {
                      associated_id: item.id,
                      name: item.user_given_name,
                      device_type_id: item.device_type_id,
                      device_set_id: item.device_set_id,
                      deviceId: item.deviceId,
                      device_type: item.device_type,
                    };
                    finalList.push(device);
                    // dispatchDevice({ type: "ADD", device });
                  }
                });
              } else {
                devicesList.forEach((item) => {
                  if (!addedIDs.find((c) => c === item.id)) {
                    let device = {
                      associated_id: item.id,
                      name: item.user_given_name,
                      device_type_id: item.device_type_id,
                      device_set_id: item.device_set_id,
                      deviceId: item.deviceId,
                      device_type: item.device_type,
                    };
                    finalList.push(device);
                    // dispatchDevice({ type: "ADD", device });
                  }
                });
              }
            }
          });
        }
        dispatchDevice({ type: "BULK_ADD", devices: finalList });
      });
      setIsLoading(false);
    };

    fetchData();
  };

  const handleSuccessAddDevice = (newDeviceId) => {
    const fetchData = async () => {
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDevices/read_information/${newDeviceId}`,
      }).then(async (response) => {
        if (response.data && response.status === 200) {
          var device = {
            associated_id: response.data.associated_id,
            name: response.data.name,
            device_type_id: response.data.device_type_id,
            device_set_id: SelectedDeviceSet ? SelectedDeviceSet.id : "null",
            deviceId: response.data.deviceId,
            Unattached: response.data.device_type_id == 1 ? null : 0,
          };
          if (SelectedDeviceSet) {
            var formData = new FormData();
            formData.append("user_given_name", response.data.name);
            formData.append("device_set_id", SelectedDeviceSet.id);
            await Axios({
              method: "post",
              data: formData,
              withCredentials: true,
              url: `${api_base_url}/apiDevices/associated_properties_edited/${response.data.associated_id}`,
            });
          }
          dispatchDevice({ type: "ADD", device });
        }
      });
    };

    setCreateDeviceModalOpened(false);

    fetchData();
  };

  return (
    <DevicesContext.Provider
      value={{
        IsLoading,
        DevicesList,
        DevicesTreeList,
        SelectedDevice,
        DevicesStatusList,
        ChartParams,
        CreateDeviceModalOpened,
        EditDeviceModalOpened,
        ChartModalOpened,
        InfoModalOpened,
        MeasurandsList,
        AssociatedDevicesList,
      }}
    >
      <DevicesActionsContext.Provider
        value={{
          setCreateDeviceModalOpened,
          setEditDeviceModalOpened,
          setSelectedDevice,
          setChartModalOpened,
          setInfoModalOpened,
          setIsLoading,
          setChartParams,
          handleSuccessAddDevice,
          loadData,
        }}
      >
        {props.children}
      </DevicesActionsContext.Provider>
    </DevicesContext.Provider>
  );
};
