import React, { useEffect, useState, useContext } from "react";

import { Button, Modal, ListGroup, Collapse, Spinner } from "react-bootstrap";

// import "./DeviceSetsPage.css";
import CreateDeviceModal from "../Components/Modals/CreateDeviceModal";
import EditDeviceModal from "../Components/Modals/EditDeviceModal";
import ChartsModal from "../Components/Modals/ChartsModal";
import "./DeviceSetsPage.css";
import "../assets/css/modals.css";
import {
  DevicesContext,
  DevicesActionsContext,
} from "../Contexts/DevicesContext";
import DeviceRowComponent from "../Components/DevicesPage/DeviceRowComponent";
import ViewDeviceInfoModal from "../Components/Modals/ViewDeviceInfoModal";
import { useHistory, useParams } from "react-router-dom";
import {
  DeviceSetsActionsContext,
  DeviceSetsContext,
} from "../Contexts/DeviceSetsContext";

const SelectedDeviceSetPage = () => {
  const { devicesetid } = useParams();
  let history = useHistory();
  const {
    DevicesStatusList,
    IsLoading,
    CreateDeviceModalOpened,
    EditDeviceModalOpened,
    ChartModalOpened,
    InfoModalOpened,
    ChartParams,
  } = useContext(DevicesContext);
  const { DeviceSetsList, SelectedDeviceSet } = useContext(DeviceSetsContext);
  const { setSelectedDeviceSet } = useContext(DeviceSetsActionsContext);
  const {
    setCreateDeviceModalOpened,
    setEditDeviceModalOpened,
    setChartModalOpened,
    setInfoModalOpened,
    loadData,
  } = useContext(DevicesActionsContext);

  const [IsFilterChecked, setIsFilterChecked] = useState(false);
  const [ShowUnAttached, setShowUnAttached] = useState(true);

  useEffect(() => {
    let set = DeviceSetsList.filter((c) => c.id == devicesetid)[0];
    if (set) {
      setSelectedDeviceSet(set);
    } 
    if (devicesetid === "all") {
      setSelectedDeviceSet(null);
    } else {
      // history.push("/devices");
    }
  }, [devicesetid, DeviceSetsList]);

  return (
    <div className="devices-container">
      {IsLoading ? (
        <div style={{ textAlign: "center", margin: 30 }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="content">
          <div className="devices-header">
            <div>
              <p className="title">
                <Button variant='light' style={{marginRight:10}} onClick={()=>{history.goBack()}}><i className='fa fa-chevron-left'/></Button>
                {SelectedDeviceSet ? SelectedDeviceSet.name : "Alle Geräte"}
              </p>
            </div>
            <Button
              className="btn btn-primary"
              style={{ float: "right" }}
              onClick={() => setCreateDeviceModalOpened(true)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i className="fa fa-plus" style={{ marginRight: 10 }} /> Gerät
                anlegen
              </div>{" "}
            </Button>
          </div>
          <hr />
          <div style={{ width: "100%", marginBottom: 100 }}>
            {SelectedDeviceSet&&SelectedDeviceSet.id ? (
              <>
                {DevicesStatusList.filter(
                  (c) => c.Unattached === undefined
                ).map((device) => {
                  return (
                    <DeviceRowComponent
                      key={device.associated_id*SelectedDeviceSet.id}
                      Device={device}
                    />
                  );
                })}
                {DevicesStatusList.filter(
                  (c) => c.Unattached === undefined
                ).length==0&&<h5>Keine Geräte wurden diesem Set zugewiesen</h5>}
              </>
            ) : (
              <>
                {DevicesStatusList.map((device) => {
                  return (
                    <DeviceRowComponent
                      key={device.associated_id}
                      Device={device}
                    />
                  );
                })}
                {DevicesStatusList.length==0&&<h5>Keine Geräte wurden Ihnen zugewiesen</h5>}
              </>
            )}
          </div>
          <Modal
            className="create-device-modal"
            centered
            size="xl"
            backdrop="static"
            keyboard={false}
            show={CreateDeviceModalOpened}
            onHide={() => setCreateDeviceModalOpened(false)}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-plus" />
                Gerät anlegen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateDeviceModal
                handleClose={() => setCreateDeviceModalOpened(false)}
              />
            </Modal.Body>
          </Modal>
          <Modal
            className="edit-device-modal"
            centered
            size="xl"
            // scrollable="true"
            show={EditDeviceModalOpened}
            onHide={() => setEditDeviceModalOpened(false)}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-pencil" />
                Gerät bearbeiten
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditDeviceModal
              handleUpdated={()=>{loadData();}}
                handleClose={() => setEditDeviceModalOpened(false)}
                handleSuccessDeleted={() => {
                  loadData();
                  setEditDeviceModalOpened(false);
                }}
              />
            </Modal.Body>
          </Modal>
          <Modal
            className="view-device-modal"
            centered
            size="xl"
            // scrollable="true"
            show={InfoModalOpened}
            onHide={() => setInfoModalOpened(false)}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-info" />
                Geräteinformationen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ViewDeviceInfoModal
                handleClose={() => setInfoModalOpened(false)}
              />
            </Modal.Body>
          </Modal>
          {ChartModalOpened && (
            <ChartsModal
              ChartModalOpened={ChartModalOpened}
              Params={ChartParams}
              handleClose={() => {
                setChartModalOpened(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default SelectedDeviceSetPage;
