import React, { useState, useReducer, useEffect, useContext } from "react";
import { api_base_url, api_headers } from "../Configs/Configs";
import Axios from "axios";
import { UserContext } from "./UserContext";
import { NotificationContext } from "./NotificationContext";
import { useHistory, useParams } from "react-router-dom";

export const DashboardContext = React.createContext();
export const DashboardActionsContext = React.createContext();

const DeviceGroupReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.group];
    case "INSERT":
      return [action.group, ...state];
    case "CLEAR":
      return [];
    case "DELETE":
      return state.filter((c) => c.id !== action.group.id);
    case "EDIT":
      var edited = [...state].map((item) => {
        if (item.id === action.group.id) {
          return action.group;
        } else return item;
      });
      return edited;
    default:
      return state;
  }
};

export const DashboardContextProvider = (props) => {
  let history = useHistory();
  const { pathGroupId } = useParams();
  const { checkUserSession } = useContext(UserContext);
  const [IsLoading, setIsLoading] = useState();
  const [ChartModalOpened, setChartModalOpened] = useState(false);
  const [EditModalOpenMode, setEditModalOpenMode] = useState(0);
  const [InfoModalOpened, setInfoModalOpened] = useState(false);
  const [ThresholdsModalOpened, setThresholdsModalOpened] = useState(false);

  const [ChartParams, setChartParams] = useState("group");
  const [GroupsList, dispatchGroup] = useReducer(DeviceGroupReducer, []);
  const [DeletedGroupsList, setDeletedGroupsList] = useState([]);
  const [DevicesList, setDevicesList] = useState([]);

  const [LastValuesList, setLastValuesList] = useState([]);
  const [OldValuesList, setOldValuesList] = useState([]);

  const [SelectedDeviceGroup, setSelectedDeviceGroup] = useState();
  const [SelectedSensor, setSelectedSensor] = useState();
  const [reloadSensorHandler, setReloadSensorHandler] = useState();
  const [SensorsFilter, setSensorsFilter] = useState("");

  const [OpenedGroupId, setOpenedGroupId] = useState(
    pathGroupId ? pathGroupId : 0
  );

  useEffect(() => {
    dispatchGroup({ type: "CLEAR" });
    setDevicesList([]);

    const fetchData = async () => {
      if (!checkUserSession()) return;
      setIsLoading(true);
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/configurations/${props.User.id}/1?step=0`,
      }).then((response) => {
        if (
          response.data &&
          response.data.configurations &&
          Array.isArray(response.data.configurations) &&
          response.status === 200
        ) {
          setDeletedGroupsList(
            response.data.configurations.filter((c) => c.deleted == 1)
          );
          response.data.configurations
            .filter((c) => c.deleted == 0)
            .forEach((group) => {
              dispatchGroup({ type: "ADD", group });
            });
        }
      });
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDevices/associated/1/0?step=0`,
      }).then((response) => {
        if (
          response.data &&
          response.data.devices &&
          Array.isArray(response.data.devices) &&
          response.status === 200
        ) {
          setDevicesList(response.data.devices);
          setIsLoading(false);
        }
      });
    };
    const getLatestValues = async (oldData = false, daysBack = 1) => {
      if (!checkUserSession()) return;
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDeviceConfiguration/latest_values_overview/1/1/${daysBack}`,
      }).then((response) => {
        if (
          response.data &&
          response.data.own_devices &&
          Array.isArray(response.data.own_devices) &&
          response.status === 200
        ) {
          let allValues = [];
          response.data.own_devices.forEach(async (element) => {
            let latest_values = element.latest_measurement_values;
            if (!latest_values) return;
            latest_values.forEach((lastVal) => {
              allValues.push(lastVal);
            });
          });
          if (oldData) {
            setOldValuesList(allValues);
          } else {
            setLastValuesList(allValues);
          }
        }
      });
    };

    fetchData();
    getLatestValues(true, 15);
    getLatestValues();
    const lastValuesInterval = setInterval(() => {
      getLatestValues();
    }, 100000);
    return () => {
      clearInterval(lastValuesInterval);
    };
  }, []);

  useEffect(() => {
    if (pathGroupId != OpenedGroupId)
      history.push("/dashboard/" + (OpenedGroupId === 0 ? "" : OpenedGroupId));
  }, [OpenedGroupId]);

  useEffect(() => {
    if (pathGroupId) setOpenedGroupId(pathGroupId);
  }, [pathGroupId]);
  return (
    <DashboardContext.Provider
      value={{
        IsLoading,
        GroupsList,
        SelectedDeviceGroup,
        DevicesList,
        SelectedSensor,
        reloadSensorHandler,
        ChartParams,
        ChartModalOpened,
        EditModalOpenMode,
        InfoModalOpened,
        ThresholdsModalOpened,
        DeletedGroupsList,
        OpenedGroupId,
        LastValuesList,
        OldValuesList,
        SensorsFilter,
      }}
    >
      <DashboardActionsContext.Provider
        value={{
          setChartModalOpened,
          setEditModalOpenMode,
          setInfoModalOpened,
          setThresholdsModalOpened,
          setSelectedSensor,
          setReloadSensorHandler,
          setSelectedDeviceGroup,
          setIsLoading,
          dispatchGroup,
          setChartParams,
          setOpenedGroupId,
          setSensorsFilter,
        }}
      >
        {props.children}
      </DashboardActionsContext.Provider>
    </DashboardContext.Provider>
  );
};
