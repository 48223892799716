import React, { useContext, useState, useEffect } from "react";
import { Button, ButtonToolbar, ButtonGroup, Modal } from "react-bootstrap";
import * as moment from "moment";
import "moment/locale/de";
import { ArchiveActionsContext } from "../../Contexts/ArchiveContext";
import { SensorsTypes } from "../../Configs/DeviceConsts";
import { ArchiveRowContext } from "../../Contexts/ArchiveRowContext";

const SensorItem = ({ sensor }) => {
  const {
    setChartModalOpened,
    setChartParams
  } = useContext(ArchiveActionsContext);
  const {
    DeviceGroup,Configuration
  } = useContext(ArchiveRowContext);
  return (
    <tr
      style={{
        backgroundColor: '#eee'
      }}
    >
      <td className="column column1">
        <div className="title">{sensor.bwb_name} </div>
        {sensor &&
        sensor.symbol &&
        SensorsTypes[sensor.symbol] && (
          <>{SensorsTypes[sensor.symbol].de}</>
        )}
        <div className="desc">
          {sensor.bwb +
            " - " +
            sensor.sid +
            "(" +
            sensor.parameter +
            ")" +
            " port " +
            sensor.port}{" "}
        </div>
      </td>
      <td className="column column2">
        {sensor.last_value_uts && (
          <>
            <div className="title">
              {moment.unix(sensor.last_value_uts).fromNow()}
            </div>
            <div className="desc">
              {moment.unix(sensor.last_value_uts).format("DD.MM.YYYY HH:mm")}{" "}
            </div>
          </>
        )}
        {!sensor.last_value_uts && <div className="desc">---</div>}
      </td>
      <td className="column column5">
        {sensor.last_value ? (
          <>
            <div >
              {"  " + sensor.last_value + " " + sensor.unit}
            </div>
          </>
        ) : (
          <div>{"---"}</div>
        )}
      </td>
      <td className="column column7">
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup className="mr-2" aria-label="First group">
            <Button
              variant="light"
              // disabled={sensor.last_value ? false : true}
              onClick={() => {
                let end_date=moment.unix(sensor.last_value_uts)
                setChartParams({ type: "sensor", object: {...sensor,start_date:Configuration.starts_at,end_date:end_date} });
                setChartModalOpened(true);
              }}
            >
              <i className="buttons fa fa-line-chart" />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </td>
    </tr>
  );
};
export default SensorItem;
