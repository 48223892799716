import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { api_base_url } from "../Configs/Configs";
import { ArchiveActionsContext, ArchiveContext } from "./ArchiveContext";

export const ArchiveRowContext = React.createContext();
export const ArchiveRowActionsContext = React.createContext();

export const ArchiveRowContextProvider = (props) => {
  const DeviceGroup = props.Group;

  const { SensorsFilter, OpenedGroupId,DeletedGroupsList } = useContext(ArchiveContext);
  const { setOpenedGroupId,dispatchGroup ,setDeletedGroupsList} = useContext(ArchiveActionsContext);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [FilteredSensorsList, setFilteredSensorsList] = useState([]);
  const [SensorsList, setSensorsList] = useState([]);
  const [AllSensorsList, setAllSensorsList] = useState([]);
  const [ActiveSensorsCount, setActiveSensorsCount] = useState(0);
  const [InActiveSensorsCount, setInActiveSensorsCount] = useState(0);

  const [ThresholdSensorsCount, setThresholdSensorsCount] = useState(0);

  const [showAllSensors, setShowAllSensors] = useState(false);

  const [cssClassName, setCssClassName] = useState();

  const [IsLoading, setIsLoading] = useState(0);
  const AddLoading = () => {
    setIsLoading((IsLoading) => IsLoading + 1);
  };
  const RemoveLoading = () => {
    setIsLoading((IsLoading) => IsLoading - 1);
  };

  const DeleteGroup = async () => {
    await Axios({
      method: "get",
      withCredentials: true,
      url: `${api_base_url}/apiMeasurementConfiguration/delete/${DeviceGroup.id}`,
    }).then((response) => {
      DeviceGroup['deleted']=1;
      dispatchGroup({ type: "DELETE", group: DeviceGroup });
      setDeletedGroupsList([DeletedGroupsList,DeviceGroup]);
      setShowConfirmDeleteModal(false);
    });
  };

  const [Configuration, setConfiguration] = useState(null);
  const [GroupLastValues, setGroupLastValues] = useState([]);

  const [selectedSensorId, setSelectedSensorId] = useState(0);
  const { pathGroupId, pathSensorId } = useParams();

  useEffect(() => {
    if (SensorsFilter && SensorsFilter.length > 0) {
      let filter = SensorsFilter.trim();
      let filtered = SensorsList.filter((c) => c.bwb.includes(filter));
      setFilteredSensorsList(filtered);
      if (filtered.length > 0) {
        setOpenedGroupId(DeviceGroup.id);
      }
    } else {
      setFilteredSensorsList(SensorsList);
    }
  }, [SensorsFilter, SensorsList]);

  useEffect(() => {
    if (DeviceGroup.id == pathGroupId) {
      if (pathSensorId) setSelectedSensorId(pathSensorId);
      else {
        setSelectedSensorId(0);
      }
    } else {
      setSelectedSensorId(0);
    }
  }, [pathSensorId, pathGroupId]);

  useEffect(() => {
    if (
      DeviceGroup.deleted == "0" &&
      GroupLastValues &&
      GroupLastValues.length > 0 &&
      ActiveSensorsCount > 0
    ) {
      if (ActiveSensorsCount === SensorsList.length)
        setCssClassName("rowStatus1");
      else setCssClassName("rowStatus2");
    } else setCssClassName("rowStatus3");
  }, [GroupLastValues, ActiveSensorsCount, SensorsList]);


  const refreshSensorsList = () => {
    if (!showAllSensors)
      setSensorsList(
        AllSensorsList.filter(
          (c) =>
            !c.display_in_diagram ||
            c.display_in_diagram == 0 ||
            c.display_in_diagram == "" ||
            c.display_in_diagram == 2
        )
      );
    else setSensorsList(AllSensorsList);
  };

  useEffect(() => {
    refreshSensorsList();
  }, [showAllSensors]);

  return (
    <ArchiveRowContext.Provider
      value={{
        DeviceGroup,
        Configuration,
        SensorsList,
        IsLoading,
        ActiveSensorsCount,
        InActiveSensorsCount,
        ThresholdSensorsCount,
        cssClassName,
        selectedSensorId,
        GroupLastValues,
        AllSensorsList,
        showAllSensors,
        FilteredSensorsList,
        showConfirmDeleteModal,
      }}
    >
      <ArchiveRowActionsContext.Provider
        value={{
          setSensorsList,
          setActiveSensorsCount,
          setInActiveSensorsCount,
          AddLoading,
          RemoveLoading,
          setConfiguration,
          setGroupLastValues,
          setAllSensorsList,
          setShowAllSensors,
          refreshSensorsList,
          setShowConfirmDeleteModal,
          DeleteGroup,
        }}
      >
        {props.children}
      </ArchiveRowActionsContext.Provider>
    </ArchiveRowContext.Provider>
  );
};
