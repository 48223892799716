import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";

import * as moment from "moment";
import "moment/locale/de";
import SensorsComponent from "./MachineSensorsComponent";
import { api_base_url, api_headers } from "../../Configs/Configs";
import {
  DeviceGroupContext,
  DeviceGroupActionsContext,
} from "../../Contexts/DeviceGroupContext";
import { DashboardActionsContext, DashboardContext } from "../../Contexts/DashboardContext";
import { UserContext } from "../../Contexts/UserContext";
import MeasurementGroupItem from "./MeasurementGroupItem";
import { Spinner } from "react-bootstrap";

const GroupRowComponent = () => {
  const { LastValuesList, OldValuesList, SensorsFilter } = useContext(
    DashboardContext
  );

  const {
    IsLoading,
    SensorsList,
    DeviceGroup,
    GroupLastValues,
    FilteredSensorsList,
  } = useContext(DeviceGroupContext);
  const {
    setSensorsList,
    AddLoading,
    RemoveLoading,
    setConfiguration,
    setActiveSensorsCount,
    setInActiveSensorsCount,
    setGroupLastValues,
    setAllSensorsList,
  } = useContext(DeviceGroupActionsContext);
  const { dispatchGroup } = useContext(DashboardActionsContext);

  const { User, checkUserSession } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      AddLoading();
      if (!checkUserSession()) return;
      //TODO: move to dashboard context
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurements/measurements/${User.id}?step=0`,
      }).then(async(response) => {
        if (
          response.data &&
          response.status === 200 &&
          response.data.measurements &&
          Array.isArray(response.data.measurements)
        ) {
          let configuration = response.data.measurements.find(
            (c) => c.measurement_configuration_id == DeviceGroup.id
          );
          setConfiguration(configuration);
          if(configuration.ends_at &&
            moment(configuration.ends_at).isValid()){
          }
          if (
            configuration.ends_at &&
            moment(configuration.ends_at).isValid() &&
            moment(configuration.ends_at).isBefore(moment.now()) &&
            DeviceGroup.customer_field_1 != "ARCHIV"
          ) {
            DeviceGroup.customer_field_1 = "ARCHIV";
            var formData = new FormData();
            formData.append("customer_field_1", "ARCHIV");
            await Axios({
              method: "post",
              data: formData,
              withCredentials: true,
              url: `${api_base_url}/apiMeasurementConfiguration/edit/${DeviceGroup.id}`,
            });
            dispatchGroup({ type: "EDIT", group: DeviceGroup });
          }
        }
      });
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/configuration/${DeviceGroup.id}`,
      }).then((response) => {
        if (
          response.data &&
          response.status === 200 &&
          response.data.measurands &&
          Array.isArray(response.data.measurands) &&
          response.data.measurands.length > 0
        ) {
          setAllSensorsList(
            response.data.measurands.filter((c) => c.active == 1)
          );
          setSensorsList(
            response.data.measurands.filter(
              (c) =>
                (!c.display_in_diagram ||
                  c.display_in_diagram == 0 ||
                  c.display_in_diagram == "" ||
                  c.display_in_diagram == 2) &&
                c.active == 1
            )
          );
        }
      });
      RemoveLoading();
    };
    fetchData();
  }, [DeviceGroup]);

  // useEffect(() => {
  //   if (SensorsList && SensorsList.length > 0) {
  //     if (!GroupLastValues || GroupLastValues.length == 0) {
  //       setActiveSensorsCount(0);
  //       setInActiveSensorsCount(SensorsList.length);
  //       return;
  //     }
  //     let activeCount = 0;
  //     let inActiveCount = 0;
  //     SensorsList.forEach((sensor) => {
  //       var l = GroupLastValues.find(
  //         (c) =>
  //           c.dtb === sensor.dtb &&
  //           //c.mtb === sensor.mtb &&
  //           c.bwb === sensor.bwb &&
  //           c.sid === sensor.sid &&
  //           c.port === sensor.port &&
  //           c.parameter === sensor.parameter
  //       );
  //       if (l) activeCount++;
  //       else inActiveCount++;
  //     });
  //     setActiveSensorsCount(activeCount);
  //     setInActiveSensorsCount(inActiveCount);
  //   }
  // }, [GroupLastValues, SensorsList]);

  useEffect(() => {
    let groupVals = [];
    let dateBefore2Hours=moment().subtract(2, 'hours');
    SensorsList.forEach((sensor) => {
      let val = LastValuesList.find(
        (c) =>
          c.dtb === sensor.dtb &&
          // c.mtb === sensor.mtb &&
          c.bwb === sensor.bwb &&
          c.sid === sensor.sid &&
          c.parameter === sensor.parameter &&
          c.port === sensor.port
      );
      if (val) {
        val["sensor_id"] = sensor.id;
       if(moment.unix(val.UTS).isBefore(dateBefore2Hours)){
        val["old"] = true;
       }
       else val["old"] = false;
        groupVals.push(val);
      } else {
        let oldVal = OldValuesList.find(
          (c) =>
            c.dtb === sensor.dtb &&
            // c.mtb === sensor.mtb &&
            c.bwb === sensor.bwb &&
            c.sid === sensor.sid &&
            c.parameter === sensor.parameter &&
            c.port === sensor.port
        );
        if (oldVal) {
          oldVal["sensor_id"] = sensor.id;
          oldVal["old"] = true;
          groupVals.push(oldVal);
        }
      }
    });
    setGroupLastValues(groupVals);
  }, [LastValuesList, SensorsList]);

  return (
    <>
      {IsLoading > 0 ? (
        <tr>
          <td
            style={{ textAlign: "center", verticalAlign: "middle" }}
            colSpan={12}
          >
            <Spinner animation="border" />
          </td>
        </tr>
      ) : (
        <>
          {!SensorsFilter ||
          SensorsFilter === "" ||
          (SensorsFilter &&
            SensorsFilter.length > 0 &&
            FilteredSensorsList.length > 0) ? (
            <>
              <MeasurementGroupItem />
              <SensorsComponent />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default GroupRowComponent;
