import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Modal,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CreateDeviceModalContextProvider, {
  CreateDeviceModalContext,
} from "../../Contexts/CreateDeviceModalContext";
import "../../assets/css/modals.css";
import Axios from "axios";
import { api_base_url } from "../../Configs/Configs";
import { UserContext } from "../../Contexts/UserContext";
import { DevicesActionsContext } from "../../Contexts/DevicesContext";
import { NotificationContext } from "../../Contexts/NotificationContext";
import qrcode from "../../assets/images/qrcode.png";

const CreateDeviceModalScreen = () => {
  const { screenNo, setScreenNo } = useContext(CreateDeviceModalContext);
  return (
    screenNo === 0 && (
      <Container>
        <Row>
          <Col>
            <Card style={{ height: "100%" }}>
              <Card.Header style={{ textAlign: "center" }}>
                <div>
                  <span style={{ fontSize: 120 }} className="fa fa-mobile" />
                </div>
                <h3>Gerät per App anmelden</h3>
                <h4>(Bevorzugt)</h4>
              </Card.Header>
              <Card.Body>
                <p>
                  Wenn Sie sich für diese Option entscheiden benötigen Sie für
                  den weiteren Verlauf der Geräteanmeldung die Trotec
                  Multimeasure App. Bitte stellen Sie sicher, dass diese auf
                  ihrem Mobilgerät installiert ist und Sie sich mit den
                  richtigen Accountdaten erfolgreich eingeloggt haben.
                </p>
              </Card.Body>
              <Card.Footer style={{ height: 75, textAlign: "right" }}>
                <Button onClick={() => setScreenNo(1)}> Weiter</Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={{ height: "100%" }}>
              <Card.Header style={{ textAlign: "center" }}>
                <div>
                  <span
                    style={{ fontSize: 120 }}
                    className="fa fa-keyboard-o"
                  />
                </div>
                <h3>Gerät manuell anmelden</h3>
                <h4>(Nicht bevorzugt)</h4>
              </Card.Header>
              <Card.Body>
                <p>
                Fehler beim Anlegen des Geräts. Bitte überprüfen Sie die MMUI (MultiMeasure Unique ID).
                </p>
              </Card.Body>
              <Card.Footer style={{ height: 75, textAlign: "right" }}>
                <Button onClick={() => setScreenNo(2)}>Weiter</Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  );
};
const CreateDeviceModalTour = () => {
  const { screenNo } = useContext(CreateDeviceModalContext);

  return (
    screenNo === 1 && (
      <>
        <Card style={{ height: "100%" }}>
          <Card.Header style={{ textAlign: "center" }}>
            <h1>{"App Downloaden & installieren"}</h1>
          </Card.Header>
          <Card.Body style={{ display: "flex", flexDirection: "column" }}>
            <img
              alt="QR-Code"
              class="qrcode"
              src={qrcode}
              style={{ height: 300, marginBottom: 10, alignSelf: "center" }}
            />
            <ol>
              <li>Scannen Sie den QR-Code</li>
              <li>
                Nachdem Sie auf den Link geklickt haben gelangen Sie in den App
                Store oder Play Store
              </li>
              <li>Laden Sie die App runter</li>
              <li>Klicken Sie auf den Reiter "Mehr"</li>
              <li>Melden Sie sich in die Multimeasure Cloud an</li>
              <li>Klicken Sie auf "Neues Gerät registrieren"</li>
            </ol>
            {/* <p>
              Wenn Sie sich für diese Option entscheiden benötigen Sie für den
              weiteren Verlauf der Geräteanmeldung die Trotec Multimeasure App.
              Bitte stellen Sie sicher, dass diese auf ihrem Mobilgerät
              installiert ist und Sie sich mit den richtigen Accountdaten
              erfolgreich eingeloggt haben.
            </p> */}
          </Card.Body>
        </Card>
      </>
    )
  );
};
const CreateDeviceModalCreate = () => {
  const { User, checkUserSession } = useContext(UserContext);
  const { ShowNotification } = useContext(NotificationContext);
  const { screenNo } = useContext(CreateDeviceModalContext);
  const { handleSuccessAddDevice } = useContext(DevicesActionsContext);
  const [validationMessage, setValidationMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [serialNumberArr, setSerialNumberArr] = useState([]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    let str = "";
    serialNumberArr.forEach((element) => {
      str += element;
    });
    setSerialNumber(str);
  }, [serialNumberArr]);

  const handleChange = (e) => {
    if (e.target.value.length === 4) {
      let index = parseInt(e.target.id.split("serialNumberInput")[1]);
      let arr = [...serialNumberArr];
      arr[index] = e.target.value;
      setSerialNumberArr(arr);
      if (index < 15)
        document.getElementById("serialNumberInput" + (index + 1)).focus();
    }
    if (e.target.value.length === 0) {
      let index = parseInt(e.target.id.split("serialNumberInput")[1]);
      let arr = [...serialNumberArr];
      arr[index] = e.target.value;
      setSerialNumberArr(arr);
      if (index > 0)
        document.getElementById("serialNumberInput" + (index - 1)).focus();
    }
  };

  useEffect(() => {
    var form = document.getElementById("form");
    var input = document.getElementById("serialNumberInput0");
    if (!input) return;
    input.setCustomValidity("");
    setValidationMessage("");
    if (!serialNumber || serialNumber.length < 64) {
      input.setCustomValidity("Bitte geben Sie einen gültigen Wert ein");
      return;
    }
    form.checkValidity();
  }, [serialNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkUserSession()) return;
    setIsSaving(true);
    await Axios.get(
      `${api_base_url}/apiDevices/created/${serialNumber}/${User.id}`,
      { withCredentials: true }
    )
      .then((response) => {
        if (response.data && (response.data.success == true||response.data.associated_id)) {
          setShowSuccessDialog(true);
          handleSuccessAddDevice(response.data.associated_id);
          ShowNotification({
            type: "success",
            title: "Neues Gerät wurde erfolgreich hinzugefügt",
          });
        } else {
          setValidationMessage(
            "Fehler beim Anlegen des Geräte, Bitte überprüfen Sie die MMUI (MultiMeasure Unique ID)."
          );
          setIsSaving(false);
          return;
        }
      })
      .catch(() => {
        setValidationMessage(
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal.."
        );
        setIsSaving(false);
        return;
      });
  };
  return (
    screenNo === 2 && (
      <>
        <Container>
          <Form id="form" onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>
                    Bitte geben Sie die 64-stellige MMUI (MultiMeasure Unique ID) ein:
                  </Form.Label>
                  <InputGroup style={{ margin: 5 }}>
                    <Form.Control
                      autoFocus
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput0"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput1"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput2"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput3"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                  </InputGroup>
                  <InputGroup style={{ margin: 5 }}>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput4"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput5"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput6"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput7"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                  </InputGroup>
                  <InputGroup style={{ margin: 5 }}>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput8"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput9"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput10"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput11"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                  </InputGroup>
                  <InputGroup style={{ margin: 5 }}>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput12"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput13"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput14"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>-</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="serialNumberInput15"
                      maxLength={4}
                      type="text"
                      bsClass="txt-input"
                    />
                  </InputGroup>
                </Form.Group>
                <div>
                  <p style={{ color: "#f55", float: "left" }}>
                    {validationMessage}
                  </p>
                  <Button
                    disabled={isSaving}
                    style={{ float: "right" }}
                    type="submit"
                  >
                    {isSaving ? (
                      <>
                        <Spinner size="sm" animation="border" />
                        {"  Bitte warten..."}
                      </>
                    ) : (
                      "Gerät hinzufügen"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>

        <Modal
          centered
          size="lg"
          animation={false}
          backdrop="static"
          show={showSuccessDialog}
          onHide={()=>{setShowSuccessDialog(false)}}
        >
          <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-plus' />Erfolgreich</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className="fa fa-check fa-5x"
                style={{ color: "#383", margin: 10, marginRight: 15 }}
              />
              <div>
                <p>Sie konnten erfolgreich ein neues Gerät hinzufügen.</p>
                <p>Das Gerät wird in Kürze angezeigt.</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowSuccessDialog(false)}
              className="btn-primary"
            >
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};

const CreateDeviceModal = () => {
  return (
    <CreateDeviceModalContextProvider>
      <CreateDeviceModalScreen />
      <CreateDeviceModalTour />
      <CreateDeviceModalCreate />
    </CreateDeviceModalContextProvider>
  );
};

export default CreateDeviceModal;
