import React, { useState, useReducer, useEffect, useContext } from "react";
import { api_base_url, api_headers } from "../Configs/Configs";
import Axios from "axios";
import { UserContext } from "./UserContext";

export const DeviceSetsContext = React.createContext();
export const DeviceSetsActionsContext = React.createContext();

export const DeviceSetsContextProvider = (props) => {
  const { checkUserSession } = useContext(UserContext);
  const [IsLoading, setIsLoading] = useState(false);
  const [CreateDeviceSetModalOpened, setCreateDeviceSetModalOpened] = useState(false);
  const [EditDeviceSetModalOpened, setEditDeviceSetModalOpened] = useState(false);
  const [InfoModalOpened, setInfoModalOpened] = useState(false);

  const [DeviceSetsList, dispatchDeviceSet] = useReducer((state, action) => {
    switch (action.type) {
      case "ADD":
        return [...state, action.deviceSet];
      case "BULK_ADD":
        return [...state, ...action.deviceSets];
      case "INSERT":
        return [action.deviceSet, ...state];
      case "CLEAR":
        return [];
      case "EDIT":
        var edited = [...state].map((item) => {
          if (item.id === action.deviceSet.id) {
            return action.deviceSet;
          } else return item;
        });
        return edited;
      case "DELETE":
        var edited = [...state].filter(
          (item) => item.id !== action.deviceSet.id
        );
        return edited;
      default:
        return state;
    }
  }, []);

  const [SelectedDeviceSet, setSelectedDeviceSet] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    const fetchData = async () => {
      if (!checkUserSession()) return;
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/deviceSets/get_device_set_for_user`,
      }).then(async (response) => {
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.status === 200
        ) {
          dispatchDeviceSet({ type: "BULK_ADD", deviceSets: response.data });
        }
      });
      setIsLoading(false);
    };

    fetchData();
  };

  return (
    <DeviceSetsContext.Provider
      value={{
        IsLoading,
        DeviceSetsList,
        SelectedDeviceSet,
        CreateDeviceSetModalOpened,
        EditDeviceSetModalOpened,
        InfoModalOpened,
      }}
    >
      <DeviceSetsActionsContext.Provider
        value={{
          setCreateDeviceSetModalOpened,
          dispatchDeviceSet,
          setSelectedDeviceSet,
          setEditDeviceSetModalOpened,
          setInfoModalOpened,
          setIsLoading,
          loadData,
        }}
      >
        {props.children}
      </DeviceSetsActionsContext.Provider>
    </DeviceSetsContext.Provider>
  );
};
