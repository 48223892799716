import React, { useState, useEffect } from "react";
import Axios from "axios";
import qs from "querystring";
import { api_base_url, api_headers } from "../Configs/Configs";
import { Button } from "react-bootstrap";

export const UserContext = React.createContext();

export const UserContextProvider = (props) => {
  const [User, setUser] = useState();
  const [NewUser, setNewUser] = useState(null);
  const [EnabledEmailAlerts, setEnabledEmailAlerts] = useState(false);
  const [AlertsEmail, setAlertsEmail] = useState(null);
  const [AppVersion, setAppVersion] = useState('V 1.0.0');

  const [SensorsDefaultsList, setSensorsDefaultsList] = useState([]);

  const getUserInfo = () => {
    const get = async () => {
      await Axios.get(`${api_base_url}/userSetting/get_user_info`, {
        withCredentials: true,
      }).then((response) => {
        if (response.data && response.data.id > 0) {
          setUser(response.data);
        }
      });
      await Axios.get(
        `${api_base_url}/apiMeasurementConfiguration/sensor_defaults`,
        {
          withCredentials: true,
        }
      ).then((response) => {
        if (response.data && response.status === 200) {
          setSensorsDefaultsList(response.data);
        }
      });
      await Axios({
        method: "post",
        withCredentials: true,
        url: `${api_base_url}/userSetting/get_is_email_enabled`,
      }).then((response) => {
        if (response.status === 200) {
          setEnabledEmailAlerts(response.data);
        }
      });
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/userSetting/get_alert_email`,
      }).then((response) => {
        if (response.status === 200 && response.data) {
          setAlertsEmail(response.data);
        }
      });
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/appSetting/get_current_app_version`,
      }).then((response) => {
        if (response.status === 200 && response.data&&response.data.length<=10) {
          setAppVersion(response.data);
        }
      });
    };
    get();
  };

  const checkUserSession = () => {
    const check = async () => {
      await Axios.get(`${api_base_url}/login/api_login_check`, {
        withCredentials: true,
      })
        .then(async (response) => {
          if (response.data && response.data.user_id > 0) {
            return true;
          }
          return null;
        })
        .catch((error) => {
          setUser(null);
          return null;
        });
    };
    return check();
  };

  return (
    <UserContext.Provider
      value={{
        User,
        setUser,
        NewUser,
        setNewUser,
        checkUserSession,
        getUserInfo,
        SensorsDefaultsList,
        EnabledEmailAlerts,
        setEnabledEmailAlerts,
        AlertsEmail,
        setAlertsEmail,AppVersion
      }}
    >
      <>
        {props.children}

        {/* <div
          style={{
            zIndex: 5000,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "#00000090",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: 300,
              width: 600,
              backgroundColor: "#f1f1f1",
              borderRadius: 25,
              boxShadow: "0 0 0 9px #00000026",
            }}
          >
            <p>Verbindung ist unterbrochen, Wiederholung ...</p>
            <Button>Wiederholung</Button>
          </div>
        </div>
      */}
      </>
    </UserContext.Provider>
  );
};
