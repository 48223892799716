/* eslint-disable jsx-a11y/anchor-is-valid */
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { api_base_url } from "../../Configs/Configs";
import { SensorsTypes } from "../../Configs/DeviceConsts";
import { UserContext } from "../../Contexts/UserContext";

const SensorThresholdsComponent = ({
  measurand = {},
  defaultVal,
  handleClose,
  handleReload,
}) => {
  const { User, checkUserSession, SensorsDefaultsList } = useContext(
    UserContext
  );
  const [defaultValue, setDefaultValue] = useState(defaultVal);
  const [validationMessage, setValidationMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    measurand["bwb_name"] = "";
    measurand["unit"] = "";
    measurand["last_value"] = "";
    measurand["last_value_uts"] = "";
    measurand["symbol"] = defaultValue.symbol;
    measurand["threshold_min"] = is_custom ? threshold_min : null;
    measurand["threshold_max"] = is_custom ? threshold_max : null;
    let display_val = 0;
    if (is_active === false) display_val += 1;
    if (is_displayed === false) display_val += 2;
    measurand["display_in_diagram"] =  display_val ;
    measurand["min_notif_text"] = is_custom
      ? min_notif_text + "@@@" + (min_check === true ? "success" : "error")
      : null;
    measurand["min_notif_count"] = is_custom ? min_notif_count : null;
    measurand["max_notif_text"] = is_custom
      ? max_notif_text + "@@@" + (max_check === true ? "success" : "error")
      : null;
    measurand["target_min"] =
      target_min &&
      !Number.isNaN(target_min) &&
      (target_min != 0 || target_max != 0)
        ? target_min
        : "";
    measurand["target_max"] =
      target_max &&
      !Number.isNaN(target_max) &&
      (target_min != 0 || target_max != 0)
        ? target_max
        : "";
    measurand["max_notif_count"] = is_custom ? max_notif_count : null;
    if (is_custom) {
      measurand["extra_field_1"] = "1";
    } else {
      measurand["extra_field_1"] = "0";
    }
    var formData = new FormData();
    measurand["unit"] = unit;
    var val = {
      ...measurand,
      associated_id: "",
    };
    for (var key in val) {
      formData.append(key, val[key]);
    }

    if (!checkUserSession()) return;
    await Axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/apiMeasurementConfiguration/change_measurand_properties/1`,
    });

    //  else {
    //   if (!checkUserSession()) return;
    //   measurand["extra_field_1"] = "0";
    //   await Axios({
    //     method: "get",
    //     withCredentials: true,
    //     url: `${api_base_url}/apiMeasurementConfiguration/destroy_measurand_properties/${measurand.id}/${measurand.measurand_display_id}/${User.id}`,
    //   });
    // }
    setIsSaving(false);
    setUpdated(false);
    if (handleReload) handleReload["s"] = { ...(handleReload.s + 1) };
    if (handleClose) handleClose();
  };

  const getMaxCheck = () => {
    if (!measurand || !measurand.max_notif_text) return false;
    let s = measurand.max_notif_text.split("@@@");
    if (s.length > 1) {
      if (s[1] === "success") return true;
      if (s[1] === "error") return false;
    }
  };
  const getMinCheck = () => {
    if (!measurand || !measurand.min_notif_text) return false;
    let s = measurand.min_notif_text.split("@@@");
    if (s.length > 1) {
      if (s[1] === "success") return true;
      if (s[1] === "error") return false;
    }
  };

  const [updated, setUpdated] = useState(false);

  const [is_custom, setIs_custom] = useState(false);

  const [is_active, setIs_active] = useState(true);
  const [is_displayed, setIs_displayed] = useState(true);

  const [hasMinValue, setHasMinValue] = useState(false);
  const [hasMaxValue, setHasMaxValue] = useState(false);

  const [min_check, setMin_check] = useState(getMinCheck());
  const [max_check, setMax_check] = useState(getMaxCheck());

  const [target_min, setTargetMin] = useState("");
  const [target_max, setTargetMax] = useState("");

  const [threshold_min, setMin_value] = useState("");
  const [threshold_max, setMax_value] = useState("");

  const [unit, setUnit] = useState();

  const [min_notif_text, setMin_text] = useState("");
  const [max_notif_text, setMax_text] = useState("");

  const [min_notif_count, setMin_notif_count] = useState(1);
  const [max_notif_count, setMax_notif_count] = useState(1);

  const [hasSuggestTarget, setHasSuggestTarget] = useState(true);

  useEffect(() => {
    setUpdated(true);
  }, [
    is_active,
    is_displayed,
    hasMinValue,
    hasMaxValue,
    min_check,
    max_check,
    threshold_min,
    threshold_max,
    unit,
    min_notif_text,
    max_notif_text,
    min_notif_count,
    max_notif_count,
    target_min,
    target_max,
  ]);

  useEffect(() => {
    if (measurand) {
      setIs_custom(measurand.extra_field_1 == 1 ? true : false);
      setIs_active(
        !measurand.display_in_diagram ||
          measurand.display_in_diagram == 0 ||
          measurand.display_in_diagram == 2
          ? true
          : false
      );
      setIs_displayed(
        !measurand.display_in_diagram ||
          measurand.display_in_diagram == 0 ||
          measurand.display_in_diagram == 1
          ? true
          : false
      );
      setHasMinValue(measurand.threshold_min ? true : false);
      setHasMaxValue(measurand.threshold_max ? true : false);
      setMin_value(measurand.threshold_min);
      setMax_value(measurand.threshold_max);
      setTargetMin(measurand.target_min);
      setTargetMax(measurand.target_max);
      setUnit(measurand.unit ? measurand.unit : null);
      setMin_text(
        measurand.min_notif_text&&measurand.min_notif_text!='null' ? measurand.min_notif_text.split("@@@")[0] : ""
      );
      setMax_text(
        measurand.max_notif_text &&measurand.max_notif_text!='null'? measurand.max_notif_text.split("@@@")[0] : ""
      );
      setMin_notif_count(
        measurand.min_notif_count
          ? measurand.min_notif_count == 0
            ? 1
            : measurand.min_notif_count
          : 1
      );
      setMax_notif_count(
        measurand.max_notif_count
          ? measurand.max_notif_count == 0
            ? 1
            : measurand.max_notif_count
          : 1
      );
    }
  }, [measurand]);

  useEffect(() => {
    if (is_custom && defaultValue) {
      setUnit(defaultValue.unit);
    }
    if (!is_custom && defaultValue) {
      setMin_value(defaultValue.threshold_min);
      setMax_value(defaultValue.threshold_max);
      setUnit(defaultValue.unit);
    }
    setUpdated(true);
  }, [is_custom]);

  useEffect(() => {
    if (!defaultValue) {
      const fetchData = async () => {
        let dv = SensorsDefaultsList.find(
          (c) => c.sid === measurand.sid && c.parameter === measurand.parameter
        );
        setDefaultValue(dv);
      };

      fetchData();
    }
    setUpdated(false);
  }, []);

  useEffect(() => {
    setValidationMessage("");
    var form = document.getElementById("thresholds-form");
    var p1 = document.getElementById("threshold_min_input");
    var p2 = document.getElementById("threshold_max_input");
    var p3 = document.getElementById("min_notif_count_input");
    var p4 = document.getElementById("max_notif_count_input");
    p1.setCustomValidity("");
    p2.setCustomValidity("");
    p3.setCustomValidity("");
    p4.setCustomValidity("");
    if (is_custom) {
      if (min_notif_count < 1) {
        p3.setCustomValidity("Bitte geben Sie einen gültigen Wert ein.");
      }
      if (max_notif_count < 1) {
        p4.setCustomValidity("Bitte geben Sie einen gültigen Wert ein.");
      }
      if (
        threshold_min != "" &&
        threshold_max != "" &&
        Number(threshold_min) >= Number(threshold_max)
      ) {
        p2.setCustomValidity("Max. Wert muss größer als der Min. Wert sein.");
      }
    }
    form.checkValidity();
  }, [
    is_custom,
    min_notif_count,
    max_notif_count,
    threshold_min,
    threshold_max,
  ]);

  return (
    <Form
      id="thresholds-form"
      onSubmit={(e) => {
        handleSave(e);
      }}
    >
      <Container>
        <Row>
          <Col>
            {defaultValue &&
              defaultValue.symbol &&
              SensorsTypes[defaultValue.symbol] && (
                <strong>{SensorsTypes[defaultValue.symbol].de}</strong>
              )}
            <h5>
              {measurand.bwb +
                " - " +
                measurand.sid +
                "(" +
                measurand.parameter +
                ") Port " +
                measurand.port}
            </h5>
          </Col>
          <Col md={3}>
            <select
              className="form-control"
              value={is_custom ? "overrite" : "standard"}
              onChange={(e) => {
                setIs_custom(e.target.value === "overrite");
                if (e.target.value === "standard") {
                  setMin_value(defaultValue.threshold_min);
                  setMax_value(defaultValue.threshold_max);
                }
              }}
            >
              <option value="standard">Standard</option>
              <option value="overrite">Überschreiben</option>
            </select>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (is_custom) setHasMinValue(!hasMinValue);
                  }}
                >
                  <i
                    className={
                      "fa " +
                      (hasMinValue === true && is_custom
                        ? "fa-check-square"
                        : "fa-square")
                    }
                  />
                </a>
                {"  "}
                Bearbeitung aktivieren
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={1}>
            <div className="column-separator"></div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (is_custom) setHasMaxValue(!hasMaxValue);
                  }}
                >
                  <i
                    className={
                      "fa " +
                      (hasMaxValue === true && is_custom
                        ? "fa-check-square"
                        : "fa-square")
                    }
                  />
                </a>
                {"  "}
                Bearbeitung aktivieren
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Min. Wert:</Form.Label>
              <InputGroup>
                <Form.Control
                  id="threshold_min_input"
                  disabled={!is_custom || !hasMinValue}
                  value={threshold_min ? threshold_min : ""}
                  type="number"
                  onChange={(e) => {
                    setMin_value(e.target.value);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">{unit}</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={1}>
            <div className="column-separator"></div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Max. Wert:</Form.Label>
              <InputGroup>
                <Form.Control
                  id="threshold_max_input"
                  disabled={!is_custom || !hasMaxValue}
                  value={threshold_max ? threshold_max : ""}
                  type="number"
                  onChange={(e) => {
                    setMax_value(e.target.value);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">{unit}</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Min. target:</Form.Label>
              <InputGroup>
                <Form.Control
                  id="target_min_input"
                  disabled={!is_custom || !hasMinValue}
                  value={target_min}
                  type="number"
                  onChange={(e) => {
                    setTargetMin(e.target.value);
                  }}
                />
                {hasSuggestTarget && (
                  <InputGroup.Append>
                    <DropdownButton
                      as={InputGroup.Append}
                      variant="outline-secondary"
                      title=""
                      id="input-group-dropdown-2"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setTargetMin(-30);
                          setTargetMax(100);
                        }}
                      >
                        Trotec (-30 - 100) Celsius
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setTargetMin(5);
                          setTargetMax(95);
                        }}
                      >
                        Trotec (5 - 95) r.H. %
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setTargetMin("0");
                          setTargetMax(3);
                        }}
                      >
                        Holykell (0 - 3) Meter
                      </Dropdown.Item>
                    </DropdownButton>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={1}>
            <div className="column-separator"></div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Max. target:</Form.Label>
              <InputGroup>
                <Form.Control
                  id="target_max_input"
                  disabled={!is_custom || !hasMaxValue}
                  value={target_max}
                  type="number"
                  onChange={(e) => {
                    setTargetMax(e.target.value);
                  }}
                />
                {hasSuggestTarget && (
                  <InputGroup.Append>
                    <DropdownButton
                      as={InputGroup.Append}
                      variant="outline-secondary"
                      title=""
                      id="input-group-dropdown-2"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setTargetMin(-30);
                          setTargetMax(100);
                        }}
                      >
                        Trotec (-30 - 100) Celsius
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setTargetMin(5);
                          setTargetMax(95);
                        }}
                      >
                        Trotec (5 - 95) r.H. %
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setTargetMin("0");
                          setTargetMax(3);
                        }}
                      >
                        Holykell (0 - 3) Meter
                      </Dropdown.Item>
                    </DropdownButton>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Individueller Benachrichtigungstext:</Form.Label>
              <Form.Control
                disabled={!is_custom || !hasMinValue}
                value={min_notif_text ? min_notif_text : ""}
                type="text"
                onChange={(e) => {
                  setMin_text(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={1}>
            <div className="column-separator"></div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Individueller Benachrichtigungstext:</Form.Label>
              <Form.Control
                disabled={!is_custom || !hasMaxValue}
                value={max_notif_text ? max_notif_text : ""}
                type="text"
                onChange={(e) => {
                  setMax_text(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Benachrichtigung einstufen:</Form.Label>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (is_custom && hasMinValue) {
                    setMin_check(true);
                  }
                }}
              >
                <i
                  style={{
                    color:
                      min_check === true && is_custom && hasMinValue
                        ? "#383"
                        : "#333",
                  }}
                  className="fa fa-check-circle "
                />
                {"  "}Information
              </a>
              <a
                style={{ marginLeft: 10 }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (is_custom && hasMinValue) setMin_check(false);
                }}
              >
                <i
                  style={{
                    color:
                      min_check === false && is_custom && hasMinValue
                        ? "#f00"
                        : "#333",
                  }}
                  className="fa fa-times-circle "
                />
                {"  "}Warnhinweis
              </a>
            </Form.Group>
          </Col>
          <Col md={1}>
            <div className="column-separator"></div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Benachrichtigung einstufen:</Form.Label>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (is_custom && hasMaxValue) setMax_check(true);
                }}
              >
                <i
                  style={{
                    color:
                      max_check === true && is_custom && hasMaxValue
                        ? "#383"
                        : "#333",
                  }}
                  className="fa fa-check-circle "
                />
                {"  "}Information
              </a>
              <a
                style={{ marginLeft: 10 }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (is_custom && hasMaxValue) setMax_check(false);
                }}
              >
                <i
                  style={{
                    color:
                      max_check === false && is_custom && hasMaxValue
                        ? "#f00"
                        : "#333",
                  }}
                  className="fa fa-times-circle "
                />
                {"  "}Warnhinweis
              </a>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Turnus:</Form.Label>
              <Form.Control
                id="min_notif_count_input"
                disabled={!is_custom || !hasMinValue}
                value={min_notif_count ? min_notif_count : ""}
                type="number"
                min={1}
                onChange={(e) => {
                  setMin_notif_count(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={1}>
            <div className="column-separator"></div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Turnus:</Form.Label>
              <Form.Control
                id="max_notif_count_input"
                disabled={!is_custom || !hasMaxValue}
                value={max_notif_count ? max_notif_count : ""}
                type="number"
                min={1}
                onChange={(e) => {
                  setMax_notif_count(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIs_active(!is_active);
                  }}
                >
                  <i
                    className={
                      "fa " +
                      (is_active === true
                        ? "fa-check-square"
                        : "fa-square")
                    }
                  />
                  {"  "}Anzeigen im Dashboard
                </a>
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIs_displayed(!is_displayed);
                  }}
                >
                  <i
                    className={
                      "fa " +
                      (is_displayed === true
                        ? "fa-check-square"
                        : "fa-square")
                    }
                  />
                  {"  "}Anzeigen im Diagramm
                </a>
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={1}></Col>
          <Col>
            Einheit:
            <select
              className="form-control"
              disabled={!is_custom}
              value={unit ? unit : ""}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
            >
              <option value="% r.H.">% r.H.</option>
              <option value="°C">°C</option>
              <option value="A">A</option>
              <option value="digits">digits</option>
            </select>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div style={{ textAlign: "right", margin: 15, marginRight: 0 }}>
              <button
                disabled={!updated || isSaving}
                type="submit"
                className={"btn btn-primary"}
              >
                {isSaving ? (
                  <>
                    <Spinner size="sm" animation="border" />
                    {"  Bitte warten..."}
                  </>
                ) : (
                  "Speichern"
                )}
              </button>
              <p style={{ float: "left", color: "#f55" }}>
                {validationMessage}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default SensorThresholdsComponent;
