import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { api_base_url, api_headers } from "../../Configs/Configs";
import { SensorsTypes } from "../../Configs/DeviceConsts";

const SensorDefaultValue = ({ defaultVal }) => {
  const [updated, setUpdated] = useState(false);
  const [min, setMin] = useState(defaultVal.threshold_min);
  const [max, setMax] = useState(defaultVal.threshold_max);
  const [target_min, setTargetMin] = useState(defaultVal.target_min);
  const [target_max, setTargetMax] = useState(defaultVal.target_max);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = (e) => {
    e.preventDefault();
    const postData = async () => {
      setIsSaving(true);
      var formData = new FormData();
      defaultVal["threshold_min"] = min;
      defaultVal["threshold_max"] = max;
      defaultVal["target_min"] = target_min&&target_min!=0 ? target_min : "";
      defaultVal["target_max"] = target_max&&target_max!=0 ? target_max : "";
      for (var key in defaultVal) {
        formData.append(key, defaultVal[key]);
      }
      await Axios({
        method: "post",
        data: formData,
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/measurand_default_property`,
      });
      setIsSaving(false);
      setUpdated(false);
    };
    postData();
  };
  useEffect(() => {
    if (
      min !== defaultVal.threshold_min ||
      max !== defaultVal.threshold_max ||
      target_min !== defaultVal.target_min ||
      target_max !== defaultVal.target_max
    )
      setUpdated(true);
    else setUpdated(false);
  }, [min, max, target_min, target_max]);

  return (
    <>
      <hr />
      <Row>
        <h4>{SensorsTypes[defaultVal.symbol].de + " - " + defaultVal.sid}</h4>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Min:</Form.Label>
            <InputGroup>
              <Form.Control
                onChange={(e) => {
                  setMin(e.target.value);
                }}
                type="input"
                name="min"
                value={min}
                bsClass="form-control txt-input"
              />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">
                  {defaultVal.unit}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Max:</Form.Label>
            <InputGroup>
              <Form.Control
                onChange={(e) => {
                  setMax(e.target.value);
                }}
                type="input"
                name="max"
                value={max}
                bsClass="form-control txt-input"
              />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">
                  {defaultVal.unit}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={1}>
          <button
            style={{ marginTop: 30, width: 40, height: 40 }}
            disabled={!updated || isSaving}
            onClick={handleSave}
            className="btn-save"
          >
            {isSaving ? (
              <>
                <Spinner size="sm" animation="border" />
              </>
            ) : (
              <i className="fa fa-save" />
            )}
          </button>
        </Col>
      </Row>
      {defaultVal.symbol === "amperage" && (
        <Row>
          <Col style={{marginBottom:10}} md={12}><strong >4..20mA Anschluss Umrechnung:</strong></Col>
          <Col>
            <Form.Group>
              <Form.Label>Ziel (Min):</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={(e) => {
                    setTargetMin(e.target.value);
                  }}
                  type="input"
                  name="tmin"
                  value={target_min}
                  bsClass="form-control txt-input"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Ziel (Max):</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={(e) => {
                    setTargetMax(e.target.value);
                  }}
                  type="input"
                  name="tmax"
                  value={target_max}
                  bsClass="form-control txt-input"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={1} />
        </Row>
      )}
    </>
  );
};

export default SensorDefaultValue;
