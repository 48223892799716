import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Login.css";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Spinner,
  Form,
} from "react-bootstrap";
import { api_base_url, api_headers } from "../Configs/Configs";
import { UserContext } from "../Contexts/UserContext";
import ResetPasswordModal from "../Components/Modals/ResetPasswordModal";

const LoginPage = () => {
  const emailRegex = RegExp(
    `^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$`
  );
  // ^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$
  const { NewUser, getUserInfo } = useContext(UserContext);

  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [formValidated, setFormValidated] = useState(false);

  const [openResetPassModal, setOpenResetPassModal] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setFormValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const qs = require("querystring");
    var bodyData = qs.stringify({ email, password });

    setIsSaving(true);
    axios({
      method: "post",
      url: `${api_base_url}/login/api_login`,
      data: bodyData,
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.status === 200 && response.data == false) {
          setValidationMessage("Login fehlgeschlagen. Bitte versuchen Sie es erneut!");
          setIsSaving(false);
          return;
        }
        if (response.status === 200 && response.data) {
          getUserInfo();
        }
        setIsSaving(false);
      })
      .catch(function (response) {
        setValidationMessage(
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal."
        );
        setIsSaving(false);
        return;
      });
  };

  useEffect(() => {
    if (NewUser) {
      setEmail(NewUser.email);
      setPassword(NewUser.password);
    }
  }, []);

  return (
    <>
      <style>
        {`
        .invalid-feedback{
          text-align:left;
        }
        `}
      </style>
      <div className={"Login-container"}>
        <Container className={"content"}>
          <Row>
            <Col md={12}>
              <div>
                <h2 className="card-title">
                  <span className="fa fa-unlock-alt"></span> Anmeldung
                </h2>
                <div>
                  <hr />
                  <Form
                    noValidate
                    validated={formValidated}
                    method="post"
                    onSubmit={handleSubmit}
                    id="login-form"
                  >
                    <div className="text-left">
                      <b>Benutzername</b>
                    </div>
                    <Form.Group>
                      <Form.Label></Form.Label>
                      <Form.Control
                        required
                        type="email"
                        className="form-control"
                        id="emailInput"
                        name="email"
                        autoFocus="autofocus"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte geben Sie eine gültige E-Mail-Adresse ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="Login-input text-left">
                      <b>Passwort</b>
                    </div>
                    <Form.Group className="form-group">
                      <Form.Control
                        required
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                      Bitte geben Sie ein gültiges Passwort ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        Noch nicht registriert?{"      "}
                        <Link to="/register" style={{ fontWeight: "600" }}>
                          Registrieren
                        </Link>
                      </div>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenResetPassModal(true);
                        }}
                        style={{ fontWeight: "600" }}
                      >
                        Passwort vergessen
                      </Link>
                    </div>
                    <div className="Login-submit">
                      <button
                        disabled={isSaving}
                        type="submit"
                        id="submit"
                        className="btn btn-primary"
                        style={{ float: "right" }}
                      >
                        {isSaving ? (
                          <>
                            <Spinner size="sm" animation="border" />
                            {"  Bitte warten..."}
                          </>
                        ) : (
                          "Anmelden"
                        )}
                      </button>
                      <p style={{ float: "left", color: "#f55" }}>
                        {validationMessage}
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          centered
          size="lg"
          className="reset-password-modal"
          // scrollable="true"
          show={openResetPassModal}
          onHide={() => setOpenResetPassModal(false)}
          animation={false}
        >
          <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-at' />Passwort zurücksetzen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ResetPasswordModal
              handleSuccess={() => {
                setShowSuccessDialog(true);
                setOpenResetPassModal(false);
              }}
              handleClose={() => setOpenResetPassModal(false)}
            />
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size="lg"
          animation={false}
          backdrop="static"
          onHide={()=>{setShowSuccessDialog(false)}}
          show={showSuccessDialog}
        >
          <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-check'/>Erfolgreich</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className="fa fa-check fa-5x"
                style={{ color: "#383", margin: 10, marginRight: 15 }}
              />
              <div>
                <p>Passwort wurde erfolgreich zurückgesetzt.</p>
                <p>Bitte überprüfen Sie Ihren E-Mail-Posteingang</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowSuccessDialog(false)}
              href="/#/login"
              className="btn-primary"
            >
              Zur Anmeldeseite
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LoginPage;
