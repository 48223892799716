import React, { useContext } from "react";
import * as moment from "moment";
import "moment/locale/de";
import { NotificationContext } from "../../Contexts/NotificationContext";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NotificationItem = ({ Notification }) => {
  const { setIsNotificationsDialogOpened, dispatchNotification } = useContext(
    NotificationContext
  );
  let history = useHistory();
  const getDate = () => {
    return moment(Notification.date_time).format("DD.MM.YYYY HH:mm");
  };
  const getAlertTitle = () => {
    return Notification.message_info;
    // Notification.configuration_id != 0
    //   ? Notification.message_info
    //   : Notification.connection_status == "1"
    //   ? "Wurde verbunden"
    //   : "Keine verbindung";
  };
  const getType = () => {
    if (
      Notification.configuration_id &&
      Notification.configuration_id != 0 &&
      Notification.msg
    ) {
      let arr = Notification.msg.split("@@@");
      if (arr.length > 1) return arr[1];
      else return "error";
    }

    return Notification.connection_status == "1" ? "success" : "error";
  };

  return (
    <div
      className={"notification-item "}
      onClick={(e) => {
        e.preventDefault();
        if (
          Notification.configuration_id &&
          Notification.configuration_id > 0
        ) {
          history.push(
            `/dashboard/${Notification.configuration_id}/${
              Notification.device_id ? Notification.device_id : ""
            }`
          );
        }
        dispatchNotification({ type: "SEEN", item: Notification });
        setIsNotificationsDialogOpened(false);
      }}
    >
      <div className="notif-btns">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <p>Benachrichtigung löschen</p>
            </Tooltip>
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dispatchNotification({ type: "REMOVE", item: Notification });
            }}
            className="del"
          >
            <small className="fa fa-times" />
          </a>
        </OverlayTrigger>
        {Notification.is_seen_by_user == 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                <p>Als gelesen markieren</p>
              </Tooltip>
            }
          >
            <a
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dispatchNotification({ type: "SEEN", item: Notification });
              }}
              className="seen"
            >
              <small className="fa fa-circle" />
            </a>
          </OverlayTrigger>
        )}
      </div>
      <div className="notification-item-top">
        <div className="notification-item-right">
          <div className="notif-icon">
            <i className={""} />
          </div>
          <div className="notif-device">
            <strong>
              {Notification.custom_message1
                ? `${Notification.custom_message1} (${Notification.bwb})`
                : Notification.bwb}
            </strong>
          </div>
        </div>
        <div className={"notif-type " + getType()}>{getAlertTitle()}</div>
      </div>
      <div className="notification-message">{`${Notification.sid} - Port ${Notification.port}`}</div>
      <div className="notification-message">
        {Notification.configuration_id != 0 && Notification.msg
          ? Notification.msg.split("@@@")[0]
          : ""}
      </div>
      <div
        className={
          "item-date " +
          (Notification.is_seen_by_user == 0 ? "unseen-date" : "")
        }
        style={{ float: "right" }}
      >
        <small>{getDate()}</small>
      </div>
    </div>
  );
};
export default NotificationItem;
