import React, { useEffect, useState, useContext } from "react";

import {
  Button,
  Modal,
  ListGroup,
  Collapse,
  Spinner,
  Form,
  Col,
  Row,
  Container,
} from "react-bootstrap";

import { api_base_url } from "../Configs/Configs";
import "./DeviceSetsPage.css";
import "../assets/css/modals.css";
import DeviceSetItem from "../Components/DevicesPage/DeviceSetItem";
import Axios from "axios";
import {
  DeviceSetsActionsContext,
  DeviceSetsContext,
} from "../Contexts/DeviceSetsContext";
import { useHistory } from "react-router-dom";

const DeviceSetsPage = () => {
  let history = useHistory();
  const {
    DeviceSetsList,
    IsLoading,
    CreateDeviceSetModalOpened,
    EditDeviceSetModalOpened,
    SelectedDeviceSet,
    InfoModalOpened,
  } = useContext(DeviceSetsContext);
  const {
    dispatchDeviceSet,
    setSelectedDeviceSet,
    setCreateDeviceSetModalOpened,
    setEditDeviceSetModalOpened,
    setInfoModalOpened,
  } = useContext(DeviceSetsActionsContext);

  const [DeviceSetName, setDeviceSetName] = useState("");
  const [DeviceSetDetails, setDeviceSetDetails] = useState("");

  useEffect(() => {
    if (SelectedDeviceSet && EditDeviceSetModalOpened) {
      setDeviceSetName(SelectedDeviceSet.name);
      setDeviceSetDetails(SelectedDeviceSet.details);
    }
    if (CreateDeviceSetModalOpened) {
      setDeviceSetName("");
      setDeviceSetDetails("");
    }
  }, [EditDeviceSetModalOpened, CreateDeviceSetModalOpened]);

  const handleSaveSetName = async () => {
    var formData = new FormData();
    var newSet = {
      name: DeviceSetName,
      details: DeviceSetDetails,
      custom_field_1: "",
      custom_field_2: "",
      custom_field_3: "",
    };
    for (var key in newSet) {
      formData.append(key, newSet[key]);
    }
    await Axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/deviceSets/create_device_set`,
    }).then((response) => {
      if (response.data && response.status === 200) {
        newSet["id"] = response.data.device_set_id;
        dispatchDeviceSet({ type: "ADD", deviceSet: newSet });
        setCreateDeviceSetModalOpened(false);
      }
    });
  };
  const handleEditSetName = async () => {
    var formData = new FormData();
    var editedSet = {
      ...SelectedDeviceSet,
      name: DeviceSetName,
      details: DeviceSetDetails,
    };
    for (var key in editedSet) {
      formData.append(key, editedSet[key]);
    }
    await Axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/deviceSets/edit_device_set`,
    }).then((response) => {
      if (response.data && response.status === 200) {
        dispatchDeviceSet({ type: "EDIT", deviceSet: editedSet });
        setEditDeviceSetModalOpened(false);
      }
    });
  };

  return (
    <div className="devices-container">
      {IsLoading ? (
        <div style={{ textAlign: "center", margin: 30 }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="content">
          <div className="devices-header">
            <div>
              <p className="title">Geräte-Sets (Messgruppen)</p>
            </div>
            <div style={{ float: "right" }}>
              <Button
                variant="light"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setSelectedDeviceSet(null);
                  history.push("devices/all");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <i className="fa fa-plus" style={{ marginRight: 10 }} />  */}
                  Alle Geräte anzeigen
                </div>
              </Button>
              <Button
                variant="primary"
                onClick={() => setCreateDeviceSetModalOpened(true)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i className="fa fa-plus" style={{ marginRight: 10 }} /> Neues Geräte-Set hinzufügen
                </div>
              </Button>
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {DeviceSetsList.map((DeviceSet) => {
              return (
                <DeviceSetItem
                  style={{ width: "250px", marginRight: 15, marginTop: 15 }}
                  key={DeviceSet.id}
                  DeviceSet={DeviceSet}
                />
              );
            })}
          </div>
          <hr />
          <Modal
            centered
            size="lg"
            show={CreateDeviceSetModalOpened}
            onHide={() => {
              setCreateDeviceSetModalOpened(false);
            }}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-plus" style={{ marginLeft: 0 }} />
                Gerät set hinzufügen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Form id="form">
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                          autoFocus
                          onChange={(e) => {
                            setDeviceSetName(e.target.value);
                          }}
                          value={DeviceSetName}
                          type="text"
                          bsClass="txt-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Notiz:</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setDeviceSetDetails(e.target.value);
                          }}
                          value={DeviceSetDetails}
                          type="text"
                          bsClass="txt-input"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveSetName();
                }}
                variant="primary"
              >
                Speichern
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            centered
            size="lg"
            show={EditDeviceSetModalOpened}
            onHide={() => {
              setEditDeviceSetModalOpened(false);
            }}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-pencil" style={{ marginLeft: 0 }} />
                Gerät set bearbeiten
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Form id="form">
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                          autoFocus
                          onChange={(e) => {
                            setDeviceSetName(e.target.value);
                          }}
                          value={DeviceSetName}
                          type="text"
                          bsClass="txt-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Notiz:</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setDeviceSetDetails(e.target.value);
                          }}
                          value={DeviceSetDetails}
                          type="text"
                          bsClass="txt-input"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleEditSetName();
                }}
                variant="primary"
              >
                Speichern
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default DeviceSetsPage;
