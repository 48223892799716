/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import * as moment from "moment";
import "moment/locale/de";

import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import {
  ArchiveRowContext,
  ArchiveRowActionsContext,
} from "../../Contexts/ArchiveRowContext";
import {
  ArchiveActionsContext,
  ArchiveContext,
} from "../../Contexts/ArchiveContext";

const MeasurementGroupItem = () => {
  const {
    setChartModalOpened,
    setEditModalOpenMode,
    setInfoModalOpened,
    setSelectedDeviceGroup,
    setChartParams,
    setOpenedGroupId,
  } = useContext(ArchiveActionsContext);
  const {
    DeviceGroup,
    Configuration,
    SensorsList,
    ActiveSensorsCount,
    InActiveSensorsCount,
    cssClassName,
    ThresholdSensorsCount,
    AllSensorsList,
    GroupLastValues,
  } = useContext(ArchiveRowContext);
  const{setShowConfirmDeleteModal}=useContext(ArchiveRowActionsContext);
  const { OpenedGroupId } = useContext(ArchiveContext);

  return (
    <tr 
    onClick={(e) => {
      e.preventDefault();
      if (OpenedGroupId === DeviceGroup.id) setOpenedGroupId(0);
      else setOpenedGroupId(DeviceGroup.id);
    }} style={{cursor:'pointer',backgroundColor:DeviceGroup.deleted==1?'#aaa':'#eee'}}>
      <td className="column column1">
        <div className="title">{DeviceGroup.title} </div>
        {Configuration && <div className="desc">{Configuration.location} </div>}
      </td>
      <td className="column column2">
        {Configuration && Configuration.starts_at ? (
          <div className="title">
            {moment(Configuration.starts_at).fromNow()}
          </div>
        ) : (
          <></>
        )}
        {Configuration && (
          <div className="title">
            {moment(Configuration.starts_at).format("DD.MM.YYYY HH:mm")}
          </div>
        )}
      </td>
      <td className="column column2">
        {Configuration && Configuration.ends_at&& (
          <div className="title">
            {moment(Configuration.ends_at).fromNow()}
          </div>
        )}
        {Configuration && Configuration.ends_at&& (
          <div className="title">
            {moment(Configuration.ends_at).format("DD.MM.YYYY HH:mm")}
          </div>
        )}
      </td>
      <td className="column column2">
        {Configuration && Configuration.note_1&&Configuration.note_1!='null'&& (
          <div className="desc">
            {Configuration.note_1}
          </div>
        )}
      </td>
      <td className="column column5">
        {AllSensorsList.length > 0 && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (OpenedGroupId === DeviceGroup.id) setOpenedGroupId(0);
              else setOpenedGroupId(DeviceGroup.id);
            }}
            aria-controls={"groupRow" + DeviceGroup.id}
            aria-expanded={OpenedGroupId === DeviceGroup.id}
          >
            <div>
              {OpenedGroupId === DeviceGroup.id ? (
                <i className="fa fa-chevron-circle-up" />
              ) : (
                <i className="fa fa-chevron-circle-down" />
              )}
            </div>
          </a>
        )}
        {SensorsList.length === 0 && <i className="fa fa-times status-warn" />}
      </td>
      <td className="column column7">
        <ButtonToolbar
          aria-label="Toolbar with button groups"
          style={{ padding: 5 }}
        >
          <ButtonGroup>
            <Button
              variant={DeviceGroup.deleted?'dark':"light"}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDeviceGroup(DeviceGroup);
                setChartParams({ type: "group", object: {...DeviceGroup,start_date:Configuration.starts_at,end_date:Configuration.ends_at} });
                setChartModalOpened(true);
              }}
            >
              <i className="buttons fa fa-line-chart" />
            </Button>
            {DeviceGroup.deleted!=1&&<Button
              variant={DeviceGroup.deleted?'dark':"light"}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDeviceGroup(DeviceGroup);
                setShowConfirmDeleteModal(true);
              }}
            >
              <i className="buttons fa fa-trash" />
            </Button>}
          </ButtonGroup>
        </ButtonToolbar>
      </td>
    </tr>
  );
};

export default MeasurementGroupItem;
