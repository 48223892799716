import React, { useContext } from "react";
import {
  Table,
  Collapse,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ArchiveContext } from "../../Contexts/ArchiveContext";
import { UserContext } from "../../Contexts/UserContext";
import {
  ArchiveRowActionsContext,
  ArchiveRowContext,
} from "../../Contexts/ArchiveRowContext";
import SensorItem from "./SensorItem";

const SensorsComponent = () => {
  const { SensorsDefaultsList } = useContext(UserContext);
  const { DevicesList, OpenedGroupId } = useContext(ArchiveContext);
  const {
    SensorsList,
    cssClassName,
    GroupLastValues,
    DeviceGroup,
    showAllSensors,
  } = useContext(ArchiveRowContext);
  const { setShowAllSensors } = useContext(ArchiveRowActionsContext);

  return (
    <tr>
      <td colSpan={7} style={{ padding: "5px 10px" }}>
        <Collapse in={OpenedGroupId === DeviceGroup.id}>
          <div id={"groupRow" + DeviceGroup.id}>
            <Table
              className={cssClassName}
              style={{
                borderLeft: "solid 1px #999",
                borderRight: "solid 1px #999",
              }}
            >
              <thead>
                <tr>
                  <th className="column-header column1">Sensor</th>
                  <th className="column-header">Zeitpunkt</th>
                  <th className="column-header">Letzter Messwert</th>
                </tr>
              </thead>
              <tbody>
                {SensorsList.map((sensor) => {
                    return (
                      <SensorItem
                        sensor={sensor}
                        key={sensor.measurand_display_id}
                      />
                    );
                })}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </td>
    </tr>
  );
};
export default SensorsComponent;
