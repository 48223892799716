import React, { createContext, useState } from "react";

export const CreateDeviceModalContext = createContext();

const CreateDeviceModalContextProvider = (props) => {
  const [screenNo, setScreenNo] = useState(0);
  return (
    <CreateDeviceModalContext.Provider value={{ screenNo, setScreenNo }}>
      {props.children}
    </CreateDeviceModalContext.Provider>
  );
};

export default CreateDeviceModalContextProvider;
