import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";
import * as moment from "moment";
import "moment/locale/de";
import Push from "push.js";
import { Toast } from "react-bootstrap";
import Axios from "axios";
import { api_base_url, webApp_base_url } from "../Configs/Configs";
import { DeviceTypes } from "../Configs/DeviceConsts";
import { useHistory, withRouter } from "react-router-dom";
import { UserContext } from "./UserContext";

const NotificationsReducer = (state, action) => {
  var postData = null;
  switch (action.type) {
    case "SET":
      return action.list;
      break;
    case "ADD":
      //action.item["id"] = state.length + 1;
      return [action.item, ...state];
      break;
    case "CLEAR":
      return [];
      break;
    case "REMOVE":
      postData = async () => {
        await Axios({
          method: "post",
          withCredentials: true,
          url: `${api_base_url}/userAlert/delete/${action.item.id}`,
        });
      };
      postData();
      return state.filter((item) => item.id !== action.item.id);
      break;
    case "REMOVE_ALL":
      postData = async () => {
        await Axios({
          method: "post",
          withCredentials: true,
          url: `${api_base_url}/userAlert/delete_all`,
        });
      };
      postData();
      return [];
      break;
    case "SEEN":
      postData = async () => {
        await Axios({
          method: "post",
          withCredentials: true,
          url: `${api_base_url}/userAlert/seen/${action.item.id}`,
        });
      };
      postData();
      var edited = [...state].map((item) => {
        if (item.id === action.item.id) {
          item["is_seen_by_user"] = true;
          return item;
        } else return item;
      });
      return edited;
      break;
    case "EDIT":
      var edited = [...state].map((item) => {
        if (item.id === action.item.id) {
          return action.item;
        } else return item;
      });
      return edited;
      // var array = require("lodash");
      // return array.sortBy(...edited, [
      //   function (n) {
      //     return n.date_time;
      //   },
      // ]);
      break;
    case "SEEN_ALL":
      postData = async () => {
        await Axios({
          method: "post",
          withCredentials: true,
          url: `${api_base_url}/userAlert/seen_all`,
        });
      };
      postData();
      var edited = [...state].map((item) => {
        item["is_seen_by_user"] = true;
        return item;
      });
      return edited;
      break;
    default:
      return state;
  }
};
const ToastsReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      action.toast["id"] = state.length + 1;
      action.toast["show"] = true;
      return [...state, action.toast];
    case "CLEAR":
      return [];
    case "REMOVE":
      var edited = [...state].map((item) => {
        if (item.id === action.toast.id) {
          item["show"] = false;
          return item;
        } else return item;
      });
      return edited;
    default:
      return [...state];
  }
};

export const NotificationContext = React.createContext();

const NotificationContextProvider = (props) => {
  const [ToastsList, dispatchToast] = useReducer(ToastsReducer, []);

  const { checkUserSession } = useContext(UserContext);
  let history = useHistory();
  const [
    IsNotificationsDialogOpened,
    setIsNotificationsDialogOpened,
  ] = useState(false);

  const [Loading, setLoading] = useState(false);

  const getNotificationsList = () => {
    const localData = localStorage.getItem("alerts");
    return localData ? JSON.parse(localData) : [];
  };
  const [needReload, setNeedReload] = useState(true);
  const [unseenCount, setUnseenCount] = useState(0);
  const [NotificationsList, dispatchNotification] = useReducer(
    NotificationsReducer,
    [],
    getNotificationsList
  );
  const ShowNotification = (notification) => {
    try {
      notification["link"] = notification.configuration_id
        ? webApp_base_url +
          "/#/dashboard/" +
          notification.configuration_id +
          "/" +
          notification.sensor
        : ""; // webApp_base_url + "/devices";
    } catch (error) {}
    try {
      if (!document.hasFocus()) {
        if (Push.Permission.has()) {
          Push.create(notification.title, {
            body:
              notification.message +
              (notification.msg ? "\n" + notification.msg : ""),
            timeout: 4000,
            onClick: function () {
              window.focus();
              history.push(
                notification.configuration_id
                  ? "/#/dashboard/" +
                      notification.configuration_id +
                      "/" +
                      notification.sensor
                  : "/devices/"
              );
              this.close();
            },
          });
        }
      } else {
        dispatchToast({ type: "ADD", toast: notification });
      }
    } catch (error) {
      dispatchToast({ type: "ADD", toast: notification });
    }
  };

  const handlePushAlert = useCallback(
    (alert) => {
      if (!alert.is_temp || alert.is_temp == false) {
        let type = alert.alert_type;
        if (
          alert.configuration_id &&
          alert.configuration_id != 0 &&
          alert.msg
        ) {
          let arr = alert.msg.split("@@@");
          if (arr.length > 1) type = arr[1];
          else type = "error";
        }
        ShowNotification({
          type,
          message: alert.custom_message1
            ? `${alert.custom_message1} (${alert.bwb})\n${alert.sid} - Port ${alert.port}`
            : `${alert.bwb}\n${alert.sid} - Port ${alert.port}`,
          title: alert.message_info,
          location: alert.location ? alert.location : "",
          configuration_name: alert.custom_message1
            ? alert.custom_message1
            : "",
          configuration_id: alert.configuration_id,
          msg: alert.msg ? alert.msg.split("@@@")[0] : "",
          sensor: alert.device_id,
        });
      }
    },
    [NotificationsList]
  );

  useEffect(() => {
    Push.Permission.request();

    const getCount = async () => {
      if (! checkUserSession()) return;
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/userAlert/get_unseen_count`,
      }).then(async (response) => {
        if (
          response.data &&
          response.status === 200 &&
          response.data.unseen_alert_count
        ) {
          setUnseenCount(parseInt(response.data.unseen_alert_count));
          let lastAlert = null;
          try {
            let localData = localStorage.getItem("lastAlert");
            lastAlert = localData ? JSON.parse(localData) : null;
          } catch (error) {
            //localStorage.removeItem("lastAlert");
          }
          if (parseInt(response.data.unseen_alert_count) > 0) {
            await Axios({
              method: "get",
              withCredentials: true,
              url: `${api_base_url}/userAlert/get_last_unseen/5`, //replace with get_last
            }).then(async (response) => {
              if (
                response.data &&
                response.status === 200 &&
                response.data.last_alerts
              ) {
                for (
                  let index = response.data.last_alerts.length - 1;
                  index >= 0;
                  index--
                ) {
                  const element = response.data.last_alerts[index];
                  if (element.is_seen_by_user == 0) {
                    if (lastAlert && lastAlert.date_time) {
                      if (
                        moment(element.date_time).isAfter(
                          lastAlert.date_time
                        ) &&
                        element.id > lastAlert.id
                      ) {
                        handlePushAlert(element);
                      }
                    } else {
                      handlePushAlert(element);
                    }
                  }
                  if (index === 0) {
                    let last = response.data.last_alerts[0];
                    localStorage.setItem("lastAlert", JSON.stringify(last));
                  }
                }
              }
            });
          }
        }
      });
    };
    const alertssInterval = setInterval(() => {
      getCount();
    }, 30000);
    return () => {
      clearInterval(alertssInterval);
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        ShowNotification,
        NotificationsList,
        dispatchNotification,
        IsNotificationsDialogOpened,
        setIsNotificationsDialogOpened,
        unseenCount,
        setUnseenCount,
        Loading,
        setLoading,
        needReload,
        setNeedReload,
      }}
    >
      {props.children}
      <>
        <div
          style={{
            zIndex: 10000,
            position: "fixed",
            bottom: 20,
            right: 20,
            top: "auto",
            left: "auto",
            minWidth: 400,
          }}
        >
          {ToastsList.map((toast) => {
            let ToastBgColor =
              toast.type === "success"
                ? "#383"
                : toast.type === "error"
                ? "#f00"
                : "";
            return (
              <Toast
                key={toast.id}
                onClose={() => {
                  dispatchToast({ type: "REMOVE", toast: toast });
                }}
                show={toast.show}
                autohide
                delay={4000}
              >
                <Toast.Header style={{ color: ToastBgColor, padding: 10 }}>
                  <strong className="mr-auto">{toast.title}</strong>
                </Toast.Header>
                {toast.message&&<Toast.Body style={{ textAlign: "left" }}>
                  {toast.message}
                </Toast.Body>}
              </Toast>
            );
          })}
        </div>
      </>
    </NotificationContext.Provider>
  );
};

export default withRouter(NotificationContextProvider);
