import React, { useEffect, useState, useContext } from "react";

import {
  Modal,
  Table,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import ChartsModal from "../Components/Modals/ChartsModal";
import CreateGroupModel from "../Components/Modals/CreateGroupModel";
import GroupInfoModal from "../Components/Modals/GroupInfoModal";

import "./DashboardPage.css";
import "../assets/css/modals.css";
import GroupRowComponent from "../Components/DashboardPage/GroupRowComponent";
import {
  DashboardContext,
  DashboardActionsContext,
} from "../Contexts/DashboardContext";
import { DeviceGroupContextProvider } from "../Contexts/DeviceGroupContext";
import SensorThresholdsComponent from "../Components/Modals/SensorThresholdsComponent";

const DashboardPage = () => {
  const {
    IsLoading,
    GroupsList,
    ChartParams,
    ChartModalOpened,
    InfoModalOpened,
    EditModalOpenMode,
    ThresholdsModalOpened,
    SelectedSensor,
    reloadSensorHandler,
    SensorsFilter,
  } = useContext(DashboardContext);
  const {
    setChartModalOpened,
    setInfoModalOpened,
    setEditModalOpenMode,
    setThresholdsModalOpened,
    setSensorsFilter,
  } = useContext(DashboardActionsContext);

  return (
    <div className="dashboard-container">
      {IsLoading ? (
        <div style={{ textAlign: "center", margin: 30 }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="content">
          <div className="dashboard-header">
            <div style={{ display: "flex" }}>
              <p className="title">Aktive Projekte</p>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", marginRight: 20, width: 310 }}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text as="i" className="fa fa-search fa-sm" />
                  </InputGroup.Prepend>
                  <Form.Control
                    style={{ height: "100%" }}
                    type="text"
                    placeholder="MMUI (MultiMeasure Unique ID)"
                    value={SensorsFilter}
                    onChange={(e) => {
                      setSensorsFilter(e.target.value);
                    }}
                  />
                  {/* {SensorsFilter && SensorsFilter.length > 0 && (
                  <InputGroup.Append>
                    <Button
                      as="i"
                      className="fa fa-times"
                      variant="danger"
                      size="xs"
                    ></Button>
                  </InputGroup.Append>
                )} */}
                </InputGroup>{" "}
                {SensorsFilter && SensorsFilter.length > 0 && (
                  <Button
                    onClick={() => {
                      setSensorsFilter("");
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      marginTop: 3,
                      marginLeft: -40,
                      zIndex: 55,
                      background: "none",
                      color: "red",
                      border: "none",
                      boxShadow: "none",
                    }}
                    as="i"
                    className="fa fa-times"
                    variant="danger"
                    size="xs"
                  ></Button>
                )}
              </div>
              <Button
                className="btn btn-primary"
                onClick={() => setEditModalOpenMode(1)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i className="fa fa-plus" style={{ marginRight: 10 }} />

                  {"Projekt anlegen"}
                </div>
              </Button>{" "}
            </div>
          </div>
          <div>
            <Table responsive style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="column-header column1">Projekte</th>
                  <th className="column-header">Beginn / Dauer</th>
                  <th className="column-header">Grenzwerte</th>
                  <th className="column-header">Daten</th>
                  <th className="column-header">Geräte anzeigen</th>
                  <th className="column-header">Verbindung</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {GroupsList.filter(c=>c.customer_field_1!='ARCHIV').map((group) => {
                  return (
                    <DeviceGroupContextProvider Group={group} key={group.id}>
                      <GroupRowComponent />
                    </DeviceGroupContextProvider>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
     {ChartModalOpened&& <ChartsModal
        ChartModalOpened={ChartModalOpened}
        Params={ChartParams}
        handleClose={() => {
          setChartModalOpened(false);
        }}
      />}
      <Modal
        className="info-modal"
        centered
        size="xl"
        show={InfoModalOpened}
        onHide={() => setInfoModalOpened(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-sitemap' />Geräteübersicht des Projekts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GroupInfoModal />
        </Modal.Body>
      </Modal>
      <Modal
        className="create-group-modal"
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        show={EditModalOpenMode > 0}
        onHide={() => setEditModalOpenMode(0)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as='strong'><i className={EditModalOpenMode == 1?'fa fa-plus':'fa fa-pencil'} />
            {EditModalOpenMode == 1 && <>Projekt anlegen</>}
            {EditModalOpenMode == 2 && <>Projekt bearbeiten</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateGroupModel handleClose={() => setEditModalOpenMode(0)} />
        </Modal.Body>
      </Modal>
      <Modal
        className="sensor-thresholds-modal"
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        show={ThresholdsModalOpened}
        onHide={() => setThresholdsModalOpened(false)}
        animation={false}
      >
        <Modal.Body>
          <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-cog' style={{marginLeft:0}}/>Sensorkonfiguration bearbeiten</Modal.Title>
          </Modal.Header>
          <SensorThresholdsComponent
            displayInModal={true}
            bsClassName="statusRowClass5"
            measurand={SelectedSensor}
            handleReload={reloadSensorHandler}
            handleClose={() => {
              setThresholdsModalOpened(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default DashboardPage;
