import React, { useContext, useState, useEffect } from "react";
import { Button, ButtonToolbar, ButtonGroup, Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import * as moment from "moment";
import "moment/locale/de";
import SensorThresholdsComponent from "../Modals/SensorThresholdsComponent";
import {
  DashboardActionsContext,
  DashboardContext,
} from "../../Contexts/DashboardContext";
import {
  DeviceGroupActionsContext,
  DeviceGroupContext,
} from "../../Contexts/DeviceGroupContext";
import { SensorsTypes } from "../../Configs/DeviceConsts";
import Axios from "axios";
import { api_base_url } from "../../Configs/Configs";
import { isNumber } from "../../shared/numberTool";
import { UserContext } from "../../Contexts/UserContext";
import { DeviceSetsContext } from "../../Contexts/DeviceSetsContext";

const SensorItem = ({ sensor, device, latest_value, default_val }) => {
  const {
    setChartModalOpened,
    setChartParams,
    setThresholdsModalOpened,
    setSelectedSensor,
    setReloadSensorHandler,
  } = useContext(DashboardActionsContext);
  const { DevicesList } = useContext(DashboardContext);
  const { User, checkUserSession } = useContext(UserContext);
  const { DeviceSetsList } = useContext(DeviceSetsContext);
  const {
    selectedSensorId,
    sensorsThresholdsStatusList,
    sensorsActiveStatusList,
    sensorsDataStatusList,
    DeviceGroup,
    Configuration,
  } = useContext(DeviceGroupContext);
  const { refreshSensorsList } = useContext(DeviceGroupActionsContext);

  const id =
    "lastValueTooltip" + sensor.id + sensor.sid + sensor.bwb + sensor.parameter; //sensor.mtb;
  const [unit, setUnit] = useState("");
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [thresholdStatus, setThresholdStatus] = useState(0);
  const [activeStatus, setActiveStatus] = useState(true);
  const [dataStatus, setDataStatus] = useState(true);
  const [color, setColor] = useState();
  const [icon, setIcon] = useState();
  const [tooltipType, setTooltipType] = useState("dark");
  const [customMessage, setCustomMessage] = useState("");

  const [reload, setReload] = useState({ s: 0 });

  const [calculatedValue, setCalculatedValue] = useState("");

  const calculateValue = () => {
    let calculated_value =
      latest_value && latest_value.value && sensor.extra_field_2 != 1
        ? latest_value.value
        : sensor.last_value;
    // if(latest_value&&latest_value.value){
    if (sensor.calculatable == 1 && sensor.target_max && sensor.target_min) {
      let diff = parseFloat(sensor.target_max) - parseFloat(sensor.target_min);
      let factor = parseFloat(diff / 0.016);
      calculated_value = calculated_value - 0.004;
      calculated_value = calculated_value * factor;
      calculated_value = calculated_value + parseFloat(sensor.target_min);
      calculated_value = parseFloat(calculated_value).toFixed(2);
    }
    // }
    return calculated_value;
  };

  const getRelatedDeviceSetStatus = () => {
    if (device) {
      let dtb = DevicesList.find((c) => c.deviceId == sensor.dtb);
      if (dtb && dtb.device_set_id) {
        let deviceSet = DeviceSetsList.find((c) => c.id == dtb.device_set_id);
        if (deviceSet) {
          if (deviceSet.project_id == DeviceGroup.id) {
            setActiveStatus(latest_value ? true : false);
            return 0;
          }
        }
      }
    }
    setActiveStatus(false);
    return 1;
  };

  const saveSensorInfo = async () => {
    sensor["threshold_min"] = isNumber(sensor.threshold_min)
      ? sensor.threshold_min
      : "";
    sensor["threshold_max"] = isNumber(sensor.threshold_max)
      ? sensor.threshold_max
      : "";
    sensor["target_min"] = isNumber(sensor.target_min) ? sensor.target_min : "";
    sensor["target_max"] = isNumber(sensor.target_max) ? sensor.target_max : "";
    sensor["extra_field_1"] = sensor.extra_field_1;
    sensor["extra_field_2"] = sensor.extra_field_2;
    sensor["bwb_name"] = device
      ? device.user_given_name
      : sensor.bwb_name && sensor.bwb_name != "null"
      ? sensor.bwb_name
      : "";
    sensor["unit"] = latest_value
      ? latest_value.unit
      : default_val
      ? default_val.unit
      : sensor.unit;
    sensor["last_value"] = calculateValue();
    sensor["last_value_uts"] =
      latest_value && sensor.extra_field_2 != 1
        ? latest_value.UTS
        : sensor.last_value_uts;
    sensor["device_type"] = latest_value ? latest_value.symbol : sensor.symbol;
    sensor["symbol"] = latest_value ? latest_value.symbol : sensor.symbol;
    var val = {
      ...sensor,
      user_id: User.id,
    };
    var formData = new FormData();
    for (var key in val) {
      formData.append(key, val[key]);
    }
    await Axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/apiMeasurementConfiguration/change_measurand_properties/1`,
    });
  };

  useEffect(() => {
    setDataStatus(
      sensor.extra_field_2 == 1 || !latest_value ||
        (latest_value && latest_value.old === true)
        ? false
        : true
    );
    sensor["extra_field_2"] = getRelatedDeviceSetStatus();
    if (sensor.unit) setUnit(sensor.unit);
    let val = calculateValue();
    setCalculatedValue(val);
    //if (!device || !sensor) return;
    if (sensor && !sensor.bwb_name && !latest_value) {
      saveSensorInfo();
    }
    if (true) {
      if (latest_value) setUnit(latest_value.unit);
      let min_check =
        sensor.threshold_min || default_val.threshold_min
          ? sensor.extra_field_1 == 1 &&
            sensor.extra_field_2 != 1 &&
            sensor.min_notif_text &&
            sensor.min_notif_text.split("@@@").length > 1
            ? sensor.min_notif_text.split("@@@")[1]
            : "error"
          : "";
      let max_check =
        sensor.threshold_max || default_val.threshold_max
          ? sensor.extra_field_1 == 1 &&
            sensor.extra_field_2 != 1 &&
            sensor.max_notif_text &&
            sensor.max_notif_text.split("@@@").length > 1
            ? sensor.max_notif_text.split("@@@")[1]
            : "error"
          : "";
      let minV =
        sensor.extra_field_1 == 1 &&
        sensor.extra_field_2 != 1 &&
        sensor.threshold_min
          ? parseFloat(sensor.threshold_min)
          : default_val.threshold_min
          ? parseFloat(default_val.threshold_min)
          : "Unbekannt";
      setMin(minV);
      let maxV =
        sensor.extra_field_1 == 1 &&
        sensor.extra_field_2 != 1 &&
        sensor.threshold_max
          ? parseFloat(sensor.threshold_max)
          : default_val.threshold_max
          ? parseFloat(default_val.threshold_max)
          : "Unbekannt";
      setMax(maxV);
      // if (sensor.extra_field_1 == 1) {
      if (
        (default_val.threshold_min || sensor.threshold_min) &&
        val < minV &&
        sensor.extra_field_1 == 1 &&
        sensor.extra_field_2 != 1
      ) {
        setTooltipType(min_check);
        if (min_check === "error") {
          setColor("#f55");
          setIcon("fa fa-times");
          setThresholdStatus(0);
        } else {
          setThresholdStatus(2);
          setColor("#0e9d19");
          setIcon("fa fa-check");
        }
        setCustomMessage(
          sensor.min_notif_text ? sensor.min_notif_text.split("@@@")[0] : null
        );
      } else if (
        (default_val.threshold_max || sensor.threshold_max) &&
        val > maxV &&
        sensor.extra_field_1 == 1 &&
        sensor.extra_field_2 != 1
      ) {
        setTooltipType(max_check);
        if (max_check === "error") {
          setColor("#f55");
          setIcon("fa fa-times");
          setThresholdStatus(0);
        } else {
          setThresholdStatus(2);
          setColor("#0e9d19");
          setIcon("fa fa-check");
        }
        setCustomMessage(
          sensor.min_notif_text ? sensor.max_notif_text.split("@@@")[0] : null
        );
      } else if (
        !default_val.threshold_min &&
        !default_val.threshold_max &&
        (sensor.extra_field_1 != 1 || sensor.extra_field_2 == 1)
      ) {
        setColor("#333");
        setIcon("");
        setThresholdStatus(1);
      } else {
        setThresholdStatus(2);
        setColor("#0e9d19");
        setIcon("fa fa-check");
        setTooltipType("success");
      }
      // } else {
      //   setColor("#333");
      //   setIcon("");
      //   setThresholdStatus(1);
      // }
      if (latest_value) {
        saveSensorInfo();
      }
      if (!device) {
        setThresholdStatus(0);
      }
    }
  }, [sensor, device, default_val, latest_value, reload.s]);

  useEffect(() => {
    if (!latest_value || !sensor || sensor.extra_field_2 == 1) {
      sensorsThresholdsStatusList[sensor.id] = null;
      return;
    }
    if (
      sensorsThresholdsStatusList[sensor.id] &&
      ((thresholdStatus === 0 &&
        sensorsThresholdsStatusList[sensor.id] === false) ||
        ((thresholdStatus === 1 || thresholdStatus === 2) &&
          sensorsThresholdsStatusList[sensor.id] === true))
    ) {
      return;
    }
    sensorsThresholdsStatusList[sensor.id] =
      thresholdStatus === 2 || thresholdStatus === 1 ? true : false;
  }, [sensorsThresholdsStatusList, thresholdStatus, sensor.extra_field_2]);

  useEffect(() => {
    sensorsActiveStatusList[sensor.id] = activeStatus;
  }, [sensorsActiveStatusList, activeStatus]);

  useEffect(() => {
    sensorsDataStatusList[sensor.id] = dataStatus;
  }, [sensorsDataStatusList, dataStatus]);

  useEffect(() => {
    refreshSensorsList();
  }, [reload.s]);

  return (
    <tr
      style={{
        fontStyle: sensor.extra_field_2 == 1 ? "italic" : "normal",
        animationName:
          selectedSensorId == sensor.id && latest_value
            ? thresholdStatus === 2 || thresholdStatus === 1
              ? "sensor-row-success-animation"
              : "sensor-row-error-animation"
            : "",
        animationDuration: "3s",
        backgroundColor: latest_value
          ? thresholdStatus === 2
            ? "#00aa0022"
            : thresholdStatus === 0
            ? "#ff555522"
            : "#aaaaaa22"
          : "#aaaaaa22",
      }}
    >
      <td
        className={
          "column column1 " + (sensor.extra_field_2 == 1 ? "text-muted" : "")
        }
      >
        <div className="title">
          {device && device.user_given_name
            ? device.user_given_name
            : sensor.bwb_name && sensor.bwb_name != "null"
            ? sensor.bwb_name
            : ""}
        </div>
        {latest_value &&
        latest_value.symbol &&
        SensorsTypes[latest_value.symbol] ? (
          <>{SensorsTypes[latest_value.symbol].de}</>
        ) : (
          <>
            {default_val &&
              default_val.symbol &&
              SensorsTypes[default_val.symbol] && (
                <>{SensorsTypes[default_val.symbol].de}</>
              )}
          </>
        )}
        <div className="desc">
          {sensor.bwb +
            " - " +
            sensor.sid +
            "(" +
            sensor.parameter +
            ")" +
            " port " +
            sensor.port}{" "}
        </div>
      </td>
      <td
        className={
          "column column2 " + (sensor.extra_field_2 == 1 ? "text-muted" : "")
        }
      >
        {latest_value &&
        sensor.extra_field_2 != 1 &&
        moment.unix(latest_value.UTS).isValid() ? (
          <>
            <div className="title">
              {moment.unix(latest_value.UTS).fromNow()}
            </div>
            <div className="desc">
              {moment.unix(latest_value.UTS).format("DD.MM.YYYY HH:mm")}
            </div>
          </>
        ) : (
          <>
            {sensor.last_value_uts &&
              moment.unix(sensor.last_value_uts).isValid() && (
                <>
                  <div className="title">
                    {moment.unix(sensor.last_value_uts).fromNow()}
                  </div>
                  <div className="desc">
                    {moment
                      .unix(sensor.last_value_uts)
                      .format("DD.MM.YYYY HH:mm")}
                  </div>
                </>
              )}
          </>
        )}
        {!latest_value && !sensor.last_value_uts && (
          <div className="desc">---</div>
        )}
      </td>
      <td className="column column3">
        {sensor && device && sensor.extra_field_2 != 1 && latest_value ? (
          <>
            {thresholdStatus === 2 || thresholdStatus === 1 ? (
              <div>
                <i className="fa fa-check status-ok" />{" "}
              </div>
            ) : (
              <div>
                <i className="fa fa-times status-warn" />{" "}
              </div>
            )}
          </>
        ) : (
          <div className="desc">---</div>
        )}
      </td>
      <td
        className={
          "column column4 " + (sensor.extra_field_2 == 1 ? "text-muted" : "")
        }
      >
        {latest_value && sensor.extra_field_2 != 1 ? (
          <>
            {latest_value && latest_value.old === true ? (
              <div>Daten veraltet</div>
            ) : (
              <div>
                <i className="fa fa-check status-ok" />{" "}
              </div>
            )}
          </>
        ) : (
          <>
            {sensor.last_value && <div className="desc">Daten veraltet</div>}
            {!sensor.last_value && <div className="desc">---</div>}
          </>
        )}
      </td>
      <td className="column column5">
        {(latest_value || sensor.last_value) &&
        calculatedValue &&
        calculatedValue != "null" ? (
          <>
            <div
              className={sensor.extra_field_2 == 1 ? "text-muted" : ""}
              style={{ color: color }}
              data-tip
              data-for={id}
            >
              <i className={icon} />
              {"  " + calculatedValue + " " + unit}
            </div>
            {sensor.extra_field_2 != 1 && (
              <ReactTooltip
                place="top"
                effect="float"
                type={tooltipType}
                id={id}
                getContent={() => {
                  return (
                    <>
                      <p>
                        {"Min: " + min}
                        {" - Max: " + max}
                      </p>
                      {customMessage && <p>{customMessage}</p>}
                    </>
                  );
                }}
              />
            )}
          </>
        ) : (
          <div>{"---"}</div>
        )}
      </td>
      <td className="column column6">
        {latest_value && sensor.extra_field_2 != 1 ? (
          <div>
            <i className="fa fa-check status-ok" />{" "}
          </div>
        ) : (
          <div>
            <i className="fa fa-question-circle status-warn" />{" "}
          </div>
        )}
      </td>
      <td className="column column7">
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup className="mr-2" aria-label="First group">
            <Button
              variant="light"
              // disabled={latest_value ? false : true}
              onClick={() => {
                let end_date = moment.unix(sensor.last_value_uts);
                setChartParams({
                  type: "sensor",
                  object: {
                    ...sensor,
                    start_date: Configuration.starts_at,
                    end_date: end_date,
                  },
                });
                setChartModalOpened(true);
              }}
            >
              <i className="buttons fa fa-line-chart" />
            </Button>
            <Button
              variant="light"
              onClick={() => {
                setSelectedSensor(sensor);
                setThresholdsModalOpened(true);
                setReloadSensorHandler(reload);
              }}
            >
              <i className="buttons fa fa-cog" />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </td>
    </tr>
  );
};
export default SensorItem;
