import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, withRouter } from "react-router-dom";
import "./Login.css";
import { Container, Row, Col } from "react-bootstrap";


const ImprintPage = () => {
  return (
   <><div style={{marginBottom:150,padding: "20px 100px 20px 100px" }}>
          <h2 style={{ textAlign: "left" }}>
  <strong>IMPRESSUM</strong>
          </h2>
          <hr />
          <h4 style={{ textAlign: "left" }}>
            <p style={{ fontStyle: "italic" }}>
              An wen kann ich mich bei Fragen wenden?
            </p>
            <p style={{ marginTop: 10 }}>
              Unsere Berater stehen Ihnen gerne für Ihre Fragen zur Verfügung.
              Falls Sie sich schriftlich an uns wenden wollen, lautet die
              Postanschrift
            </p>
            <p style={{ marginTop: 10 }}>Trotec GmbH</p>
            <p>Grebbener Straße 7</p>
            <p>52525 Heinsberg</p>
            <p style={{ fontStyle: "italic" }}>
              Am einfachsten ist natürlich ein Anruf:
            </p>
            <p style={{ textOverflow: "hidden",whiteSpace:'wrap' }}>
              Unter der Rufnummer +49 2452 962 0 beantworten wir Ihnen gerne Mo.
              - Do. zwischen 8.00 und 17.30 Uhr und Fr. zwischen 8.00 und 17.00
              Uhr Ihre Fragen.
            </p>
            <p style={{ marginTop: 10 }}>
              Rund um die Uhr können Sie uns per Mail erreichen:
            </p>
            <a href="mailto:info@trotec.de">info@trotec.de</a>
            <p>Oder Sie senden uns ein Telefax an +49 2452 962 200</p>
            <p> USt.-IdNr.: DE280690324 Amtsgericht Aachen - HRB 13453</p>

            <p>Telefon: +49 2452 962 0</p>
            <p>Fax: +49 2452 962 200</p>
            <p>E-Mail: info@trotec.de </p>

            <p style={{ marginTop: 40 }}>
              Für eine Streitschlichtung können Sie sich auch an die folgende
              Internetseite wenden:{" "}
              <a href="http://ec.europa.eu/consumers/odr/">
                ec.europa.eu/consumers/odr/
              </a>
            </p>
          </h4>
    </div></>
  );
};

export default withRouter(ImprintPage);
