/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import {
  ListGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { NotificationContext } from "../../Contexts/NotificationContext";
import "./NotificationModal.css";
import NotificationItem from "./NotificationItem";

function NotificationModal() {
  const { NotificationsList, dispatchNotification, Loading } = useContext(
    NotificationContext
  );
  return (
    <>
      <div className="notifications-header">
        <i className="fa fa-bell" />
        <p>Benachrichtigungen</p>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <p>Alle als gelesen markieren</p>
            </Tooltip>
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              dispatchNotification({ type: "SEEN_ALL" });
            }}
            className="del-all-btn"
          >
            <small className="fa fa-circle" />
          </a>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <p>Alle Benachrichtigungen löschen</p>
            </Tooltip>
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              dispatchNotification({ type: "REMOVE_ALL" });
            }}
            className="del-all-btn"
          >
            <small className="fa fa-times" />
          </a>
        </OverlayTrigger>
      </div>
      {Loading && (
        <div
          style={{
            marginTop: 50,
            fontSize: 32,
            textAlign: "center",
            height: 400,
          }}
        >
          <strong>
            <Spinner animation="border" />
          </strong>
        </div>
      )}
      {!Loading &&
        NotificationsList.length === 0 && ( //.filter((c) => c.is_temp === null || c.is_temp === false)
          <div style={{ textAlign: "center", height: 400 }}>
            <strong>Keine Benachrichtigungen zum Anzeigen</strong>
          </div>
        )}
      <ListGroup className="notifs-list-group">
        {NotificationsList.map((prop, key) => {
          //.filter( (c) => c.is_temp === null || c.is_temp === false)
          return <NotificationItem key={key} Notification={prop} />;
        })}
      </ListGroup>
    </>
  );
}

export default NotificationModal;
