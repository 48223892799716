import React, { useEffect, useContext } from "react";
import { Button } from "react-bootstrap";

import { UserContext } from "../../Contexts/UserContext";
import Axios from "axios";
import "./UserDialog.css";
import { api_base_url, api_headers } from "../../Configs/Configs";
import avatar from "../../assets/images/img_avatar.png";
import { NotificationContext } from "../../Contexts/NotificationContext";

export const UserDialog = ({ IsOpen, onHide, onSettingsButtonClick }) => {
  const { User, setUser } = useContext(UserContext);
  const {setUnseenCount } = useContext(
    NotificationContext
  );

  useEffect(() => {
    if (IsOpen === true) {
      document.addEventListener("mousedown", handleClick, false);
    }
  }, [IsOpen]);

  const handleClick = (event) => {
    var e = document.getElementById("user-dialog");
    if (e && !e.contains(event.target)) {
      document.removeEventListener("mousedown", handleClick, false);
      onHide();
    }
  };

  const handleLogout = () => {
    Axios.get(`${api_base_url}/login/api_logout`, {
      withCredentials: true,
    })
      .then((response) => {
        if (!response.data) {
          document.removeEventListener("mousedown", handleClick, false);
          onHide();
          setUnseenCount(0);
          setUser(null);
        }
      })
      .catch(() => {});
  };

  const handleSettingsClick = () => {
    document.removeEventListener("mousedown", handleClick, false);
    onHide();
    onSettingsButtonClick();
  };

  return (
    <div id="user-dialog" className={IsOpen == true ? "open" : "closed"}>
      <img className="avatar" src={avatar} />
      <p className="user-email">{User.email}</p>
      <div className="buttons">
        <Button className="btn-info" onClick={handleSettingsClick}>
          <i className="fa fa-cog" />
          {"  "}Einstellungen
        </Button>
        {"  "}
        <Button className="btn-primary" onClick={handleLogout}>
          <i className="fa fa-sign-out"></i>
          {"  "}Ausloggen
        </Button>
      </div>
    </div>
  );
};
