import React, { useContext, useState, useEffect } from "react";
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  Collapse,
} from "react-bootstrap";
import { DeviceTypes } from "../../Configs/DeviceConsts";
import {
  DevicesContext,
  DevicesActionsContext,
} from "../../Contexts/DevicesContext";
import { DeviceSetsContext } from "../../Contexts/DeviceSetsContext";
import DeviceSensorComponent from "./DeviceSensorComponent";
const DeviceRowComponent = ({ Device }) => {
  const {
    setInfoModalOpened,
    setEditDeviceModalOpened,
    setSelectedDevice,
    setChartParams,
    setChartModalOpened,
  } = useContext(DevicesActionsContext);
  const { MeasurandsList } = useContext(DevicesContext);
  const { SelectedDeviceSet } = useContext(DeviceSetsContext);
  const [is_opened, setIs_opened] = useState(true);

  const [childs, setChilds] = useState([]);

  const [device_type, setDevice_type] = useState(0);
  useEffect(() => {
    if (Device.device_type_id == 1) {
      setChilds(
        Device.mtbs && Array.isArray(Device.mtbs)
          ? Device.mtbs.filter(
              (c) => c.associated_id && c.associated_id != "null"
            )
          : []
      );
      setDevice_type(1);
    } else {
      if (Device.device_type_id == 2) {
        setChilds(
          Device.bwbs && Array.isArray(Device.bwbs)
            ? Device.bwbs.filter(
                (c) => c.associated_id && c.associated_id != "null"
              )
            : []
        );
        setDevice_type(2);
      } else {
        setChilds(MeasurandsList.filter((c) => c.bwb == Device.deviceId));
        setDevice_type(3);
      }
    }
  }, []);

  return (
    <Card className="device-card">
      <Card.Header
        style={{
          cursor: childs.length > 0 ? "pointer" : "default",
        }}
        onClick={(e) => {
          setIs_opened(!is_opened);
        }}
      >
        <div className="device-row-content">
          <div className="left-part">
            {device_type != 1 && SelectedDeviceSet && (
              <div className="left-indent">
                <div></div>
              </div>
            )}
            {childs.length > 0 && SelectedDeviceSet && (
              <div
                style={{ display: "block", marginRight: 15 }}
                className="btn-active"
                aria-controls={"deviceStat-" + Device.associated_id}
                aria-expanded={is_opened}
              >
                {is_opened ? (
                  <i className="fa fa-chevron-circle-up" />
                ) : (
                  <i className="fa fa-chevron-circle-down" />
                )}
              </div>
            )}
            {!Device.deviceId || !Device.associated_id ? (
              "Keine Daten!"
            ) : (
              <div>
                <strong>{Device.name}</strong>
                <p>{Device.deviceId}</p>
              </div>
            )}
          </div>
          <div className="right-part">
            <div className="device-type">
              {DeviceTypes && DeviceTypes[Device.device_type_id] ? (
                <p>{DeviceTypes[Device.device_type_id].short}</p>
              ) : (
                "---"
              )}
              {DeviceTypes && DeviceTypes[Device.device_type_id] ? (
                <p>{DeviceTypes[Device.device_type_id].long}</p>
              ) : (
                "---"
              )}
            </div>
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ButtonGroup className="mr-2" aria-label="First group">
                <Button
                  disabled={!Device.deviceId || !Device.associated_id}
                  variant="dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedDevice(Device);
                    setInfoModalOpened(true);
                  }}
                >
                  <i className="buttons fa fa-info-circle" />
                </Button>
                <Button
                  disabled={!Device.deviceId || !Device.associated_id}
                  variant="dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedDevice(Device);
                    setChartModalOpened(true);
                    setChartParams({ type: "device", object: Device });
                  }}
                >
                  <i className="buttons  fa fa-line-chart" />
                </Button>{" "}
                <Button
                  disabled={!Device.deviceId || !Device.associated_id}
                  variant="dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedDevice(Device);
                    setEditDeviceModalOpened(true);
                  }}
                >
                  <i className="buttons fa fa-pencil" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
        </div>
      </Card.Header>
      {device_type != 3 && childs.length > 0 && SelectedDeviceSet &&(
        <Collapse className="card-body" in={is_opened}>
          <div id={"deviceStat-" + Device.associated_id}>
            {childs.map((child) => {
              return (
                <DeviceRowComponent key={child.associated_id} Device={child} />
              );
            })}
          </div>
        </Collapse>
      )}
      {device_type == 3 && childs.length > 0 &&SelectedDeviceSet && (
        <Collapse className="card-body" in={is_opened}>
          <div id={"deviceStat-" + Device.associated_id}>
            {childs.map((child) => {
              return (
                <DeviceSensorComponent
                  key={child.associated_id}
                  sensor={child}
                />
              );
            })}
          </div>
        </Collapse>
      )}
    </Card>
  );
};

export default DeviceRowComponent;
