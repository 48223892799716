import React, { useState, useEffect, useContext, useRef } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import axios from "axios";
import * as moment from "moment";
import "moment/locale/de";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-zoom";
import "chartjs-plugin-annotation";
import "hammerjs";
import { api_base_url } from "../../Configs/Configs";
import { UserContext } from "../../Contexts/UserContext";
import {
  ButtonGroup,
  ToggleButton,
  Table,
  Dropdown,
  SplitButton,
  Overlay,
  Tooltip,
  Button,
  Form,
  Modal,
  Spinner,
  Tabs,
} from "react-bootstrap";
import { helpers } from "chart.js";
import de from "date-fns/locale/de";
import { NotificationContext } from "../../Contexts/NotificationContext";
import { Tab } from "bootstrap";
import { getRandomNumber } from "../../shared/numberTool";
registerLocale("de", de);
const ThresholdsList = [];

function ChartsModal({ Params, ChartModalOpened, handleClose }) {
  const { User, checkUserSession, SensorsDefaultsList } = useContext(
    UserContext
  );
  const { ShowNotification } = useContext(NotificationContext);
  const [DateDialogOpened, setDateDialogOpened] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [HaveData, setHaveData] = useState(true);
  const [ChartData, setChartData] = useState({});
  const [SensorThresholds, setSensorThresholds] = useState(null);
  const [AnnotationsList, setAnnotationsList] = useState([]);
  const [screenNo, setScreenNo] = useState(0);

  const [invalid_dates, setInvalid_dates] = useState(false);
  const [invalidPeriod, setInvalidPeriod] = useState(false);
  const dt_ref = useRef(null);

  const chartRefrence = useRef(null);

  const [modalDate, setModalDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minStartDate, setMinStartDate] = useState(Params.object.start_date&&moment(Params.object.start_date).isValid()?moment(Params.object.start_date).toDate():null);
  const [maxEndDate, setMaxEndDate] = useState(Params.object.end_date&&moment(Params.object.end_date).isValid()?moment(Params.object.end_date).toDate():null);
  const [yAxesTicks, setYAxesTicks] = useState(null);

  const [interval, setInterval] = useState("Standard");
  const [intervalDisplay, setIntervalDisplay] = useState("Standard");

  useEffect(() => {
    ThresholdsList.splice(0, ThresholdsList.length);
    setIsLoading(true);
    getChartVals();
  }, [Params]);

  useEffect(() => {
    //if (interval !== "All")
    loadChartValues(startDate, endDate, 0);
  }, [interval]);

  const getModalTitle = () => {
    if (Params.type === "group") {
      return " des Projekts (" + Params.object.title + ")";
    } else if (Params.type === "device") {
      return " des Geräts (" + Params.object.name + ")";
    } else if (Params.type === "sensor") {
      return ` der Sensor (${Params.object.bwb} - ${Params.object.sid}(${Params.object.parameter}) Port${Params.object.port})`;
    }
  };

  const getChartVals = () => {
    const fetchData = async () => {
      if (!checkUserSession()) return;
      ThresholdsList.splice(0, ThresholdsList.length);
      if (Params.type === "group") {
        // setMinStartDate(moment(Params.object.starts_at).toDate());
        if (Params.object.customer_field_1 == "ARCHIV") {
          // setMaxEndDate(moment(Params.object.ends_at).toDate());
        }
        await axios({
          method: "get",
          withCredentials: true,
          url: `${api_base_url}/apiMeasurementDisplay/preview_by_configuration/${Params.object.id}`,
        }).then((response) => {
          if (
            response.data &&
            response.status === 200 &&
            Array.isArray(response.data)
          ) {
            response.data.forEach((element) => {
              ThresholdsList.push(element);
            }); // ThresholdsList = response.data;
          }
        });
      } else if (Params.type === "device") {
        await axios({
          method: "get",
          withCredentials: true,
          url: `${api_base_url}/apiMeasurementDisplay/preview_by_device/${Params.object.associated_id}`,
        });
      } else if (Params.type === "sensor") {
        // if (Params.object.start_date&&moment(Params.object.start_date).isValid()) {
        //   setMinStartDate(moment(Params.object.start_date).toDate());
        // }
        // if (Params.object.last_value_uts&&moment.unix(Params.object.last_value_uts).isValid()) {
        //   setMaxEndDate(moment.unix(Params.object.last_value_uts).toDate());
        // }
        await axios({
          method: "get",
          withCredentials: true,
          url: `${api_base_url}/apiMeasurementDisplay/single_mesurand_preview/${User.id}/${Params.object.id}/${Params.object.measurand_display_id}`,
        });
        //ThresholdsList = [Params.object];
        ThresholdsList.push(Params.object);
      }
      if (Params.object.customer_field_1 == "ARCHIV") {
        loadChartValues(
          moment(Params.object.starts_at).toDate(),
          moment(Params.object.ends_at).toDate(),
          1
        );
      } else loadChartValues();
    };
    fetchData();
  };

  const loadChartValues = (
    start = null,
    end = null,
    retry = 1,
    intervalParam = interval
  ) => {
    const fetchData = async () => {
      if (!start && !end) {
        start = moment(Date.now()).subtract(3, "days").toDate();
        end = moment(Date.now()).toDate();
      }
      if(minStartDate&&moment(minStartDate).isValid()&&moment(minStartDate).isAfter(moment(start))){
        start=moment(minStartDate).toDate();
      }
      if(maxEndDate&&moment(maxEndDate).isValid()&&moment(maxEndDate).isBefore(moment(end))){
        end=moment(maxEndDate).toDate();
      }
      // if(Params.object.starts_at&&moment(start).isBefore(moment(Params.object.starts_at))){
      //   start = moment(Params.object.starts_at).toDate();
      // }
      setInvalidPeriod(false);
      var sensorsVals = [];
      setIsLoading(true);
      setChartData({});
      if (chartRefrence.current && chartRefrence.current.chartInstance) {
        await chartRefrence.current.chartInstance.resetZoom();
      }

      let datesFilter =
        start && end
          ? "/" + moment(start).unix() + "/" + moment(end).unix()
          : "";
      await axios({
        method: "get",
        withCredentials: true,
        url:
          intervalParam == "Standard"
            ? `${api_base_url}/apiMeasurementDisplay/time_related_user_preview/${User.id}${datesFilter}`
            : `${api_base_url}/apiMeasurementDisplay/time_related_user_preview_hourly_daily_month_all/${
                User.id
              }${datesFilter}/${intervalParam.toLowerCase()}`,
      })
        .then(async (response) => {
          if (response.data && response.status === 200) {
            for (let index = 0; index < response.data.units.length; index++) {
              let sensor = response.data.details[index];

              let s_vals = {
                unit: response.data.units[index],
                symbol: response.data.symbols[index],
                details: sensor,
                vals: [],
              };

              sensorsVals.push(s_vals);
            }
            for (
              let eIndex = 0;
              eIndex < response.data.values.length;
              eIndex++
            ) {
              let element = response.data.values[eIndex];
              for (let index = 0; index < element.length; index++) {
                var v;
                if (index == 0) {
                  v = moment.unix(element[0] / 1000).toDate();
                } else v = parseFloat(element[index]);
                sensorsVals[index].vals.push(v);
              }
            }

            var data = { labels: sensorsVals[0].vals, datasets: [] };
            var annotations = [];
            for (let index = 1; index < sensorsVals.length; index++) {
              const element = sensorsVals[index];
              //labels.push(element.details.bwb_name);
              let randomColor1 =
                Params.type === "sensor" ? 33 : Math.floor(getRandomNumber(index) * 180);
              let randomColor2 =
                Params.type === "sensor"
                  ? 200
                  : Math.floor(getRandomNumber(index*2+1) * 180);
              let randomColor3 =
                Params.type === "sensor" ? 33 : Math.floor(getRandomNumber(index*3+3) * 180);
              let randomColor = `rgb(${randomColor1},${randomColor2},${randomColor3})`;
              var dataset = {
                data_set_id: index,
                label: `${element.details.bwb_name} (${element.details.sid} , ${element.details.param})`,
                fill: false,
                lineTension: 0.00001,
                backgroundColor: "rgb(255,255,255)",
                borderColor: randomColor, //line
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: `rgb(${randomColor1},${randomColor2},${randomColor3})`,
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: `rgb(${randomColor1},${randomColor2},${randomColor3})`,
                pointHoverBorderColor: "rgb(220,220,220)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: element.vals,
                sensor: element.details,
              };

              let hiddenDataset = false;
              if (Params.type === "group") {
                try {
                  const sensor = dataset.sensor;

                  let meas = ThresholdsList.find(
                    (c) =>
                      c.dtb == sensor.dtb &&
                      c.bwb == sensor.bwb &&
                      sensor.sid &&
                      sensor.sid.includes(c.sid) &&
                      sensor.param == c.parameter
                  );
                  if (meas && meas.display_in_diagram) {
                    if (
                      meas.display_in_diagram == 2 ||
                      meas.display_in_diagram == 3
                    )
                      hiddenDataset = true;
                  }
                } catch (error) {}
              }

              if (!hiddenDataset) data.datasets.push(dataset);

              var threshold = null;
              if (Params.type === "sensor") {
                threshold =
                  ThresholdsList && ThresholdsList.length > 0
                    ? ThresholdsList[0]
                    : null;
              }
              try {
                if (threshold) {
                  if (threshold.threshold_min && threshold.threshold_max) {
                  } else {
                    // let dv =SensorsDefaultsList.find(
                    //   (c) =>
                    //     c.sid === threshold.sid &&
                    //     c.parameter === threshold.parameter
                    // );
                    // if (dv && dv.threshold_max && dv.threshold_min) {
                    //   threshold["threshold_min"] = dv.threshold_min;
                    //   threshold["threshold_max"] = dv.threshold_max;
                    // }
                    //get default thresholds
                  }
                  if (threshold.threshold_min && threshold.threshold_max) {
                    threshold["min_type"] = "error";
                    if (threshold.min_notif_text) {
                      let arr = threshold.min_notif_text.split("@@@");
                      if (arr.length > 1) threshold["min_type"] = arr[1];
                    }
                    threshold["max_type"] = "error";
                    if (threshold.max_notif_text) {
                      let arr2 = threshold.max_notif_text.split("@@@");
                      if (arr2.length > 1) threshold["max_type"] = arr2[1];
                    }
                    annotations.push({
                      type: "box",
                      borderDash: [5, 5],
                      yScaleID: "y-axis-0",
                      yMax: threshold.threshold_max,
                      yMin: threshold.threshold_min,
                      backgroundColor: `rgba(${randomColor1},${randomColor2},${randomColor3},0.1)`,

                      borderColor: randomColor,
                      borderWidth: 0.3,
                    });
                    annotations.push({
                      type: "box",
                      yScaleID: "y-axis-0",
                      yMax: threshold.threshold_min,
                      backgroundColor:
                        threshold["min_type"] === "error"
                          ? `rgba(255,0,0,0.1)`
                          : `rgba(${randomColor1},${randomColor2},${randomColor3},0.1)`,
                    });
                    annotations.push({
                      type: "box",
                      yScaleID: "y-axis-0",
                      yMin: threshold.threshold_max,
                      backgroundColor:
                        threshold["max_type"] === "error"
                          ? `rgba(255,0,0,0.1)`
                          : `rgba(${randomColor1},${randomColor2},${randomColor3},0.1)`,
                    });
                  }
                }
              } catch (error) {
              }
            }
            let chartMinVal = 0;
            let chartMaxVal = 0;
            data.datasets.forEach(async (element) => {
              element.data.forEach((val) => {
                if (Number(val) < chartMinVal) chartMinVal = Number(val);
                if (Number(val) > chartMaxVal) chartMaxVal = Number(val);
              });
            });
            // if (
            //   Params.type === "sensor" &&
            //   ThresholdsList &&
            //   ThresholdsList.length > 0
            // ) {
            //   if (ThresholdsList[0].threshold_min < chartMinVal)
            //     chartMinVal = ThresholdsList[0].threshold_min;
            //   if (ThresholdsList[0].threshold_max > chartMaxVal)
            //     chartMaxVal = ThresholdsList[0].threshold_max;
            // }

            let yAxisMinVal =
             chartMinVal -  ( Math.abs(chartMaxVal - chartMinVal)) != 0
                ? chartMinVal - (Math.abs(chartMaxVal - chartMinVal) / 5)
                : -1;
            let yAxisMaxVal =
            chartMaxVal +( Math.abs (chartMaxVal - chartMinVal) )!= 0
                ? chartMaxVal + (Math.abs(chartMaxVal - chartMinVal) / 5)
                : 1;
            setYAxesTicks({
              min: yAxisMinVal,
              max: yAxisMaxVal,
              stepSize:
                chartMaxVal - chartMinVal == 0
                  ? 10
                  : Math.abs(chartMaxVal - chartMinVal) / 20,
            });
            setChartData(data);
            setHaveData(true);
            setIsLoading(false);
            setSensorThresholds(threshold);
            setAnnotationsList(annotations);
            setStartDate(start);
            setEndDate(end);
          } else {
            if (retry == 1) {
              let start = moment(Date.now()).subtract(30, "days").toDate();
              let end = moment(Date.now()).toDate();
              loadChartValues(start, end, 2);
              return;
            } else if (retry == 2) {
              let start = moment(Date.now()).subtract(365, "days").toDate();
              let end = moment(Date.now()).toDate();
              loadChartValues(start, end, 0);
              return;
            }
            setHaveData(false);
            setIsLoading(false);
          }
        })
        .catch(function (response) {
          //handle error
          setIsLoading(false);
        });
    };
    if (moment(endDate).isBefore(moment(startDate))) return;
    fetchData();
  };

  const handleCopyToClipboard = () => {
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (!isChrome) {
      ShowNotification({
        type: "error",
        title:
          "Diese Funktion wird zurzeit nur von dem Browser Google Chrome unterstützt",
      });
      return;
    }
    try {
      let _ctx = chartRefrence.current.chartInstance.ctx;

      var newCanvas = _ctx.canvas.cloneNode(true);
      var ctx = newCanvas.getContext("2d");
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
      ctx.drawImage(chartRefrence.current.chartInstance.canvas, 0, 0);

      ctx.canvas.toBlob((blob) => {
        // eslint-disable-next-line no-undef
        const data = [new ClipboardItem({ [blob.type]: blob })];
        navigator.clipboard.write(data);
      });
    } catch (error) {}
  };
  const handleExportImage = () => {
    try {
      chartRefrence.current.chartInstance.canvas.toBlob((blob) => {
        // eslint-disable-next-line no-undef
        const data = [new ClipboardItem({ [blob.type]: blob })];
        var encodedUri = encodeURI(data);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `diagram_${moment(Date.now()).format("DD.MM.YY_HHmmss")}.jpg`
        );
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {}
  };

  const handleExportCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";

    let colsCount = ChartData.datasets.length;
    let rowsCount = ChartData.datasets[0].data.length;
    csvContent += "Timestamp,";
    for (let index = 0; index < colsCount; index++) {
      csvContent += '"' + ChartData.datasets[index].label + '",';
    }
    csvContent += "\n";

    for (let rIndex = 0; rIndex < rowsCount; rIndex++) {
      csvContent +=
        moment(ChartData.labels[rIndex]).format("DD.MM.YYYY HH:mm") + ",";
      for (let cIndex = 0; cIndex < colsCount; cIndex++) {
        csvContent += ChartData.datasets[cIndex].data[rIndex] + ",";
      }
      csvContent += "\n";
    }

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `diagram_${moment(Date.now()).format("DD.MM.YY_HHmmss")}.csv`
    );
    document.body.appendChild(link);

    link.click();
  };

  const getdataTableHeader = () => {
    var r = [];
    for (let index = 0; index < ChartData.datasets.length; index++) {
      r.push(<th>{ChartData.datasets[index].label}</th>);
    }
    return r;
  };
  const getdataTable = () => {
    var r = [];
    for (let index = 0; index < ChartData.labels.length; index++) {
      let row = [];
      row.push(moment(ChartData.labels[index]).format("DD.MM.YYYY HH:mm"));
      ChartData.datasets.forEach((dataset) => {
        row.push(dataset.data[index]);
      });

      r.push(row);
    }
    return r;
  };

  useEffect(() => {
    // setInvalidPeriod(false);
    setInvalid_dates(moment(endDate).isBefore(moment(startDate)));
  }, [startDate, endDate]);

  const zoom = (zoomin = true) => {
    if (chartRefrence.current && chartRefrence.current.chartInstance) {
      helpers.each(
        chartRefrence.current.chartInstance.scales,
        function (scale, id) {
          if (id == "y-axis-0") {
            var tickOptions = scale.options.ticks;
            if (tickOptions) {
              let step =
                Math.abs(scale.options.ticks.max - scale.options.ticks.min) / 5;
              let min = scale.options.ticks.min + (zoomin ? step : -step);
              let max = scale.options.ticks.max + (zoomin ? -step : step);
              scale.options.ticks.min = min;

              scale.options.ticks.max = max;

              scale.options.ticks.stepSize = Math.abs(max - min) / 20;
            }

            scale.options = helpers.configMerge(
              scale.options,
              scale.originalOptions
            );
          }
        }
      );

      helpers.each(
        chartRefrence.current.chartInstance.data.datasets,
        function (dataset, id) {
          dataset._meta = null;
        }
      );

      chartRefrence.current.chartInstance.update();
    }
  };

  return (
    <>
      <Modal
        className="chart-modal"
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        show={ChartModalOpened}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="strong">
            <i className="fa fa-line-chart" />
            Vorschau {getModalTitle()}
            {Params.object.customer_field_1 == "ARCHIV" && (
              <small
                style={{ marginLeft: 10, fontStyle: "italic", fontStretch: "" }}
              >
                Archiviert
              </small>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="chart-modal-header">
              <div className="left">
                <p style={{ marginTop: 10 }}>Zeitraum:</p>
                <div className="date-range" style={{ marginTop: 10 }}>
                  <DatePicker
                    minDate={minStartDate}
                    maxDate={endDate}
                    locale={de}
                    timeCaption="Uhrzeit"
                    placeholderText=""
                    showTimeSelect
                    id="start-date-input"
                    dateFormat="dd.MM.yyyy HH:mm"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control txt-input"
                    style={{ width: "100%" }}
                  />
                </div>
                <p style={{ marginLeft: 8 }}>-</p>
                <div
                  ref={dt_ref}
                  className="date-range"
                  style={{ marginTop: 10 }}
                >
                  <DatePicker
                    minDate={startDate}
                    maxDate={maxEndDate}
                    locale={de}
                    showTimeSelect
                    placeholderText=""
                    timeCaption="Uhrzeit"
                    id="end-date-input"
                    dateFormat="dd.MM.yyyy HH:mm"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control txt-input"
                    style={{ width: "100%" }}
                  />
                  <Overlay
                    show={invalid_dates || invalidPeriod}
                    //target={target}
                    placement="bottom"
                    target={dt_ref.current}
                    containerPadding={20}
                  >
                    <Tooltip id="popover-contained">
                      {invalid_dates && (
                        <p>Startdatum muss größer als der Enddatum sein.</p>
                      )}
                      {/* {invalidPeriod && (
                        <p>
                          Ein Intervall von 30 Sekunden ist nur gültig bei einem
                          eingestellten Zeitraum von einem Tag
                        </p>
                      )} */}
                    </Tooltip>
                  </Overlay>
                </div>
                <div className="date-range">
                  <SplitButton
                    disabled={IsLoading}
                    title="Suche"
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      loadChartValues(startDate, endDate, 0);
                    }}
                  >
                    {Params.object.customer_field_1 != "ARCHIV" && (
                      <>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault();
                            setStartDate(moment().startOf("day").toDate());
                            setEndDate(moment(Date.now()).toDate());
                            loadChartValues(
                              moment().startOf("day").toDate(),
                              moment(Date.now()).toDate(),
                              0
                            );
                          }}
                          as="button"
                        >
                          Heute
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault();
                            let start = moment(Date.now())
                              .subtract(24, "hours")
                              .toDate();
                            setStartDate(start);
                            setEndDate(moment(Date.now()).toDate());
                            loadChartValues(
                              start,
                              moment(Date.now()).toDate(),
                              0
                            );
                          }}
                          as="button"
                        >
                          24 Stunden zurück
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault();
                            let start = moment(Date.now())
                              .subtract(3, "days")
                              .toDate();
                            setStartDate(start);
                            setEndDate(moment(Date.now()).toDate());
                            loadChartValues(
                              start,
                              moment(Date.now()).toDate(),
                              0
                            );
                          }}
                          as="button"
                        >
                          3 Tage zurück
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault();
                            let start = moment().subtract(1, "weeks").toDate();
                            setStartDate(start);
                            setEndDate(moment(Date.now()).toDate());
                            loadChartValues(
                              start,
                              moment(Date.now()).toDate(),
                              0
                            );
                          }}
                          as="button"
                        >
                          1 Woche zurück
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault();
                            let start = moment().subtract(1, "months").toDate();
                            setStartDate(start);
                            setEndDate(moment(Date.now()).toDate());
                            loadChartValues(
                              start,
                              moment(Date.now()).toDate(),
                              0
                            );
                          }}
                          as="button"
                        >
                          1 Monat zurück
                        </Dropdown.Item>
                      </>
                    )}
                  </SplitButton>
                </div>
                <div className="date-range">
                  <Dropdown>
                    <Dropdown.Toggle
                      disabled={IsLoading || !HaveData}
                      variant="primary"
                      id="dropdown-basic"
                    >
                      {"Intervall: " + intervalDisplay}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setIntervalDisplay("Standard");
                          setInterval("Standard");
                        }}
                      >
                        Standard
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setIntervalDisplay("1 Stunde");
                          setInterval("hourly");
                        }}
                      >
                        1 Stunde
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setIntervalDisplay("1 Tag");
                          setInterval("Daily");
                        }}
                      >
                        1 Tag
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          // setModalDate(moment(startDate).toDate());
                          // setDateDialogOpened(true);
                          setIntervalDisplay("Alle Messpunkte");
                          setInterval("All");
                        }}
                      >
                        Alle Messpunkte
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* {HaveData && screenNo === 1 && (
              <div className="date-range">
                <Button onClick={handleExportCSV}>Export nach CSV</Button>
              </div>
            )}
            {HaveData && screenNo === 0 && (
              <div className="date-range">
                <Button onClick={handleCopyToClipboard}>
                  In die Zwischenablage kopieren
                </Button>
              </div>
            )} */}
              </div>{" "}
              <div className="left">
                {/* <ButtonGroup toggle>
                  <ToggleButton
                    type="radio"
                    variant="primary"
                    name="radio"
                    checked={screenNo === 0}
                    onChange={(e) => {
                      if (!IsLoading) setScreenNo(0);
                    }}
                  >
                    Diagramm
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    variant="primary"
                    name="radio"
                    checked={screenNo === 1}
                    onChange={(e) => {
                      if (!IsLoading) setScreenNo(1);
                    }}
                  >
                    Datentabelle
                  </ToggleButton>
                </ButtonGroup> */}
                <Dropdown style={{ marginLeft: 10 }} id="dots-dropdown">
                  <Dropdown.Toggle disabled={IsLoading} variant="primary">
                    <i className="fa fa-ellipsis-h" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={!HaveData}
                      onClick={handleCopyToClipboard}
                    >
                      In die Zwischenablage kopieren
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={!HaveData}
                      onClick={handleExportCSV}
                    >
                      Export nach CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* <hr/> */}
            {SensorThresholds &&
            SensorThresholds.threshold_min &&
            SensorThresholds.threshold_max ? (
              <div style={{ paddingLeft: 50, marginTop: 10, marginBottom: 15 }}>
                <strong>{"Min: "}</strong>
                {SensorThresholds.threshold_min + " " + SensorThresholds.unit}
                <strong>{" - Max: "}</strong>
                {SensorThresholds.threshold_max + " " + SensorThresholds.unit}
              </div>
            ) : null}
            {IsLoading ? (
              <>
                <div style={{ textAlign: "center", margin: 30 }}>
                  <Spinner animation="border" />
                  {interval == "All" && (
                    <>
                      <p
                        style={{
                          fontWeight: "bold",
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                      >
                        Haben Sie einen Augenblick Geduld.
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        Dieser Vorgang kann einige Minuten in Anspruch nehmen!
                      </p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                {!HaveData ? (
                  <div style={{ textAlign: "center", fontSize: 24 }}>
                    Keine anzuzeigenden Werte!
                  </div>
                ) : (
                  <>
                    <Tabs defaultActiveKey="diagram" transition={false}>
                      <Tab eventKey="diagram" title="Diagramm">
                        {" "}
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {/* <Form.Control
                        type="range"
                        orient="vertical"
                        id="zoom-slider"
                      /> */}
                            <Button
                              variant="light"
                              className="zoom-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                zoom();
                              }}
                            >
                              <i className="fa fa-plus" />
                            </Button>
                            <Button
                              variant="light"
                              className="zoom-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                zoom(false);
                              }}
                            >
                              <i className="fa fa-minus" />
                            </Button>
                          </div>
                          <Line
                            ref={chartRefrence}
                            data={ChartData}
                            options={{
                              chartArea: {
                                backgroundColor: "rgb(255,255,255)",
                              },
                              bezierCurve: false,
                              responsive: true,
                              scales: {
                                xAxes: [
                                  {
                                    type: "time",
                                    bounds: {},
                                    time: {
                                      displayFormats: {
                                        millisecond: "DD.MM HH:mm",
                                        second: "DD.MM HH:mm",
                                        hour: "DD.MM HH:mm",
                                        minute: "DD.MM HH:mm",
                                        day: "DD.MM",
                                        month: "MM.YYYY",
                                      },
                                      isoWeekday: true,
                                      //round: "day",
                                      tooltipFormat: "DD.MM.YYYY HH:mm",
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: "Datum",
                                    },
                                    ticks: {
                                      autoSkip: false,
                                      maxRotation: 60,
                                      minRotation: 10,
                                    },
                                  },
                                ],
                                yAxes: [
                                  {
                                    ticks: yAxesTicks,
                                  },
                                ],
                              },
                              plugins: {
                                zoom: {
                                  pan: {
                                    enabled: true,
                                    mode: "xy",
                                    speed: 5,
                                    //threshold: 10,
                                  },
                                  zoom: {
                                    enabled: true,
                                    mode: "x",
                                    speed: 0.2,
                                    threshold: 10,
                                    sensitivity: 3,
                                  },
                                },
                              },
                              annotation: {
                                annotations: AnnotationsList,
                              },
                            }}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="table" title="Datentabelle">
                        {" "}
                        <div>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th style={{ width: 170 }}></th>
                                {getdataTableHeader()}
                              </tr>
                            </thead>
                            <tbody>
                              {getdataTable().map((row, key) => {
                                return (
                                  <tr key={key}>
                                    {row.map((rowData, key) => {
                                      return (
                                        <td key={key}>
                                          {SensorThresholds &&
                                          Number(rowData) <
                                            SensorThresholds.threshold_min ? (
                                            <p
                                              style={{
                                                color:
                                                  SensorThresholds.min_type ===
                                                  "error"
                                                    ? "#f00"
                                                    : "#383",
                                              }}
                                            >
                                              {rowData}
                                            </p>
                                          ) : (
                                            <>
                                              {SensorThresholds &&
                                              Number(rowData) >
                                                SensorThresholds.threshold_max ? (
                                                <p
                                                  style={{
                                                    color:
                                                      SensorThresholds.max_type ===
                                                      "error"
                                                        ? "#f00"
                                                        : "#383",
                                                  }}
                                                >
                                                  {rowData}
                                                </p>
                                              ) : (
                                                <>{rowData}</>
                                              )}
                                            </>
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          {/* <div style={{ width: "180px", border: "1px solid #999" }}>
                <div
                  style={{
                    borderBottom: "1px solid #999",
                    height: 50,
                    padding: 5,
                  }}
                >
                  <strong>Datum</strong>
                </div>
                {ChartData.labels.map((label) => {
                  return (
                    <div style={{ padding: 5 }} key={label}>
                      {label}
                    </div>
                  );
                })}
              </div>

              {ChartData.datasets.map((dataset) => {
                return (
                  <div style={{ border: "1px solid #999" }}>
                    <div
                      style={{
                        borderBottom: "1px solid #999",
                        height: 50,
                        padding: 5,
                      }}
                    >
                      <strong>{dataset.label}</strong>
                    </div>
                    {dataset.data.map((dataT) => {
                      return <div style={{ padding: 5 }}>{dataT}</div>;
                    })}
                  </div>
                );
              })} */}
                        </div>
                      </Tab>
                      <ButtonGroup toggle>
                        <ToggleButton
                          type="radio"
                          variant="primary"
                          name="radio"
                          checked={screenNo === 0}
                          onChange={(e) => {
                            if (!IsLoading) setScreenNo(0);
                          }}
                        >
                          Diagramm
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          variant="primary"
                          name="radio"
                          checked={screenNo === 1}
                          onChange={(e) => {
                            if (!IsLoading) setScreenNo(1);
                          }}
                        >
                          Datentabelle
                        </ToggleButton>
                      </ButtonGroup>
                    </Tabs>
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="lg"
        show={DateDialogOpened}
        onHide={() => setDateDialogOpened(false)}
        animation={false}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <p>
            Ein Intervall von 30 Sekunden ist nur gültig bei einem eingestellten
            Zeitraum von einem Tag.
          </p>
          <p>Bitte wählen Sie einen Tag aus.</p>
          <DatePicker
            locale={de}
            placeholderText=""
            id="modal-date-input"
            dateFormat="dd.MM.yyyy"
            selected={modalDate}
            onChange={(date) => setModalDate(date)}
            className="form-control txt-input"
            style={{ width: "90%" }}
          />
          <Modal.Footer>
            <Button
              style={{ width: 200 }}
              onClick={(e) => {
                e.preventDefault();
                setInterval("All");
                setIntervalDisplay("30 Sek");
                let endDate = moment(modalDate).toDate();
                endDate.setHours(23);
                endDate.setMinutes(59);
                endDate.setSeconds(59);
                setStartDate(moment(modalDate).toDate());
                setEndDate(moment(endDate).toDate());
                loadChartValues(
                  moment(modalDate).toDate(),
                  moment(endDate).toDate(),
                  0,
                  "All"
                );
                setDateDialogOpened(false);
              }}
            >
              Suche
            </Button>
            <Button
              onClick={(e) => {
                setDateDialogOpened(false);
              }}
              variant="light"
            >
              Abbrechen
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ChartsModal;
