import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ButtonGroup,
  ToggleButton,
  Spinner,
  Modal,
} from "react-bootstrap";
import { api_base_url, api_headers } from "../../Configs/Configs";
import { NotificationContext } from "../../Contexts/NotificationContext";
import { UserContext } from "../../Contexts/UserContext";
import SensorDefaultValue from "./SensorDefaultValue";

const UserSettingsModal = ({ handleClose }) => {
  const {
    User,
    checkUserSession,
    getUserInfo,
    SensorsDefaultsList,
    EnabledEmailAlerts,
    AlertsEmail,
    setAlertsEmail,
    AppVersion,
  } = useContext(UserContext);
  const { ShowNotification } = useContext(NotificationContext);
  const [screenNo, setScreenNo] = useState(0);
  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingPassword, setIsSavingPassword] = useState(false);

  const [Name, setName] = useState(User.username);
  const [Email, setEmail] = useState(User.email);
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [enableEmailAlerts, setEnableEmailAlerts] = useState(
    EnabledEmailAlerts
  );
  const [tempAlertsEmail, setTempAlertsEmail] = useState(
    AlertsEmail ? AlertsEmail : User.email
  );
  const [ConfirmNewPassword, setConfirmNewPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const handleChangeUsername = () => {
    const postData = async () => {
      setIsSaving(true);
      var formData = new FormData();
      formData.append("username", Name);

      await Axios({
        method: "post",
        data: formData,
        withCredentials: true,
        url: `${api_base_url}/userSetting/change_username`,
      });
      if(enableEmailAlerts!=EnabledEmailAlerts){
      await Axios({
        method: "post",
        withCredentials: true,
        url: `${api_base_url}/userSetting/${
          enableEmailAlerts ? "enable_alert_emails" : "disable_alert_emails"
        }`,
      });

    }
      if (enableEmailAlerts) {
            ShowNotification({
              type: "success",
              title: "Benachrichtigungen per E-Mail senden wurde aktiviert",
            });
        const qs = require("querystring");
        var bodyData = qs.stringify({ alert_email: tempAlertsEmail });
        await Axios({
          method: "post",
          data: bodyData,
          withCredentials: true,
          url: `${api_base_url}/userSetting/change_alert_email`,
        }).then((response) => {
          if (response.data) {
            setAlertsEmail(tempAlertsEmail);
            ShowNotification({
              type: "success",
              title: "Benachrichtigungsadresse wurde geändert in "+tempAlertsEmail,
            });
          }
        });
      }
      else {
        ShowNotification({
          type: "success",
          title: "Benachrichtigungen per E-Mail senden wurde deaktiviert",
        });
      }
      await getUserInfo();
      setIsSaving(false);
    };
    postData();
  };
  const passwordRegex = RegExp(`^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$`);
  useEffect(() => {
    setValidationMessage("");
    var form = document.getElementById("password-form");
    var p = document.getElementById("OldPasswordInput");
    var p2 = document.getElementById("NewPasswordInput");
    var p3 = document.getElementById("ConfirmNewPasswordInput");
    if (!p || !p2 || !p3) return;
    p.setCustomValidity("");
    p2.setCustomValidity("");
    p3.setCustomValidity("");

    if (!OldPassword || OldPassword.length === 0) {
      p.setCustomValidity(
        "Geben Sie eine Kombination aus mindestens sechs Ziffern, mindestens einen Kleinbuchstaben, einen Großbuchstaben und  mindestens ein Satzzeichen ein."
      );
    }
    if (!NewPassword || !passwordRegex.test(NewPassword)) {
      p2.setCustomValidity(
        "Geben Sie eine Kombination aus mindestens sechs Ziffern, mindestens einen Kleinbuchstaben, einen Großbuchstaben und  mindestens ein Satzzeichen ein."
      );
    }
    if (NewPassword !== ConfirmNewPassword) {
      p3.setCustomValidity(
        "Ihre Passwörter stimmen nicht überein. Bitte versuchen Sie es noch einmal."
      );
    }
    form.checkValidity();
  }, [OldPassword, NewPassword, ConfirmNewPassword]);

  const handleChangePassword = (e) => {
    const postData = async () => {
      if (!OldPassword || !NewPassword || !ConfirmNewPassword) {
        return;
      }
      setIsSavingPassword(true);
      var formData = new FormData();
      formData.append("email", Email);
      formData.append("password", OldPassword);
      formData.append("new_password", NewPassword);
      formData.append("confirm_new_password", ConfirmNewPassword);

      await Axios({
        method: "post",
        data: formData,
        withCredentials: true,
        url: `${api_base_url}/login/change_password`,
      }).then((response) => {
        if (response.status === 200 && response.data) {
          checkUserSession();
          ShowNotification({
            type: "success",
            title: "Passwort wurde erfolgreich geändert.",
          });
          setPasswordModalOpened(false);
        } else {
          setValidationMessage(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal."
          );
        }
      });
      setIsSavingPassword(false);
    };
    postData();
    e.preventDefault();
  };
  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <ButtonGroup toggle>
          <ToggleButton
            type="radio"
            variant="primary"
            name="radio"
            checked={screenNo === 0}
            onChange={(e) => setScreenNo(0)}
          >
            Einstellungen
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="primary"
            name="radio"
            checked={screenNo === 1}
            onChange={(e) => setScreenNo(1)}
          >
            Erweitert
          </ToggleButton>
        </ButtonGroup>
        <Button
          style={{ float: "right" }}
          onClick={() => {
            setPasswordModalOpened(true);
          }}
        >
          Passwort ändern
        </Button>
      </div>
      {screenNo === 0 && (
        <>
          <Container>
            <Form id="userForm">
              <Row>
                <Col md={12}>
                  <Form.Label>Name:</Form.Label>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      type="input"
                      name="Name"
                      value={Name}
                      bsClass="form-control txt-input"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Label>E-Mail Adresse:</Form.Label>
                  <Form.Group>
                    <Form.Control
                      readOnly
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="input"
                      name="Name"
                      value={Email}
                      bsClass="form-control txt-input"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                {/* <style>
                {`
                .custom-control-label{padding:0}
                `}
              </style> */}
                <Col md={12}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setEnableEmailAlerts(!enableEmailAlerts);
                    }}
                  >
                    <i
                      className={
                        "fa " +
                        (enableEmailAlerts ? "fa-check-square" : "fa-square")
                      }
                    />{" "}
                    Benachrichtigungen per E-Mail senden
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Label>Benachrichtigungsadresse:</Form.Label>
                  <Form.Group>
                    <Form.Control
                      readOnly={!enableEmailAlerts}
                      onChange={(e) => {
                        setTempAlertsEmail(e.target.value);
                      }}
                      id="emailAlertInput"
                      type="input"
                      name="email"
                      value={tempAlertsEmail}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} lg={12} sm={12}>
                  <div
                    style={{ textAlign: "right", margin: 15, marginRight: 0 }}
                  >
                    <Button
                      disabled={isSaving}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeUsername();
                      }}
                      className="btn-primary"
                    >
                      {isSaving ? (
                        <>
                          <Spinner size="sm" animation="border" />
                          {"  Bitte warten..."}
                        </>
                      ) : (
                        "Speichern"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col md={12}>
                  <p style={{marginLeft:10}}>
                    {AppVersion ? " " + AppVersion : ""}
                  </p>
                </Col>
              </Row>
            </Form>
          </Container>
          <Modal
            centered
            show={passwordModalOpened}
            animation={false}
            onHide={() => {
              setPasswordModalOpened(false);
            }}
          >
            <Form
              autoComplete="false"
              id="password-form"
              onSubmit={handleChangePassword}
            >
              <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-lock'/>Passwort ändern</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col md={12}>
                      <Form.Label>Altes Passwort:</Form.Label>
                      <Form.Group>
                        <Form.Control
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                          autoComplete="new-password"
                          type="password"
                          name="OldPassword"
                          id="OldPasswordInput"
                          value={OldPassword}
                          bsClass="form-control txt-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Label>Neues Passwort:</Form.Label>
                      <Form.Group>
                        <Form.Control
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          autoComplete="new-password"
                          type="password"
                          name="NewPassword"
                          id="NewPasswordInput"
                          value={NewPassword}
                          bsClass="form-control txt-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Label>Neues Passwort wiederholen:</Form.Label>
                      <Form.Group>
                        <Form.Control
                          onChange={(e) => {
                            setConfirmNewPassword(e.target.value);
                          }}
                          autoComplete="new-password"
                          type="password"
                          name="ConfirmNewPassword"
                          id="ConfirmNewPasswordInput"
                          value={ConfirmNewPassword}
                          bsClass="form-control txt-input"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  disabled={isSavingPassword}
                  className="btn-primary"
                >
                  {isSavingPassword ? (
                    <>
                      <Spinner size="sm" animation="border" />
                      {"  Bitte warten..."}
                    </>
                  ) : (
                    "Speichern"
                  )}
                </Button>
                <p style={{ float: "left", color: "#f55" }}>
                  {validationMessage}
                </p>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      )}
      {screenNo === 1 && (
        <Form>
          <Container>
            {SensorsDefaultsList.map((row, key) => {
              return <SensorDefaultValue key={key} defaultVal={row} />;
            })}
          </Container>
        </Form>
      )}
    </div>
  );
};

export default UserSettingsModal;
