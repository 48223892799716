import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import { api_base_url, api_headers } from "../Configs/Configs";
import { UserContext } from "../Contexts/UserContext";

const RegistrationPage = (props) => {
  const passwordRegex = `^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$`;
  const { setNewUser } = useContext(UserContext);
  const [isSaving, setIsSaving] = useState(false);

  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.set("email", email);
    bodyFormData.set("emailConfirmation", emailConfirm);
    bodyFormData.set("password", password);
    bodyFormData.set("passwordConfirmation", passwordConfirm);

    setIsSaving(true);
    axios({
      method: "post",
      url: `${api_base_url}/login/api_registration`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data === "EXISTING") {
          setValidationMessage("Diese E-Mail-Adresse ist vergeben.");
          setIsSaving(false);
          return;
        }
        if (
          response.data.user_id > 0 &&
          response.data.email.length > 0 &&
          response.status === 200
        ) {
          setNewUser({ email: email, password: password });
          setShowSuccessDialog(true);
        }
        setIsSaving(false);
      })
      .catch(function (response) {
        setValidationMessage(
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal."
        );
        setIsSaving(false);
        return;
      });
  };
  const handleCloseDialog = () => {
    setShowSuccessDialog(false);
    props.history.push("login");
  };
  useEffect(() => {
    setValidationMessage("");
    var form = document.getElementById("registr-form");
    var p2 = document.getElementById("passwordConfirmationInput");
    var e1 = document.getElementById("emailConfirmationInput");
    p2.setCustomValidity("");
    e1.setCustomValidity("");

    if (email !== emailConfirm) {
      e1.setCustomValidity(
        "Ihre E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es noch einmal."
      );
    }

    if (password !== passwordConfirm) {
      p2.setCustomValidity(
        "Ihre Passwörter stimmen nicht überein. Bitte versuchen Sie es noch einmal."
      );
    }
    form.checkValidity();
  }, [email, emailConfirm, password, passwordConfirm]);

  return (
    <>
      <style>
        {`
      .invalid-feedback{
        text-align:left;
      }
      `}
      </style>
      <div className={"Login-container"}>
        <Container className={"content"}>
          <Row>
            <Col md={12}>
              <div>
                <h2 className="card-title">
                  <span className="fa fa-lock"></span> Registrierung
                </h2>
                <hr />

                <Form
                  noValidate
                  validated={validated}
                  id="registr-form"
                  method="post"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <input type="hidden" autoComplete="false" />
                  <div className="text-left">
                    <b>Email Adresse</b>
                  </div>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      required
                      autoComplete="off"
                      className="form-control"
                      id="emailInput"
                      name="email"
                      autoFocus="autofocus"
                      placeholder="Ihre E-Mail-Adresse"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie eine gültige E-Mail-Adresse ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      required
                      autoComplete="off"
                      className="form-control"
                      id="emailConfirmationInput"
                      name="emailConfirmation"
                      placeholder="Ihre E-Mail-Adresse wiederholen"
                      value={emailConfirm}
                      onChange={(e) => {
                        setEmailConfirm(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                    Ihre E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es noch einmal.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="text-left">
                    <b>Passwort</b>
                  </div>
                  <Form.Group>
                    <Form.Control
                      minLength={6}
                      required
                      pattern={passwordRegex}
                      autoComplete="new-password"
                      type="password"
                      className="form-control"
                      id="passwordInput"
                      name="password"
                      placeholder="Passwort"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                    Geben Sie eine Kombination aus mindestens sechs Ziffern, mindestens einen Kleinbuchstaben, einen Großbuchstaben und  mindestens ein Satzzeichen ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      minLength={6}
                      required
                      pattern={passwordRegex}
                      autoComplete="new-password"
                      type="password"
                      className="form-control"
                      id="passwordConfirmationInput"
                      name="passwordConfirmation"
                      placeholder="Passwort wiederholen"
                      value={passwordConfirm}
                      onChange={(e) => {
                        setPasswordConfirm(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                    Ihre Passwörter stimmen nicht überein. Bitte versuchen Sie es noch einmal.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row className="pt-2">
                    <Col md={12} lg={12}>
                      <div className="text-left">
                        Bereits angemeldet?{"  "}
                        <Link to="/login" style={{ fontWeight: "600" }}>
                          Login
                        </Link>
                      </div>
                      <div className="Login-submit">
                        <button
                          disabled={isSaving}
                          type="submit"
                          id="submit"
                          className="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          {isSaving ? (
                            <>
                              <Spinner size="sm" animation="border" />
                              {"  Bitte warten..."}
                            </>
                          ) : (
                            "Registrieren"
                          )}
                        </button>
                        <p style={{ float: "left", color: "#f55" }}>
                          {validationMessage}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          centered
          animation={false}
          backdrop="static"
          show={showSuccessDialog}
        >
          <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-check'/>Anmeldung abgeschlossen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Ihr Konto wurde erfolgreich registriert. </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              href="/#/login"
              className="btn-primary"
              onclick={handleCloseDialog}
            >
              Gehe zur Anmeldeseite
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default withRouter(RegistrationPage);
