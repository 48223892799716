import React, { useEffect, useState, useContext } from "react";

import {
  Modal,
  Table,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import ChartsModal from "../Components/Modals/ChartsModal";
import CreateGroupModel from "../Components/Modals/CreateGroupModel";
import GroupInfoModal from "../Components/Modals/GroupInfoModal";

import "./ArchivePage.css";
import "../assets/css/modals.css";
import {
  ArchiveContext,
  ArchiveActionsContext,
} from "../Contexts/ArchiveContext";
import SensorThresholdsComponent from "../Components/Modals/SensorThresholdsComponent";
import { ArchiveRowContextProvider } from "../Contexts/ArchiveRowContext";
import ArchiveRowComponent from "../Components/ArchivePage/ArchiveRowComponent";

const ArchivePage = () => {
  const {
    IsLoading,
    GroupsList,
    ChartParams,
    ChartModalOpened,
    InfoModalOpened,
    EditModalOpenMode,
    DeletedGroupsList,
    ThresholdsModalOpened,
    SelectedSensor,
    reloadSensorHandler,
    SensorsFilter,
  } = useContext(ArchiveContext);
  const {
    setChartModalOpened,
    setInfoModalOpened,
    setEditModalOpenMode,
    setThresholdsModalOpened,
    setSensorsFilter,
  } = useContext(ArchiveActionsContext);

  const [IsFilterChecked, setIsFilterChecked] = useState(false);

  useEffect(() => {}, [IsFilterChecked]);

  useEffect(() => {}, []);

  return (
    <div className="Archive-container">
      {IsLoading ? (
        <div style={{ textAlign: "center", margin: 30 }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="content">
          <div className="Archive-header">
            <div style={{ display: "flex" }}>
              <p className="title">Abgeschlossene Projekte</p>
              {/* <a
                style={{ display: "flex" }}
                id="filterByStaus"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsFilterChecked(!IsFilterChecked);
                }}
              >
                {IsFilterChecked ? (
                  <i className="checkbox accent-color fa fa-check-square" />
                ) : (
                  <i className="checkbox accent-color fa fa-square-o" />
                )}
                <p className="checkbox-text">Gelöschte Projekte Anzeigen</p>
              </a> */}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex",width: 500 }}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text as="i" className="fa fa-search fa-sm" />
                  </InputGroup.Prepend>
                  <Form.Control
                    style={{ height: "100%" }}
                    type="text"
                    placeholder="Suche nach Name, Ort, Datum, MMUI ..."
                    value={SensorsFilter}
                    onChange={(e) => {
                      setSensorsFilter(e.target.value);
                    }}
                  />
                </InputGroup>{" "}
                {SensorsFilter && SensorsFilter.length > 0 && (
                  <Button
                    onClick={() => {
                      setSensorsFilter("");
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      marginTop: 3,
                      marginLeft: -40,
                      zIndex: 55,
                      background: "none",
                      color: "red",
                      border: "none",
                      boxShadow: "none",
                    }}
                    as="i"
                    className="fa fa-times"
                    variant="danger"
                    size="xs"
                  ></Button>
                )}
              </div>
            </div>
          </div>
          <div>
            <Table responsive style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="column-header column1">Projekte</th>
                  <th className="column-header">Beginn / Dauer</th>
                  <th className="column-header">Enddatum</th>
                  <th className="column-header">Notiz</th>
                  <th className="column-header">Geräte anzeigen</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {GroupsList.map((group) => {
                  return (
                    <ArchiveRowContextProvider Group={group} key={group.id}>
                      <ArchiveRowComponent />
                    </ArchiveRowContextProvider>
                  );
                })}
                {IsFilterChecked === true &&
                  DeletedGroupsList.map((group) => {
                    return (
                      <ArchiveRowContextProvider Group={group} key={group.id}>
                        <ArchiveRowComponent />
                      </ArchiveRowContextProvider>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
     {ChartModalOpened&& <ChartsModal
        ChartModalOpened={ChartModalOpened}
        Params={ChartParams}
        handleClose={() => {
          setChartModalOpened(false);
        }}
      />}</div>
  );
};
export default ArchivePage;
