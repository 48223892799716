/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Button, Card, Collapse, Modal, Spinner } from "react-bootstrap";
import {
  GroupInfoModalContextProvider,
  GroupInfoModalContext,
  DeviceStatusContextProvider,
} from "../../Contexts/GroupInfoModalContext";
import { api_base_url } from "../../Configs/Configs";
import { DashboardContext } from "../../Contexts/DashboardContext";
import { UserContext } from "../../Contexts/UserContext";
import SensorThresholdsComponent from "./SensorThresholdsComponent";

const SensorItem = ({ sensor }) => {
  const { setSelectedSensor, setThresholdsModalOpened } = useContext(
    GroupInfoModalContext
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        padding: "4px 8px",
        margin: "5px 0",
        border: "solid 1px #ddd",
        borderRadius: 2,
        backgroundColor: "#eee",
      }}
    >
      <p>
        {sensor.bwb +
          " - " +
          sensor.sid +
          "(" +
          sensor.parameter +
          ")" +
          " port " +
          sensor.port}
      </p>
      <Button
        variant="light"
        size="sm"
        // style={{margin: "0ppx 2px 2px 0px"}}

        onClick={() => {
          setSelectedSensor(sensor);
          setThresholdsModalOpened(true);
          //setReloadSensorHandler(reload);
        }}
      >
        <i className="buttons fa fa-cog" />
      </Button>
    </div>
  );
};

const SensorsListComponent = ({ Bwb }) => {
  const { MeasurandsList } = useContext(GroupInfoModalContext);

  return (
    <div colSpan={10} style={{ padding: "10px 20px", width: "100%" }}>
      {MeasurandsList.filter((c) => c.bwb === Bwb.deviceId).length === 0 && (
        <div style={{ padding: 5, textAlign: "center" }}>
          <h4>Keine Sensoren zum Anzeigen</h4>
        </div>
      )}
      {MeasurandsList.filter((c) => c.bwb === Bwb.deviceId).map(
        (measurand, key) => {
          return <SensorItem sensor={measurand} key={key} />;
        }
      )}
    </div>
  );
};

const DeviceStatusComponent = ({ Device }) => {
  const { checkUserSession } = useContext(UserContext);

  const [is_opened, setIs_opened] = useState(true);

  const [childs, setChilds] = useState([]);

  const [device_type, setDevice_type] = useState(0);

  const [editDeviceName, setEditDeviceName] = useState(false);

  const [currDeviceName, setCurrDeviceName] = useState(Device.name);

  const [newDeviceName, setNewDeviceName] = useState(Device.name);

  const handleEditDeviceName = () => {
    setEditDeviceName(false);
    let newName = newDeviceName;
    if (!newDeviceName || newDeviceName == "") {
      newName =
        (Device.device_type.short
          ? Device.device_type.short.slice(5)
          : Device.device_type
          ? Device.device_type.toUpperCase()
          : "") +
        " " +
        Device.deviceId;
    }
    setNewDeviceName(newName);
    setCurrDeviceName(newName);
    var formData = new FormData();
    formData.append("user_given_name", newName);
    if (!checkUserSession()) return;
    axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/apiDevices/associated_properties_edited/${Device.associated_id}`,
    });
  };

  useEffect(() => {
    if (Device.device_type_id == 1) {
      setChilds(Device.mtbs.filter(c=>c.associated_id));
      setDevice_type(1);
    } else {
      if (Device.device_type_id == 2) {
        setChilds(Device.bwbs.filter(c=>c.associated_id));
        setDevice_type(2);
      } else {
        setDevice_type(3);
      }
    }
  }, []);

  return (
    <>
      <Card className={"device-info-card"}>
        <Card.Header className={"statusRowClass" + Device.device_type_id}>
          <div className={"row-info-content"}>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className={"left-part"}
            >
              {Device.device_type_id != "1" && (
                <div className="row-left-indent">
                  <div></div>
                </div>
              )}
              <div style={{ textAlign: "center", flex: "0 0 40px" }}>
                {childs && childs.length > 0 ? (
                  <div
                    style={{ display: "block", margin: 0 }}
                    className="btn-active"
                    onClick={() => {
                      setIs_opened(!is_opened);
                    }}
                    aria-controls={"deviceStat-" + Device.associated_id}
                    aria-expanded={is_opened}
                  >
                    {is_opened ? (
                      <i className="fa fa-chevron-circle-up" />
                    ) : (
                      <i className="fa fa-chevron-circle-down" />
                    )}
                  </div>
                ) : (
                  <div
                    style={{ display: "block", margin: 0 }}
                    className="btn-active"
                    aria-controls={"deviceStat-" + Device.associated_id}
                    aria-expanded={is_opened}
                  >
                    <i />
                  </div>
                )}
              </div>
              <div>{Device.device_type.short}</div>
            </div>
            <div className={"row-icons-content"}>
              <div className="form">
                {editDeviceName === false ? (
                  <>
                    {currDeviceName}
                    {"  "}
                    <Button
                      variant="light"
                      className="btn-edit"
                      onClick={() => setEditDeviceName(true)}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                  </>
                ) : (
                  <form>
                    <input
                      value={newDeviceName}
                      onChange={(e) => setNewDeviceName(e.target.value)}
                    />
                    {"  "}
                    <Button
                      variant="light"
                      className="btn-edit"
                      onClick={handleEditDeviceName}
                    >
                      <i className="fa fa-save" />
                    </Button>{" "}
                    <Button
                      variant="light"
                      className="btn-edit"
                      onClick={() => setEditDeviceName(false)}
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </form>
                )}
              </div>
              <div>{Device.deviceId}</div>
              {Device.status && Device.status.status === "OK" ? (
                <div>
                  <i className="fa fa-check status-ok" />
                </div>
              ) : (
                <div>
                  <i className="fa fa-times status-warn" />
                </div>
              )}
              <div
                className={
                  Device.status
                    ? Device.status.batstate1 < 25
                      ? "status-warn"
                      : ""
                    : ""
                }
              >
                {Device.device_type_id != "4" && Device.device_type_id != "5" && (
                  <>
                  {!(Device.status && Device.status.batstate1) ? (
                    <i className="fa fa-times status-warn"></i>
                  ) : (
                    <>
                      {Device.status.batstate1 >= 80 && (
                        <i className="fa fa-battery-full"></i>
                      )}
                      {Device.status.batstate1 < 80 &&
                        Device.status.batstate1 >= 50 && (
                          <i className="fa fa-battery-half"></i>
                        )}
                      {Device.status.batstate1 < 50 &&
                        Device.status.batstate1 >= 20 && (
                          <i className="fa fa-battery-quarter"></i>
                        )}
                      {Device.status.batstate1 < 20 && (
                        <i className="fa fa-battery-empty"></i>
                      )}
                    </>
                  )}
                  <div style={{width:5}}></div>
                  {!(Device.status && Device.status.batstate2) ? (
                    <i className="fa fa-times status-warn"></i>
                  ) : (
                    <>
                      {Device.status.batstate2 >= 80 && (
                        <i className="fa fa-battery-full"></i>
                      )}
                      {Device.status.batstate2 < 80 &&
                        Device.status.batstate2 >= 50 && (
                          <i className="fa fa-battery-half"></i>
                        )}
                      {Device.status.batstate2 < 50 &&
                        Device.status.batstate2 >= 20 && (
                          <i className="fa fa-battery-quarter"></i>
                        )}
                      {Device.status.batstate2 < 20 && (
                        <i className="fa fa-battery-empty"></i>
                      )}
                    </>
                  )}
                  </>
                )}
              </div>
              <div>
                {Device.device_type_id != "4" && Device.device_type_id != "5" && (
                  <>
                    {Device.status && Device.status.rssi ? (
                      <i className="fa fa-signal" />
                    ) : (
                      <i className="fa fa-times status-warn" />
                    )}
                    {Device.status && Device.status.rssi
                      ? " " + Device.status.rssi + " db"
                      : ""}
                  </>
                )}
              </div>
            </div>
          </div>
        </Card.Header>

        {childs.length === 0 && device_type < 3 && (
          <div style={{ padding: 5, textAlign: "center" }}>
            <h4>Keine Sensoren zum Anzeigen</h4>
          </div>
        )}
        {childs.length > 0 && device_type < 3 && (
          <Collapse in={is_opened}>
            <div
              id={"deviceStat-" + Device.associated_id}
              style={{ marginLeft: 25, paddingBottom: 2 }}
            >
              {childs.map((device) => {
                return (
                  <>
                    {device.device_type_id != "1" && (
                      <div key={device.associated_id}>
                        <DeviceStatusComponent
                          key={device.associated_id}
                          Device={device}
                          style={{ marginLeft: 25, marginBottom: 2 }}
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </Collapse>
        )}

        {device_type == 3 && (
          <Collapse in={is_opened}>
            <div id={"deviceStat-" + Device.associated_id}>
              <SensorsListComponent Bwb={Device} />
              <LoadingSpinner />
            </div>
          </Collapse>
        )}
      </Card>
    </>
  );
};

const ModalContent = () => {
  const {
    DtbsList,
    MeasurandsList,
    IsLoading,
    ThresholdsModalOpened,
    setThresholdsModalOpened,
    SelectedSensor,
  } = useContext(GroupInfoModalContext);

  useEffect(() => {}, []);

  return (
    <div>
      <div style={{ paddingRight: "1.25rem", paddingLeft: "1.25rem" }}>
        <div className={"row-info-content"}>
          <div style={{ textAlign: "center", flex: "0 0 40px" }}></div>
          <div>
            <strong>Gerätetyp</strong>
          </div>
          <div className={"row-icons-content"}>
            <div>
              <strong>Geräte</strong>
            </div>
            <div>
              <strong>MMUI</strong>
            </div>
            <div>
              <strong>Status</strong>
            </div>
            <div>
              <strong>Batterie</strong>
            </div>
            <div>
              <strong>RSSI</strong>
            </div>
          </div>
        </div>
      </div>
      {DtbsList.length === 0 && !IsLoading && (
        <div style={{ padding: 5, textAlign: "center" }}>
          <h4>Keine Sensoren zum Anzeigen</h4>
        </div>
      )}
      {DtbsList.filter(c=>c.associated_id).map((prop) => {
        if (!MeasurandsList.find((c) => c.dtb === prop.deviceId)) return null;
        return (
          <DeviceStatusContextProvider key={prop.associated_id} Dtb={prop}>
            <div
              key={prop.associated_id}
              // style={{ border: "1px solid #999", margin: "0 2px" }}
            >
              <DeviceStatusComponent key={prop.associated_id} Device={prop} />
            </div>
          </DeviceStatusContextProvider>
        );
      })}
      <Modal
        className="sensor-thresholds-modal"
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        show={ThresholdsModalOpened}
        onHide={() => setThresholdsModalOpened(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-pencil' />Konfigurationssensor bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SensorThresholdsComponent
            displayInModal={true}
            bsClassName="statusRowClass5"
            measurand={SelectedSensor}
            handleClose={() => {
              setThresholdsModalOpened(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const LoadingSpinner = () => {
  const { IsLoading } = useContext(GroupInfoModalContext);
  return (
    <>
      {IsLoading && (
        <div
          id="loading-modal-spinner"
          style={{ width: "100%", textAlign: "center", display: "block" }}
        >
          <Spinner animation="border" />
        </div>
      )}
    </>
  );
};

const GroupInfoModal = () => {
  const { SelectedDeviceGroup } = useContext(DashboardContext);

  return (
    <GroupInfoModalContextProvider DeviceGroup={SelectedDeviceGroup}>
      <ModalContent />
      <LoadingSpinner />
    </GroupInfoModalContextProvider>
  );
};

export default GroupInfoModal;
