import React, { useContext, useState, useEffect } from "react";
const DeviceSensorComponent = ({ sensor }) => {
  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        padding: "4px 8px",
        margin: "5px 0",
        border: "solid 1px #ddd",
        borderRadius: 2,
        backgroundColor: "#eee",
      }}
    >
      <p>
        {sensor.bwb +
          " - " +
          sensor.sid +
          "(" +
          sensor.parameter +
          ")" +
          " port " +
          sensor.port}
      </p>
      
      </div> );
};

export default DeviceSensorComponent;
