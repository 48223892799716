import React, { useContext, useEffect } from "react";
import {
  Redirect,
  BrowserRouter,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import axios from "axios";

import LoginPage from "./Pages/LoginPage";
import RegistrationPage from "./Pages/RegistrationPage";
import DashboardPage from "./Pages/DashboardPage";
import DeviceSetsPage from "./Pages/DeviceSetsPage";
import SelectedDeviceSetPage from "./Pages/SelectedDeviceSetPage";
import NavigationBar from "./Navbars/NavigationBar";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./main.css";
import "./assets/css/pe-icon-7-stroke.css";

import { UserContextProvider, UserContext } from "./Contexts/UserContext";
import { DashboardContextProvider } from "./Contexts/DashboardContext";

import { api_base_url, api_headers } from "./Configs/Configs";
import {
  DevicesContextProvider,
} from "./Contexts/DevicesContext";
import NotificationContextProvider from "./Contexts/NotificationContext";
import Footer from "./Navbars/Footer";
import ImprintPage from "./Pages/ImprintPage";
import {  ArchiveContextProvider } from "./Contexts/ArchiveContext";
import ArchivePage from "./Pages/ArchivePage";
import { DeviceSetsContextProvider } from "./Contexts/DeviceSetsContext";

export function AppContent() {
  const { User, getUserInfo, checkUserSession } = useContext(UserContext);

  useEffect(() => {
    const check = async () => {
      await checkUserSession();
      await getUserInfo();
    };
    check();
  }, []);

  return (
    <HashRouter>
      <NotificationContextProvider User={User}>
        <NavigationBar />

        {User && User.id > 0 ? (
      <DeviceSetsContextProvider User={User}>
          <Switch>
            <Route path={"/dashboard/:pathGroupId?/:pathSensorId?"}>
              <DashboardContextProvider User={User}>
                <DashboardPage />
              </DashboardContextProvider>
            </Route>
            <Route exact path={"/devices"}>
                <DeviceSetsPage />
            </Route>
            <Route exact path={"/devices/:devicesetid/:deviceid?"}>
              <DevicesContextProvider User={User}>
                <SelectedDeviceSetPage />
              </DevicesContextProvider>
            </Route>
            <Route exact path={"/archive/:pathGroupId?/:pathSensorId?"}>
              <ArchiveContextProvider User={User}>
                <ArchivePage />
              </ArchiveContextProvider>
            </Route>
            <Redirect from="/" to="/dashboard" />
          </Switch>
          </DeviceSetsContextProvider>
        ) : (
          <Switch>
            <Route exact path={"/login"} render={(props) => <LoginPage />} />
            <Route exact path={"/imprint"} render={(props) => <ImprintPage />} />

            <Route
              exact
              path={"/register"}
              render={(props) => <RegistrationPage />}
            />

            <Route path={"*"} render={(props) => <LoginPage />} />
            <Redirect from="*" to="/login" />
          </Switch>
        )}
        {!User&&<Footer/>}
      </NotificationContextProvider>
    </HashRouter>
  );
}
export default function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <AppContent />
      </UserContextProvider>
    </div>
  );
}
