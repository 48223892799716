import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as moment from "moment";
import "moment/locale/de";
import "../../assets/css/modals.css";
import Axios from "axios";
import { api_base_url, api_headers } from "../../Configs/Configs";
import { DevicesContext } from "../../Contexts/DevicesContext";
import { UserContext } from "../../Contexts/UserContext";
import { DeviceTypes } from "../../Configs/DeviceConsts";

const ViewDeviceInfoModal = ({ handleClose }) => {
  const { SelectedDevice } = useContext(DevicesContext);
  const { checkUserSession } = useContext(UserContext);

  const [validationMessage, setValidationMessage] = useState("");
  const [screenNo, setScreenNo] = useState(0);

  const [lastValue, setLastValue] = useState(null);

  const [name, setName] = useState(SelectedDevice.name);
  const [info, setInfo] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [xfer, setXfer] = useState("6000");
  const [slave, setSlave] = useState("3000");
  const [tx, setTx] = useState("5");

  useEffect(() => {
    const fetchData = async () => {
      if (!checkUserSession()) return;
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDevices/read_information/${SelectedDevice.associated_id}`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          let device = response.data;

          setName(device.name);
          setInfo(device.device_info);
          if (device.device_info) {
            if (
              device.device_type_id == 1 &&
              device.device_info.configurationParams &&
              device.device_info.configurationParams.mqd &&
              device.device_info.configurationParams.srd
            ) {
              setXfer(
                device.device_info.configurationParams.mqd.data_xfer_interval_ms
              );
              setSlave(
                device.device_info.configurationParams.srd
                  .m_slave_cmd_req_interval_ms
              );
            }
            if (
              device.device_type_id == 2 &&
              device.device_info.configurationParams &&
              device.device_info.configurationParams.bwb
            ) {
              setSlave(
                device.device_info.configurationParams.bwb
                  .slave_cmd_req_interval_ms
              );
            }
            if (
              device.device_info.configurationParams &&
              device.device_info.configurationParams.mqd &&
              device.device_info.configurationParams.mqd.txInterval
            ) {
              setTx(device.device_info.configurationParams.mqd.txInterval);
            }
          }
          if (response.data.uts_start) {
            setStartDate(moment.unix(response.data.uts_start).toDate());
          }
          if (response.data.uts_end) {
            setEndDate(moment.unix(response.data.uts_end).toDate());
          }
        }
      });
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDeviceConfiguration/latest_values_overview/1/1/356/${SelectedDevice.associated_id}`,
      }).then((response) => {
        if (response.data && response.status === 200 
          &&response.data.latest_measurement_values
          &&Array.isArray(response.data.latest_measurement_values)
          &&response.data.latest_measurement_values.length>0) {
          let maxUTS = Math.max.apply(Math,  response.data.latest_measurement_values.map(function(o) { return Number(o.UTS); }));
          maxUTS=maxUTS*1000;
          if(maxUTS&&moment(maxUTS).isValid()){
            setLastValue(maxUTS)
          }
        }
      });
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* <div style={{ marginTop: 20 }}>
        <ButtonGroup toggle>
          <ToggleButton
            type="radio"
            variant="primary"
            name="radio"
            checked={screenNo === 0}
            onChange={(e) => setScreenNo(0)}
          >
            Einstellungen
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="primary"
            name="radio"
            checked={screenNo === 1}
            onChange={(e) => setScreenNo(1)}
          >
            Erweitert
          </ToggleButton>
        </ButtonGroup>
      </div> */}
      <Container>
        <Row>
          <Col md={4} lg={4} sm={12}>
            <strong>Angezeigter Name:</strong>
          </Col>
          <Col md={8} lg={8} sm={12}>
            {name}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4} lg={4} sm={12}>
            <strong>MMUI (MultiMeasure Unique ID):</strong>
          </Col>
          <Col md={8} lg={8} sm={12}>
            <p>{SelectedDevice.deviceId}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4} lg={4} sm={12}>
            <strong>Gerätetyp:</strong>
          </Col>
          <Col md={8} lg={8} sm={12}>
            {DeviceTypes && DeviceTypes[SelectedDevice.device_type_id] ? (
              <p>{DeviceTypes[SelectedDevice.device_type_id].short}</p>
            ) : (
              "---"
            )}
            {DeviceTypes && DeviceTypes[SelectedDevice.device_type_id] ? (
              <p>{DeviceTypes[SelectedDevice.device_type_id].long}</p>
            ) : (
              "---"
            )}
          </Col>
        </Row>
        {startDate && (
          <>
        <hr />
        <Row>
          <Col md={4} lg={4} sm={12}>
            <strong>Startdatum</strong>
          </Col>
          <Col md={8} lg={8} sm={12}>
            {moment(startDate).format("DD.MM.YYYY HH:mm")}
          </Col>
        </Row>
          </>
        )}
        {endDate && (
          <>
            <hr />
            <Row>
              <Col md={4} lg={4} sm={12}>
                <strong>Datum Ende</strong>
              </Col>
              <Col md={8} lg={8} sm={12}>
                {moment(endDate).format("DD.MM.YYYY HH:mm")}
              </Col>
            </Row>
          </>
        )}
        {info && info.inserted && (
          <>
            <hr />
            <Row>
              <Col md={4} lg={4} sm={12}>
                <strong>Hinzugefügt am:</strong>
              </Col>
              <Col md={8} lg={8} sm={12}>
                {info
                  ? moment(info.inserted).format("DD.MM.YYYY HH:mm")
                  : "---"}
              </Col>
            </Row>
          </>
        )}
        {lastValue&&
          <>
            <hr />
            <Row>
              <Col md={4} lg={4} sm={12}>
                <strong>Zuletzt aktualisiert:</strong>
              </Col>
              <Col md={8} lg={8} sm={12}>
                { moment(lastValue).format("DD.MM.YYYY HH:mm") }
              </Col>
            </Row>
          </>
        }
        {info && info.updated && (
          <>
            <hr />
            <Row>
              <Col md={4} lg={4} sm={12}>
                <strong>Zuletzt Daten abgeholt:</strong>
              </Col>
              <Col md={8} lg={8} sm={12}>
                {info ? moment(info.updated).format("DD.MM.YYYY HH:mm") : "---"}
              </Col>
            </Row>
          </>
        )}
      </Container>
      <div style={{ height: 50 }}></div>
    </div>
  );
};

export default ViewDeviceInfoModal;
