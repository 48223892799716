import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Modal, Fade } from "react-bootstrap";

import "./NavigationBar.css";
import "../assets/css/modals.css";
import { UserContext } from "../Contexts/UserContext";
import { UserDialog } from "../Components/Modals/UserDialog";
import avatar from "../assets/images/img_avatar.png";

import UserSettingsModal from "../Components/Modals/UserSettingsDialog";
import NotificationModal from "../Components/Modals/NotificationModal";
import { NotificationContext } from "../Contexts/NotificationContext";
import { motion } from "framer-motion";
import Axios from "axios";
import { api_base_url, api_headers } from "../Configs/Configs";

const NavigationBar = () => {
  const {
    IsNotificationsDialogOpened,
    setIsNotificationsDialogOpened,
    unseenCount,
    dispatchNotification,
    setLoading,
    needReload,
    setNeedReload,
  } = useContext(NotificationContext);

  const [IsUserDialogOpened, setIsUserDialogOpened] = useState(false);
  const [IsSettingsModalOpened, setIsSettingsModalOpened] = useState(false);

  const [ShowNotifCount, setShowNotifCount] = useState(false);

  const { User,AppVersion } = useContext(UserContext);

  const getNotificationsList = async () => {
    if (!needReload) return;
    dispatchNotification({ type: "CLEAR" });
    setLoading(true);
    await Axios({
      method: "get",
      withCredentials: true,
      url: `${api_base_url}/userAlert/get_all`,
    })
      .then((response) => {
        if (
          response.data &&
          response.status === 200 &&
          response.data.all_alerts
        ) {
          response.data.all_alerts.forEach((element) => {
            dispatchNotification({ type: "ADD", item: element });
          });
        }
        setLoading(false);
        setNeedReload(false);
      })
      .catch(() => {
        setLoading(false);
        setNeedReload(true);
      });
  };

  useEffect(() => {
    setNeedReload(true);
    if (IsNotificationsDialogOpened === false)
      setShowNotifCount(unseenCount > 0 ? true : false);
  }, [unseenCount]);

  return (
    <div>
      <div className="NavigationBar-site-header">
        <div className="logo-part">
          <img
            alt="TROTEC LOGO"
            src="/media/trotec-logo.svg"
            width="235"
            className="img-fluid"
          />
          
          <div className="NavigationBar-header-title">MultiMeasure Cloud</div>
        </div>
        {User && User.id > 0 && (
          <div className="right-bar">
            <div className="notif-area">
              <button
                className="btn"
                id="notifi-btn"
                aria-controls="notif-count-fa"
                aria-expanded={ShowNotifCount}
                onClick={() => {
                  setIsNotificationsDialogOpened(true);
                  setShowNotifCount(false);
                  getNotificationsList();
                }}
              >
                <motion.i
                  animate={{
                    rotate: ShowNotifCount ? [0, 55, -15, 55, 0] : 0,
                  }}
                  className="fa fa-bell"
                />

                <motion.div
                  id="notif-count-fa"
                  animate={{
                    opacity: ShowNotifCount ? 1 : 0,
                    scale: ShowNotifCount ? 1 : 0,
                  }}
                >
                  <strong>{unseenCount}</strong>
                </motion.div>
              </button>
            </div>
            <div
              className="user-area"
              onClick={() => setIsUserDialogOpened(!IsUserDialogOpened)}
            >
              <p>{User.username ? User.username : User.email.split("@")[0]}</p>
              <img alt="avatar" className="avatar-mini" src={avatar} />
            </div>
            <UserDialog
              IsOpen={IsUserDialogOpened}
              onHide={() => setIsUserDialogOpened(false)}
              onSettingsButtonClick={() => setIsSettingsModalOpened(true)}
            />
            <Modal
              animation={false}
              dialogClassName="notification-content"
              onHide={() => {
                setIsNotificationsDialogOpened(false);
              }}
              show={IsNotificationsDialogOpened}
            >
              <Modal.Body>
                <NotificationModal />
              </Modal.Body>
            </Modal>

            <Modal
              className="user-settings-modal"
              centered
              size="lg"
              backdrop="static"
              keyboard={false}
              show={IsSettingsModalOpened}
              onHide={() => setIsSettingsModalOpened(false)}
              animation={false}
            >
              <Modal.Header closeButton>
          <Modal.Title as='strong'><i className='fa fa-edit' />Defaultwerte einstellen</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UserSettingsModal
                  handleClose={() => setIsSettingsModalOpened(false)}
                />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
      {User && User.id > 0 ? (
        <div className="NavigationBar-loginNavbar">
          <div className="NavigationBar-navbar-links">
            <NavLink
              className="link"
              to={"/dashboard"}
              isActive={(match, location) => {
                if (match) {
                  return true;
                }
              }}
            >
              <i className="fa fa-home"></i>DASHBOARD
            </NavLink>
            <NavLink
              isActive={(match, location) => {
                if (match) {
                  return true;
                }
              }}
              className="link"
              to={"/devices"}
            >
              <i className="fa fa-th"></i>GERÄTE
            </NavLink>
            <NavLink
              isActive={(match, location) => {
                if (match) {
                  return true;
                }
              }}
              className="link"
              to={"/archive"}
            >
              <i className="fa fa-folder-open"></i>Abgeschlossene Projekte
            </NavLink>
            {/* <NavLink className="link" to={"/settings"}>
              <i className="fa fa-cog"></i>EINSTELLUNGEN
            </NavLink> */}
          </div>
        </div>
      ) : (
        <div className={"NavigationBar-loginNavbar"}>
          <div className="NavigationBar-navbar-links">
            <Link className={"link"} to={"/register"}>
              <i className="fa fa-address-card" />
              REGISTRIEREN
            </Link>
            <Link className={"link"} to={"/login"}>
              <i className="fa fa-sign-in" />
              EINLOGGEN
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(NavigationBar);
