import React, { useContext } from "react";
import {
  Table,
  Collapse,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { DashboardContext } from "../../Contexts/DashboardContext";
import { UserContext } from "../../Contexts/UserContext";
import {
  DeviceGroupActionsContext,
  DeviceGroupContext,
} from "../../Contexts/DeviceGroupContext";
import SensorItem from "./SensorItem";

const SensorsComponent = () => {
  const { SensorsDefaultsList } = useContext(UserContext);
  const { DevicesList, OpenedGroupId } = useContext(DashboardContext);
  const {
    FilteredSensorsList,
    cssClassName,
    GroupLastValues,
    DeviceGroup,
    showAllSensors,
  } = useContext(DeviceGroupContext);
  const { setShowAllSensors } = useContext(DeviceGroupActionsContext);

  return (
    <tr>
      <td colSpan={7} style={{ padding: "5px 10px" }}>
        <Collapse in={OpenedGroupId === DeviceGroup.id}>
          <div id={"groupRow" + DeviceGroup.id}>
            <Table
              className={cssClassName}
              style={{
                borderLeft: "solid 1px #999",
                borderRight: "solid 1px #999",
              }}
            >
              <thead>
                <tr>
                  <th className="column-header column1">Sensor</th>
                  <th className="column-header">Zeitpunkt</th>
                  <th className="column-header">Grenzwerte</th>
                  <th className="column-header">Daten</th>
                  <th className="column-header">Messwert</th>
                  <th className="column-header">Verbindung</th>
                  <th style={{ textAlign: "right" }}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          <p>Versteckte Sensoren anzeigen</p>
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowAllSensors(!showAllSensors);
                        }}
                      >
                        {showAllSensors ? (
                          <i
                            style={{ color: "#383" }}
                            className="fa fa-toggle-on"
                          />
                        ) : (
                          <i className="fa fa-toggle-off" />
                        )}
                      </Button>
                    </OverlayTrigger>
                  </th>
                </tr>
              </thead>
              <tbody>
                {FilteredSensorsList.map((sensor) => {
                  let device = DevicesList.find(
                    (c) => c.deviceId === sensor.bwb
                  );
                  let latest_value = GroupLastValues.find(
                    (c) =>
                      // c.sensor_id === sensor.id
                      c.dtb === sensor.dtb &&
                      //c.mtb === sensor.mtb &&
                      c.bwb === sensor.bwb &&
                      c.sid === sensor.sid &&
                      c.port === sensor.port &&
                      c.parameter === sensor.parameter
                  );
                  let default_val = SensorsDefaultsList.find(
                    (c) =>
                      c.sid === sensor.sid && c.parameter === sensor.parameter
                  );
                    return (
                      <SensorItem
                        device={device}
                        sensor={sensor}
                        default_val={default_val}
                        latest_value={device?latest_value:null}
                        key={sensor.measurand_display_id}
                      />
                    );
                })}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </td>
    </tr>
  );
};
export default SensorsComponent;
