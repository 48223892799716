import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { DashboardActionsContext, DashboardContext } from "./DashboardContext";

export const DeviceGroupContext = React.createContext();
export const DeviceGroupActionsContext = React.createContext();

export const DeviceGroupContextProvider = (props) => {
  const DeviceGroup = props.Group;

  const { SensorsFilter,OpenedGroupId } = useContext(DashboardContext);
  const { setOpenedGroupId } = useContext(DashboardActionsContext);

  const [FilteredSensorsList, setFilteredSensorsList] = useState([]);
  const [SensorsList, setSensorsList] = useState([]);
  const [AllSensorsList, setAllSensorsList] = useState([]);
  const [ActiveSensorsCount, setActiveSensorsCount] = useState(0);
  const [InActiveSensorsCount, setInActiveSensorsCount] = useState(0);
  const [DataSensorsCount, setDataSensorsCount] = useState(0);
  const [NoDataSensorsCount, setNoDataSensorsCount] = useState(0);
  const [ConnectedStatus, setConnectedStatus] = useState(0);

  const [ThresholdSensorsCount, setThresholdSensorsCount] = useState(0);
  let sensorsThresholdsStatusList = {};
  let sensorsActiveStatusList = {};
  let sensorsDataStatusList = {};

  const [showAllSensors, setShowAllSensors] = useState(false);

  const [cssClassName, setCssClassName] = useState();

  const [IsLoading, setIsLoading] = useState(0);
  const AddLoading = () => {
    setIsLoading((IsLoading) => IsLoading + 1);
  };
  const RemoveLoading = () => {
    setIsLoading((IsLoading) => IsLoading - 1);
  };

  const [Configuration, setConfiguration] = useState(null);
  const [GroupLastValues, setGroupLastValues] = useState([]);

  const [selectedSensorId, setSelectedSensorId] = useState(0);
  const { pathGroupId, pathSensorId } = useParams();

  useEffect(() => {
    if (SensorsFilter && SensorsFilter.length > 0) {
      let filter = SensorsFilter.trim();
      let filtered=SensorsList.filter((c) => c.bwb.includes(filter));
      setFilteredSensorsList(filtered);
      if(filtered.length>0){
        setOpenedGroupId(DeviceGroup.id);
      }
    } else {
      setFilteredSensorsList(SensorsList);
    }
  }, [SensorsFilter, SensorsList]);

  useEffect(() => {
    if (DeviceGroup.id == pathGroupId) {
      if (pathSensorId) setSelectedSensorId(pathSensorId);
      else {
        setSelectedSensorId(0);
      }
    } else {
      setSelectedSensorId(0);
    }
  }, [pathSensorId, pathGroupId]);

  useEffect(() => {
    if (
      DeviceGroup.deleted == "0" &&
      GroupLastValues &&
      GroupLastValues.length > 0 &&
      ActiveSensorsCount > 0
    ) {
      if (ActiveSensorsCount === SensorsList.length)
        setCssClassName("rowStatus1");
      else setCssClassName("rowStatus2");
    } else setCssClassName("rowStatus3");
  }, [GroupLastValues, ActiveSensorsCount, SensorsList]);
  
  useEffect(() => {
    let count = Object.entries(sensorsThresholdsStatusList).filter((c) => c[1] === false)
      .length;
    if (count > 0) setCssClassName("rowStatus3");
    setThresholdSensorsCount(count);
  }, [sensorsThresholdsStatusList]);

  useEffect(() => {
    let allCount = Object.entries(sensorsActiveStatusList).length;
      let count = Object.entries(sensorsActiveStatusList).filter((c) => c[1] === false)
        .length;
    if (count > 0) setCssClassName("rowStatus3");
    setInActiveSensorsCount(count);
    setActiveSensorsCount(allCount-count);
  }, [sensorsActiveStatusList]);
  
  useEffect(() => {
    let allCount = Object.entries(sensorsDataStatusList).length;
      let count = Object.entries(sensorsDataStatusList).filter((c) => c[1] === false)
        .length;
    if (count > 0) setCssClassName("rowStatus3");
    setNoDataSensorsCount(count);
    setDataSensorsCount(allCount-count);
  }, [sensorsDataStatusList]);
  
  const refreshSensorsList = () => {
    if (!showAllSensors)
      setSensorsList(
        AllSensorsList.filter(
          (c) =>
            !c.display_in_diagram ||
            c.display_in_diagram == 0 ||
            c.display_in_diagram == "" ||
            c.display_in_diagram == 2
        )
      );
    else setSensorsList(AllSensorsList);
  };

  useEffect(() => {
    refreshSensorsList();
  }, [showAllSensors]);

  return (
    <DeviceGroupContext.Provider
      value={{
        DeviceGroup,
        Configuration,
        SensorsList,
        IsLoading,
        ActiveSensorsCount,
        InActiveSensorsCount,
        DataSensorsCount,
        NoDataSensorsCount,
        ThresholdSensorsCount,
        cssClassName,
        selectedSensorId,
        sensorsThresholdsStatusList,
        sensorsActiveStatusList,
        sensorsDataStatusList,
        GroupLastValues,
        AllSensorsList,
        showAllSensors,
        FilteredSensorsList,
      }}
    >
      <DeviceGroupActionsContext.Provider
        value={{
          setSensorsList,
          setActiveSensorsCount,
          setInActiveSensorsCount,
          AddLoading,
          RemoveLoading,
          setConfiguration,
          setGroupLastValues,
          setAllSensorsList,
          setShowAllSensors,
          refreshSensorsList,
        }}
      >
        {props.children}
      </DeviceGroupActionsContext.Provider>
    </DeviceGroupContext.Provider>
  );
};
