import React, { useState, useContext, useEffect, useReducer } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Table,
  Form,
  Spinner,
  Modal,
  Card,
  Collapse,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as moment from "moment";
import "moment/locale/de";
import { api_base_url, api_headers } from "../../Configs/Configs";
import {
  DashboardActionsContext,
  DashboardContext,
} from "../../Contexts/DashboardContext";
import { UserContext } from "../../Contexts/UserContext";
import { NotificationContext } from "../../Contexts/NotificationContext";
import de from "date-fns/locale/de";
import { Link } from "react-router-dom";
import {
  DeviceSetsActionsContext,
  DeviceSetsContext,
} from "../../Contexts/DeviceSetsContext";
import DeviceSetItemChecked from "../DevicesPage/DeviceSetItemChecked";
import DeviceSetPreview from "./DeviceSetPreview";
import { isNumber } from "../../shared/numberTool";
import { template } from "lodash";
registerLocale("de", de);

const MesurandsReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.element];
    case "REMOVE":
      return state.filter(
        (c) =>
          c.measurand_identification_id !=
          action.element.measurand_identification_id
      );
    case "EDIT":
      var edited = [...state].map((item) => {
        if (
          item.measurand_identification_id ===
          action.element.measurand_identification_id
        ) {
          return action.element;
        } else return item;
      });
      return edited;
    default:
      return state;
  }
};

const MeasurandItem = ({ Measurand, handleRemove, handleEdit }) => {
  const [measurand, setMeasurand] = useState(Measurand);

  const handleToggleActive = () => {
    let data = { ...measurand.data, active: "0" };
    setMeasurand({ ...measurand, data: data });
  };

  useEffect(() => {
    handleEdit(measurand);
  }, [measurand]);

  return (
    <tr>
      {/* <td>
        <a onClick={handleToggleActive}>
          {active === true ? (
            <i className="fa fa-check-square" />
          ) : (
            <i className="fa fa-square" />
          )}
        </a>
      </td> */}
      <td>{Measurand.data.dtb}</td>
      <td>{Measurand.data.mtb}</td>
      <td>{Measurand.data.bwb}</td>
      <td>{Measurand.data.port}</td>
      <td>{Measurand.data.sid}</td>
      <td>{Measurand.data.parameter}</td>
      <td>
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              variant="light"
              onClick={Measurand.id ? handleToggleActive : handleRemove}
            >
              <i className="fa fa-times" style={{ color: "#f00" }} />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </td>
    </tr>
  );
};

const CreateGroupModel = ({ handleClose }) => {
  //#region device model state
  const { SelectedDeviceGroup, EditModalOpenMode, LastValuesList } = useContext(
    DashboardContext
  );
  const { DeviceSetsList } = useContext(DeviceSetsContext);
  const { dispatchDeviceSet } = useContext(DeviceSetsActionsContext);
  const { ShowNotification } = useContext(NotificationContext);
  const { User, checkUserSession } = useContext(UserContext);
  const [CheckedDeviceSets, setCheckedDeviceSets] = useState({});
  const [DevicesList, setDevicesList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);
  const [showChangeEnddateModal, setShowChangeEnddateModal] = useState(false);
  const [showConfirmLocationModal, setConfirmLocationModal] = useState(false);
  const [showPreviewDeviceSetModal, setShowPreviewDeviceSetModal] = useState(
    false
  );
  const [showInsertSensorModal, setInsertSensorModal] = useState(false);
  const [insertOwnedList, setInsertOwnedList] = useState(false);
  const [insertActiveList, setInsertActiveList] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [is_advanced_opened, setIs_advanced_opened] = useState(false);
  const [suggestedLocation, setSuggestedLocation] = useState(null);

  const [groupId, setGroupId] = useState(0);
  const [confId, setConfId] = useState(0);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hasEndDate, setHasEndDate] = useState(false);

  const [dtb, setDTB] = useState();
  const [mtb, setMTB] = useState();
  const [bwb, setBWB] = useState();
  const [sid, setSID] = useState();
  const [port, setPORT] = useState();
  // response from server

  const [Measurands, dispatchMeasurands] = useReducer(MesurandsReducer, []);
  const [MeasurandsList, setMeasurandsList] = useState([]);
  const [sensorCapabilities, setSensorCapabilities] = useState();
  const [activeSensorsList, setActiveSensorsList] = useState([]);
  const [availableSensorsList, setAvailableSensorsList] = useState([]);
  const [devicesTree, setDevicesTree] = useState([]);
  const [previewDevicesTree, setPreviewDevicesTree] = useState([]);

  const [mtbs, setMTBs] = useState([]);
  const [dtbs, setDTBs] = useState([]);
  const [bwbs, setBWBs] = useState([]);
  const [ports, setPorts] = useState([1, 2]);
  const [sids, setSIDs] = useState([]);
  const [params, setParams] = useState([]);

  const [validationMessage, setValidationMessage] = useState("");
  const [hasFinished, setHasFinished] = useState(false);

  const { dispatchGroup } = useContext(DashboardActionsContext);

  //#endregion

  //#region Fetch data from server
  useEffect(() => {
    const fetchData = async () => {
      if (!checkUserSession()) return;
      let sensorCapabilities_temp = null;
      let devicesTree_temp = null;
      await axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDevices/associated/0/0/${User.id}?step=0`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          setDevicesList(response.data.devices);
        }
      });
      await axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/measurand_combinations/${User.id}`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          sensorCapabilities_temp = response.data.sensor_capabilities;
          setSensorCapabilities(sensorCapabilities_temp);

          //#endregion
        }
      });
      await axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDeviceConfiguration/device_overview/0/0?step=0`,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.own_devices &&
          Array.isArray(response.data.own_devices)
        ) {
          devicesTree_temp = response.data.own_devices;
          setDevicesTree(devicesTree_temp);
        }
        setCanInsert(true);
      });
      await axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/measurands/${User.id}`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          let tempList = [];
          let tempList2 = [];
          if (
            response.data.status_ok &&
            Array.isArray(response.data.status_ok)
          ) {
            response.data.status_ok.forEach((element) => {
              if (element.measurands) {
                tempList = tempList.concat(element.measurands);
              }
            });
          }
          if (
            response.data.actually_owned &&
            Array.isArray(response.data.actually_owned)
          ) {
            response.data.actually_owned.forEach((element) => {
              if (element.measurands) {
                tempList = tempList.concat(element.measurands);
              }
            });
          }
          if (
            response.data.associated &&
            Array.isArray(response.data.associated)
          ) {
            response.data.associated.forEach((element) => {
              if (element.measurands) {
                tempList = tempList.concat(element.measurands);
              }
            });
          }
          setActiveSensorsList(tempList);
          if (devicesTree_temp && sensorCapabilities_temp) {
            devicesTree_temp.forEach((dtb) => {
              if (dtb.associated_id && dtb.mtbs && Array.isArray(dtb.mtbs)) {
                dtb.mtbs.forEach((mtb) => {
                  if (
                    mtb.associated_id &&
                    mtb.bwbs &&
                    Array.isArray(mtb.bwbs)
                  ) {
                    mtb.bwbs.forEach((bwb) => {
                      if (bwb.associated_id) {
                        if (sensorCapabilities_temp[bwb.device_type_id]) {
                          Object.keys(
                            sensorCapabilities_temp[bwb.device_type_id]
                          ).forEach((port) => {
                            Object.keys(
                              sensorCapabilities_temp[bwb.device_type_id][port]
                            ).forEach((sid) => {
                              sensorCapabilities_temp[bwb.device_type_id][port][
                                sid
                              ].forEach((parameter) => {
                                tempList2.push({
                                  dtb: dtb.deviceId,
                                  mtb: mtb.deviceId,
                                  bwb: bwb.deviceId,
                                  user_id: User.id,
                                  port,
                                  sid,
                                  parameter,
                                });
                              });
                            });
                          });
                        }
                      }
                    });
                  }
                });
              }
            });
          }
          setAvailableSensorsList(tempList2);
        }
      });

      if (EditModalOpenMode == 2) {
        getDataForEditing();
      } else {
        setIsFirstLoading(false);
      }
    };

    fetchData();
  }, []);

  const getDataForEditing = async () => {
    if (!checkUserSession()) return;
    await axios({
      method: "get",
      withCredentials: true,
      url: `${api_base_url}/apiMeasurementConfiguration/configuration/${SelectedDeviceGroup.id}`,
    }).then((response) => {
      if (response.data && response.status === 200) {
        setGroupId(response.data.id);
        setName(response.data.title);
        setMeasurandsList(response.data.measurands);
        response.data.measurands.forEach((e) => {
          let measurand = {
            id: e.id,
            measurand_display_id: e.measurand_display_id,
            measurand_identification_id: e.measurand_identification_id,
            data: {
              active: e.active,
              dtb: e.dtb,
              mtb: e.mtb,
              bwb: e.bwb,
              sid: e.sid,
              port: e.port,
              parameter: e.parameter,
            },
          };
          dispatchMeasurands({ type: "ADD", element: measurand });
          let deviceSets = {};
          DeviceSetsList.filter(
            (c) => c.project_id == SelectedDeviceGroup.id
          ).forEach((element) => {
            deviceSets[element.id] = true;
          });
          setCheckedDeviceSets(deviceSets);
        });
      }
    });
    await axios({
      method: "get",
      withCredentials: true,
      url: `${api_base_url}/apiMeasurements/measurements/${User.id}?step=0`,
    }).then((response) => {
      if (
        response.data &&
        response.status === 200 &&
        response.data.measurements.length > 0
      ) {
        var conf = response.data.measurements.find(
          (c) => c.measurement_configuration_id === SelectedDeviceGroup.id
        );
        if (conf) {
          setConfId(conf.id);
          setLocation(conf.location ? conf.location : "");
          setNotes(
            conf.note_1 && conf.note_1 != null && conf.note_1 != "null"
              ? conf.note_1
              : ""
          );
          var date = conf.starts_at
            ? moment(conf.starts_at).toDate()
            : new Date();
          setStartDate(date);
          var enddate = conf.ends_at
            ? moment(conf.ends_at).toDate()
            : new Date();
          setHasEndDate(conf.ends_at ? true : false);
          setEndDate(enddate);
        }
      }
    });
    setIsFirstLoading(false);
  };

  useEffect(() => {
    setDTBs(DevicesList.filter((c) => c.device_type_id == 1));
    // setMTBs(DevicesList.filter((c) => c.device_type_id == 2));
    //#region set BWBs
    // setBWBs(
    //   DevicesList.filter((c) => c.device_type_id != 2 && c.device_type_id != 1)
    // );
  }, [DevicesList]);

  //#endregion
  useEffect(() => {
    let active = dtbs.filter((c) => c.active == 1);
    if (active.length > 0) setDTB(active[0]);
  }, [dtbs]);
  useEffect(() => {
    let active = mtbs.filter((c) => c.active == 1);
    if (active.length > 0) setMTB(active[0]);
  }, [mtbs]);
  useEffect(() => {
    setBWB(null);
    let active = bwbs.filter((c) => c.active == 1);
    if (active.length > 0) setBWB(active[0]);
  }, [bwbs]);
  useEffect(() => {
    if (ports.length > 0) setPORT(ports[0]);
  }, [ports]);
  useEffect(() => {
    if (sids.length > 0) setSID(sids[0]);
  }, [sids]);

  useEffect(() => {
    if (!sensorCapabilities || !bwb) return;
    setPorts(Object.keys(sensorCapabilities[bwb.device_type_id]));
  }, [bwb, bwbs]);
  useEffect(() => {
    loadSIDs();
  }, [port, ports]);

  const loadSIDs = () => {
    try {
      if (!sensorCapabilities || !bwb || !port) return;
      let arr = Object.keys(sensorCapabilities[bwb.device_type_id][port]);
      if (arr) {
        setSIDs(arr);
        return arr;
      } else {
        setSIDs([]);
      }
    } catch (error) {}
  };
  const loadParams = (_sid) => {
    try {
      if (!sensorCapabilities || !bwb || !port || !_sid) return;
      let arr = sensorCapabilities[bwb.device_type_id][port][_sid];
      if (arr) {
        setParams(arr);
        return arr;
      } else {
        setParams([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadParams(sid);
  }, [sid, sids]);

  useEffect(() => {
    setMTBs(DevicesList.filter((c) => c.device_type_id == 2));
  }, [dtb]);
  useEffect(() => {
    setBWBs(
      DevicesList.filter((c) => c.device_type_id != 1 && c.device_type_id != 2)
    );
  }, [mtb]);

  const insertSensor = async (newE) => {
    let exist = Measurands.find(
      (c) =>
        c.data.active &&
        c.data.active == "1" &&
        c.data.dtb === newE.dtb &&
        c.data.mtb === newE.mtb &&
        c.data.bwb === newE.bwb &&
        c.data.sid === newE.sid &&
        c.data.port === newE.port &&
        c.data.parameter === newE.parameter
    );
    if (exist) {
      return;
    }
    newE["active"] = "1";
    newE["bwb_name"] = newE.bwb;
    const fetchData = async () => {
      setCanInsert(false);
      var formData = new FormData();
      for (var key in newE) {
        formData.append(key, newE[key]);
      }
      const response = await axios({
        method: "post",
        withCredentials: true,
        data: formData,
        url: `${api_base_url}/apiMeasurementConfiguration/measurand_identification_id/1`,
      });
      if (response.data && response.status === 200) {
        var measurand = {
          measurand_identification_id: response.data,
          measurand_display_id: 0,
          data: newE,
        };
        dispatchMeasurands({ type: "ADD", element: measurand });
      }
      setCanInsert(true);
      setInsertSensorModal(false);
    };

    await fetchData();
  };

  const handleInsert = async () => {
    if (!checkUserSession()) return;
    let _sid = sid;
    let _params = params;
    if (!_sid || !sids || sids.length === 0) {
      let arr = loadSIDs();
      if (arr && Array.isArray(arr) && arr[0]) _sid = arr[0];

      _params = loadParams(_sid);
    }
    if (
      !dtb ||
      !mtb ||
      !bwb ||
      !_sid ||
      !port ||
      !_params ||
      _params.length === 0
    )
      return;
    _params.forEach((parameter) => {
      var newE = {
        user_id: User.id,
        dtb: dtb.deviceId,
        mtb: mtb.deviceId,
        bwb: bwb.deviceId,
        sid: _sid,
        port,
        parameter,
      };
      insertSensor(newE);
    });
  };

  const handleAddAllActiveSensors = () => {
    setInsertActiveList(true);
    activeSensorsList.forEach(async (element) => {
      await insertSensor(element);
    });
    setInsertActiveList(false);
  };
  const handleAddAllAvailableSensors = () => {
    setInsertOwnedList(true);
    let count = 0;
    console.log("CheckedDeviceSets");
    console.log(CheckedDeviceSets);
    console.log("availableSensorsList");
    console.log(availableSensorsList);
    availableSensorsList.forEach(async (element) => {
      let dtb = DevicesList.find((c) => c.deviceId == element.dtb);

      if (
        dtb &&
        CheckedDeviceSets[dtb.device_set_id] &&
        CheckedDeviceSets[dtb.device_set_id] == true
      ) {
        count++;
        await insertSensor(element);
      }
    });
    if (count == 0) {
      ShowNotification({
        type: "info",
        title: "Keine angeschlossenen Geräte gefunden",
      });
    }
    setInsertOwnedList(false);
  };

  const handleCheckDeviceSet = (deviceSetId) => {
    let count = 0;
    activeSensorsList.forEach(async (element) => {
      let dtb = DevicesList.find((c) => c.deviceId == element.dtb);
      let mtb = DevicesList.find((c) => c.deviceId == element.mtb);
      let bwb = DevicesList.find((c) => c.deviceId == element.bwb);
      if (dtb && mtb && bwb && dtb.device_set_id == deviceSetId) {
        count++;
        await insertSensor(element);
      }
    });
    setCheckedDeviceSets({ ...CheckedDeviceSets, [deviceSetId]: true });
    let checkedSet = DeviceSetsList.find((c) => c.id == deviceSetId);
    if (
      checkedSet &&
      checkedSet.custom_field_1 &&
      checkedSet.custom_field_1 != ""
    ) {
      setSuggestedLocation(checkedSet.custom_field_1);
      setConfirmLocationModal(true);
    }
    if (count == 0) {
      ShowNotification({
        type: "info",
        title: "Keine angeschlossenen Geräte gefunden",
      });
    }
    // devicesTree.own_devices.forEach((element) => {
    //   let dtb = DevicesList.find((c) => c.deviceId == element.deviceId);
    //   if (dtb && dtb.device_set_id == deviceSetId) {
    //     element.mtbs.forEach((mtb) => {
    //       element.bwbs.forEach((bwb) => {});
    //     });
    //   }
    // });
  };
  const handleUncheckDeviceSet = (deviceSetId) => {
    Measurands.forEach((element) => {
      let dtb = DevicesList.find((c) => c.deviceId == element.data.dtb);
      if (dtb && dtb.device_set_id == deviceSetId) {
        let data = element.measurand_display_id
          ? { ...element.data, active: "1" }
          : { ...element.data, active: "0" };
        dispatchMeasurands({
          type: "EDIT",
          element: { ...element, data: data },
        });
      }
    });
    setCheckedDeviceSets({ ...CheckedDeviceSets, [deviceSetId]: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Measurands || Measurands.length === 0) {
      setValidationMessage("Bitte fügen Sie mindestens ein Gerät ein");
      return;
    }
    const postData = async () => {
      setIsSaving(true);
      //posting measurment group
      var formData = new FormData();
      formData.append("title", name);
      formData.append("cost_center", "");
      formData.append("branch_office", "");
      formData.append("customer_field_1", "");
      formData.append("customer_field_2", "");
      formData.append("customer_field_3", "");
      Measurands.forEach((element) => {
        formData.append("active[]", element.data.active);
        formData.append("measurand_display_id[]", element.measurand_display_id);
        formData.append(
          "measurand_identification_id[]",
          element.measurand_identification_id
        );
      });
      formData.append("user_id", User.id);

      let newGroupId = 0;
      if (EditModalOpenMode == 1) {
        //create mode
        await axios({
          method: "post",
          withCredentials: true,
          data: formData,
          url: `${api_base_url}/apiMeasurementConfiguration/create`,
        }).then((response) => {
          if (response.data && response.status === 200) {
            newGroupId = response.data;
            setGroupId(response.data);
          } else {
            //TODO: handle post error, and show message
          }
        });
      } else {
        await axios({
          method: "post",
          data: formData,
          withCredentials: true,
          url: `${api_base_url}/apiMeasurementConfiguration/edit/${SelectedDeviceGroup.id}`,
        }).then((response) => {
          //TODO: handle post error, and show message
        });
      }

      //posting measurment group end

      var formData = new FormData();
      formData.append("id", confId);
      formData.append("location", location);
      formData.append(
        "measurement_configuration_id",
        EditModalOpenMode == 1 ? newGroupId : groupId
      );
      formData.append("starts_at", moment(startDate).unix());
      formData.append("ends_at", hasEndDate ? moment(endDate).unix() : "");
      formData.append("note_1", notes);
      formData.append("note_2", "");
      formData.append("associated_device_type_id", "0");
      formData.append("associated_device_id", "0");
      formData.append("order_number", "");
      formData.append("project_number", "");
      formData.append("phone_number", "");
      formData.append("user_id", User.id);
      await axios({
        method: "post",
        withCredentials: true,
        data: formData,
        url: `${api_base_url}/apiMeasurements/edit`,
      });
      Object.entries(CheckedDeviceSets).forEach(async (element) => {
        let deviceSet = DeviceSetsList.find((c) => c.id == element[0]);
        if (deviceSet) {
          deviceSet["project_id"] =
            element[1] == true
              ? EditModalOpenMode == 1
                ? newGroupId
                : groupId
              : null;
          var formData = new FormData();
          for (var key in deviceSet) {
            formData.append(key, deviceSet[key]);
          }
          await axios({
            method: "post",
            data: formData,
            withCredentials: true,
            url: `${api_base_url}/deviceSets/edit_device_set`,
          }).then((response) => {
            if (response.data && response.status === 200) {
              dispatchDeviceSet({ type: "EDIT", deviceSet: deviceSet });
            }
          });
        }
      });
      setHasFinished(true);
      setIsSaving(false);
    };

    postData();
  };

  useEffect(() => {
    if (hasFinished === false || groupId === 0) return;

    const getData = async () => {
      await axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/configuration/${groupId}`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          if (EditModalOpenMode == 1) {
            dispatchGroup({ type: "INSERT", group: response.data });
            ShowNotification({
              type: "success",
              title: "Projekt wurde erfolgreich hinzugefügt",
            });
          } else {
            dispatchGroup({ type: "EDIT", group: response.data });
            ShowNotification({
              type: "success",
              title: "Projekt wurde erfolgreich aktualisiert",
            });
          }
          handleClose();
        } else {
          //TODO: hanlde error
        }
      });
    };
    getData();
  }, [groupId, hasFinished]);

  const handleArchive = () => {
    const postData = async () => {
      let formData = new FormData();
      formData.append("title", name);
      formData.append("cost_center", "");
      formData.append("branch_office", "");
      formData.append("customer_field_1", "ARCHIV");
      formData.append("customer_field_2", "");
      formData.append("customer_field_3", "");
      formData.append("user_id", User.id);
      await axios({
        method: "post",
        data: formData,
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/edit/${SelectedDeviceGroup.id}`,
      }).then(async (response) => {
        await Promise.all(
          MeasurandsList.map(async (sensor) => {
            let formData = new FormData();
            let bwb = DevicesList.find((c) => c.deviceId == sensor.bwb);
            var latest_value = LastValuesList.find(
              (c) =>
                // c.sensor_id === sensor.id
                c.dtb === sensor.dtb &&
                //c.mtb === sensor.mtb &&
                c.bwb === sensor.bwb &&
                c.sid === sensor.sid &&
                c.port === sensor.port &&
                c.parameter === sensor.parameter
            );
            var val = {
              ...sensor,
              threshold_min: isNumber(sensor.threshold_min)
                ? sensor.threshold_min
                : "",
              threshold_max: isNumber(sensor.threshold_max)
                ? sensor.threshold_max
                : "",
              target_min: isNumber(sensor.target_min) ? sensor.target_min : "",
              target_max: isNumber(sensor.target_max) ? sensor.target_max : "",
              extra_field_1: sensor.extra_field_1,
              extra_field_2: sensor.extra_field_2,
              bwb_name: bwb ? bwb.user_given_name : sensor.bwb_name,
              last_value: latest_value
                ? latest_value.calculated_value
                : sensor.last_value,
              last_value_uts: latest_value
                ? latest_value.UTS
                : sensor.last_value_uts,
              device_type: bwb ? bwb.device_type_id : sensor.device_type,
              symbol: latest_value ? latest_value.symbol : sensor.symbol,
              unit: latest_value ? latest_value.unit : sensor.unit,
              user_id: User.id,
            };
            for (var key in val) {
              formData.append(key, val[key]);
            }
            await axios({
              method: "post",
              data: formData,
              withCredentials: true,
              url: `${api_base_url}/apiMeasurementConfiguration/change_measurand_properties/1`,
            });
          })
        );
        //TODO: handle post error, and show message

        formData = new FormData();
        formData.append("id", confId);
        formData.append("location", location);
        formData.append("measurement_configuration_id", groupId);
        formData.append("starts_at", moment(startDate).unix());
        formData.append("ends_at", moment(endDate).unix());
        formData.append("note_1", notes);
        formData.append("note_2", "");
        formData.append("associated_device_type_id", "0");
        formData.append("associated_device_id", "0");
        formData.append("order_number", "");
        formData.append("project_number", "");
        formData.append("phone_number", "");
        formData.append("user_id", User.id);
        await axios({
          method: "post",
          withCredentials: true,
          data: formData,
          url: `${api_base_url}/apiMeasurements/edit`,
        });
        dispatchGroup({ type: "DELETE", group: SelectedDeviceGroup });
        handleClose();
        ShowNotification({
          type: "success",
          title: "Projekt wurde erfolgreich archiviert",
        });
      });
    };
    postData();
  };

  const handleDelete = async () => {
    await axios({
      method: "get",
      withCredentials: true,
      url: `${api_base_url}/apiMeasurementConfiguration/delete/${SelectedDeviceGroup.id}`,
    }).then((response) => {
      dispatchGroup({ type: "DELETE", group: SelectedDeviceGroup });
      setShowConfirmDeleteModal(false);
      handleClose();
      ShowNotification({
        type: "success",
        title: "Projekt wurde erfolgreich entfernt",
      });
    });
  };

  const handlePreviewDeviceSet = (deviceSet) => {
    // let prev=[...devicesTree.filter(c=>c.device_set_id==deviceSet.id)];
    // setPreviewDevicesTree(prev);
    // setShowPreviewDeviceSetModal(true);
  };

  const injectOptions = (arr) => {
    let options = [];
    let proposed = [];
    let active = arr.filter((c) => c.active == 1);
    // let inactive = arr.filter((c) => c.active == 0);

    if (arr.length > 0 && arr[0].device_type_id == 1) {
      //dtbs list
      //TODO: filter dtbs list by checked device sets
      // active=active.filter(c=>c.device_set_id==sele)
      proposed = devicesTree.filter(
        (c) => c.mtbs && Array.isArray(c.mtbs) && c.mtbs.length > 0
      );
    }
    if (arr.length > 0 && arr[0].device_type_id == 2) {
      //mtbs list
      let _dtb = devicesTree.find((c) => c.associated_id === dtb.id);
      if (_dtb && _dtb.mtbs && Array.isArray(_dtb.mtbs)) {
        proposed = _dtb.mtbs;
      }
    }
    if (
      arr.length > 0 &&
      (arr[0].device_type_id == 4 || arr[0].device_type_id == 5)
    ) {
      //bwbs list
      let _dtb = devicesTree.find((c) => c.associated_id === dtb.id);
      if (_dtb && _dtb.mtbs && Array.isArray(_dtb.mtbs)) {
        let _mtb = _dtb.mtbs.find((c) => c.associated_id === mtb.id);
        if (_mtb && _mtb.bwbs && Array.isArray(_mtb.bwbs)) proposed = _mtb.bwbs;
      }
    }

    // if (proposed.length > 0) {
    //   options.push(<optgroup key="-1" label="Proposed:" />);

    //   for (let i = 0; i < proposed.length; i++) {
    //     let item = arr.find((c) => c.id === proposed[i].associated_id);
    //     options.push(
    //       <option key={item.id} value={item.id}>
    //         {item.user_given_name + " (" + item.deviceId + ")"}
    //       </option>
    //     );
    //   }
    // }

    // if (inactive.length > 0) {
    //   options.push(<optgroup key="-2" label="Aktiv:" />);
    // }
    for (let i = 0; i < active.length; i++) {
      let found = proposed.find((c) => c.associated_id === active[i].id)
        ? true
        : false;
      if (found) {
        options.push(
          <option
            // style={{
            //   color: found ? "#338833" : "",
            //   fontWeight: found ? "bolder" : "",
            // }}
            key={active[i].id}
            value={active[i].id}
          >
            {active[i].user_given_name + " (" + active[i].deviceId + ")"}
          </option>
        );
      }
    }

    // if (inactive.length > 0) {
    //   options.push(<optgroup key="-3" label="Inaktiv:" />);
    //   for (let i = 0; i < inactive.length; i++) {
    //     options.push(
    //       <option key={inactive[i].id} value={inactive[i].id}>
    //         {inactive[i].user_given_name + " (" + inactive[i].deviceId + ")"}
    //       </option>
    //     );
    //   }
    // }
    return options;
  };

  useEffect(() => {
    var form = document.getElementById("create-form");
    var nameInput = document.getElementById("input-name");
    var locationInput = document.getElementById("input-location");
    var dateInput = document.getElementById("input-date");
    nameInput.setCustomValidity("");
    locationInput.setCustomValidity("");
    dateInput.setCustomValidity("");
    setValidationMessage("");

    if (!name) {
      nameInput.setCustomValidity("Bitte geben Sie einen gültigen Wert ein");
    }
    if (!location) {
      locationInput.setCustomValidity(
        "Bitte geben Sie einen gültigen Wert ein"
      );
    }
    if (!startDate) {
      dateInput.setCustomValidity("Bitte geben Sie einen gültigen Wert ein");
    }
    form.checkValidity();
  }, [name, location, startDate, Measurands]);

  //#region Return dialog
  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit} id="create-form">
          {isFirstLoading && (
            <Row>
              <Col
                md={12}
                style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}
              >
                <Spinner animation="border" />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12} lg={12} sm={12}>
              <Form.Label>Name</Form.Label>
              <Form.Group>
                <Form.Control
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  id="input-name"
                  type="input"
                  name="Name"
                  value={name}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={hasEndDate ? 4 : 6}>
              <Form.Label>Standort</Form.Label>
              <Form.Group>
                <Form.Control
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  id="input-location"
                  type="input"
                  name="Standort"
                  value={location}
                />
              </Form.Group>
            </Col>
            <Col md={hasEndDate ? 4 : 6}>
              <Form.Label>Startdatum</Form.Label>
              <Form.Group>
                <DatePicker
                  locale={de}
                  timeCaption="Uhrzeit"
                  placeholderText=""
                  showTimeSelect
                  id="input-date"
                  dateFormat="dd.MM.yyyy HH:mm"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control txt-input"
                  style={{ width: "100%" }}
                />
              </Form.Group>
            </Col>
            {/* {!hasEndDate&&<Col>
              <Form.Label> </Form.Label>
              <Button variant='light' onClick={()=>{setShowChangeEnddateModal(true)}}><span className='fa fa-stopwatch'/></Button>
            </Col>} */}
            {hasEndDate && (
              <Col md={4}>
                <Form.Label>Enddatum</Form.Label>
                <Form.Group>
                  <DatePicker
                    locale={de}
                    timeCaption="Uhrzeit"
                    placeholderText=""
                    showTimeSelect
                    id="input-date"
                    dateFormat="dd.MM.yyyy HH:mm"
                    minDate={startDate}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control txt-input"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12} lg={12} sm={12}>
              <Form.Label>Notiz</Form.Label>
              <Form.Group>
                <textarea
                  rows="3"
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  name="Notiz"
                  value={notes}
                  className="form-control "
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12}>
              <h4
                style={{ fontWeight: "bold", marginBottom: 10, marginTop: 10 }}
              >
                Geräte-Set auswählen
              </h4>
            </Col>
            <Col md={12} lg={12} sm={12} style={{ marginBottom: 20 }}>
              {/* <Button
                onClick={handleAddAllActiveSensors}
                disabled={insertActiveList}
                style={{ marginRight: 10 }}
                variant="info"
              >
                <>
                  {insertActiveList && <Spinner size="sm" animation="border" />}
                  {"Verbundene Geräte hinzufügen (" +
                    activeSensorsList.length +
                    ")"}{" "}
                </>
              </Button> */}
              {/* <Button
                onClick={handleAddAllOwnedSensors}
                disabled={insertOwnedList}
                style={{ marginRight: 10 }}
                variant="info"
              >
                <>
                  {insertOwnedList && <Spinner size="sm" animation="border" />}
                  {"  Alle verbinden Sensoren (" +
                    ownedSensorsList.length +
                    ")"}{" "}
                </>
              </Button> */}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                {DeviceSetsList.map((DeviceSet) => {
                  return (
                    <DeviceSetItemChecked
                      handlePreview={handlePreviewDeviceSet(DeviceSet)}
                      checked={CheckedDeviceSets[DeviceSet.id]}
                      onCheck={handleCheckDeviceSet}
                      onRemoveCheck={handleUncheckDeviceSet}
                      style={{ width: "150px", marginRight: 15, marginTop: 15 }}
                      key={DeviceSet.id}
                      DeviceSet={DeviceSet}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} sm={12}>
              <Card>
                <Card.Header
                  aria-controls={"advanced-table"}
                  aria-expanded={is_advanced_opened}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setIs_advanced_opened(!is_advanced_opened);
                  }}
                >
                  Erweiterte Einstellungen (Expertenmodus)
                </Card.Header>
                <Collapse className="card-body" in={is_advanced_opened}>
                  <div id="advanced-table">
                    <Button
                      style={{ marginBottom: 10 }}
                      onClick={() => {
                        handleAddAllAvailableSensors();
                      }}
                      variant="dark"
                    >
                      Alle Geräte hinzufügen
                    </Button>
                    <Button
                      style={{ marginBottom: 10, marginLeft: 10 }}
                      onClick={() => {
                        setInsertSensorModal(true);
                      }}
                      variant="dark"
                    >
                      Geräte manuell hinzufügen
                    </Button>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>
                            TTSL CB{" "}
                            <small style={{ fontStyle: "italic" }}>
                              {" (Communication Bridge)"}
                            </small>
                          </th>
                          <th>
                            TTSL MB{" "}
                            <small style={{ fontStyle: "italic" }}>
                              {" (Measurement Bridge)"}
                            </small>
                          </th>
                          <th>
                            TTSL SB{" "}
                            <small style={{ fontStyle: "italic" }}>
                              {" (Sensor Bridge)"}
                            </small>
                          </th>
                          <th>Port</th>
                          <th>SID</th>
                          <th>Parameter</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Measurands &&
                        Array.isArray(Measurands) &&
                        Measurands.length > 0 ? (
                          Measurands.filter((c) => c.data.active == "1").map(
                            (mes) => {
                              return (
                                <MeasurandItem
                                  key={mes.measurand_identification_id}
                                  Measurand={mes}
                                  handleEdit={(newMes) => {
                                    dispatchMeasurands({
                                      type: "EDIT",
                                      element: newMes,
                                    });
                                  }}
                                  handleRemove={() => {
                                    dispatchMeasurands({
                                      type: "REMOVE",
                                      element: mes,
                                    });
                                  }}
                                />
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                                padding: 10,
                              }}
                              colSpan={8}
                            >
                              Keine Elemente zum Anzeigen
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12} lg={12} sm={12}>
              <div style={{ textAlign: "right", margin: 15, marginRight: 0 }}>
                {EditModalOpenMode == 2 && (
                  <div style={{ display: "inline", marginRight: 15 }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmArchiveModal(true);
                      }}
                      variant="dark"
                    >
                      Projekte abschließen
                    </Button>
                  </div>
                )}
                <Button type="submit" variant="primary" disabled={isSaving}>
                  {isSaving === false ? (
                    <>
                      {EditModalOpenMode == 2 ? "Speichern" : "Projekt anlegen"}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" animation="border" />
                      {"  Bitte warten..."}
                    </>
                  )}
                </Button>
                <p style={{ float: "left", color: "#f55" }}>
                  {validationMessage}
                </p>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      <Modal
        centered
        size="lg"
        show={showInsertSensorModal}
        onHide={() => {
          setInsertSensorModal(false);
        }}
        animation={false}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <Form.Label>DTB (Communication Bridge)</Form.Label>
                <Form.Group>
                  <Form.Control
                    as="select"
                    custom
                    className="form-control"
                    value={dtb ? dtb.id : 0}
                    onChange={(e) => {
                      setDTB(dtbs.find((c) => c.id === e.target.value));
                    }}
                  >
                    {injectOptions(dtbs)}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Label>MTB (Measurement Bridge)</Form.Label>
                <Form.Group>
                  <Form.Control
                    as="select"
                    custom
                    className="form-control"
                    value={mtb ? mtb.id : 0}
                    onChange={(e) => {
                      setMTB(mtbs.find((c) => c.id === e.target.value));
                    }}
                  >
                    {injectOptions(mtbs)}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Label>BWB (Sensor Bridge)</Form.Label>
                <Form.Group>
                  <Form.Control
                    as="select"
                    custom
                    className="form-control"
                    value={bwb ? bwb.id : 0}
                    onChange={(e) => {
                      setBWB(bwbs.find((c) => c.id === e.target.value));
                    }}
                  >
                    {injectOptions(bwbs)}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Label>Port</Form.Label>
                <Form.Group>
                  <Form.Control
                    as="select"
                    custom
                    className="form-control"
                    value={port}
                    onChange={(e) => {
                      setPORT(e.target.value);
                    }}
                  >
                    {ports.map((obj) => {
                      return (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Label>SID</Form.Label>
                <Form.Group>
                  <Form.Control
                    as="select"
                    custom
                    className="form-control"
                    value={sid}
                    onChange={(e) => {
                      setSID(e.target.value);
                    }}
                  >
                    {sids.map((obj) => {
                      return (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>

              {/* <Col md={2} lg={2} sm={12}>
            <Form.Label>Parameter</Form.Label>
            <Form.Group>
              <select
                className="form-control"
                value={parameter}
                onChange={(e) => {
                  setParameter(e.target.value);
                }}
              >
                {params.map((obj) => {
                  return (
                    <option key={obj} value={obj}>
                      {obj}
                    </option>
                  );
                })}
              </select>
            </Form.Group>
          </Col> */}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setInsertSensorModal(false);
            }}
            variant="light"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleInsert}
            disabled={canInsert === false}
            variant="info"
          >
            {canInsert ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa fa-plus" style={{ marginRight: 10 }} />
                {"Hinzufügen"}
              </div>
            ) : (
              <>
                <Spinner size="sm" animation="border" />
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        size="lg"
        show={showConfirmArchiveModal}
        onHide={() => {
          setShowConfirmArchiveModal(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="strong">
            <i className="fa fa-folder-open" style={{ marginLeft: 0 }} />
            Projekt abschließen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ marginBottom: 30 }}>
            <strong>Wollen Sie dieses Projekt abschließen?</strong>
          </h4>
          {/* <p style={{ marginBottom: 5 }}>
            Sie können{" "}
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowConfirmArchiveModal(false);
                setShowChangeEnddateModal(true);
              }}
              style={{ color: "#17a2b8", fontWeight: "bold" }}
            >
              das Enddatum manuell anpassen
            </Link>{" "}
            oder die Projekt unwiderbringlich löschen
          </p>
          <p>
            oder Sie können{" "}
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowConfirmArchiveModal(false);
                setShowConfirmDeleteModal(true);
              }}
              style={{ color: "#f00", fontWeight: "bold" }}
            >
              Projekt löschen
            </Link>
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleArchive} variant="primary">
            Ja
          </Button>
          <Button
            onClick={(e) => {
              setShowConfirmArchiveModal(false);
            }}
            variant="light"
          >
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        size="lg"
        show={showConfirmDeleteModal}
        onHide={() => {
          setShowConfirmDeleteModal(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="strong">
            <i className="fa fa-trash" style={{ marginLeft: 0 }} />
            Löschung bestätigen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Diese Aktion kann nicht rückgängig gemacht werden. Indem Sie
            bestätigen, wird dieses Projekt unwiederbringlich gelöscht.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} variant="danger">
            Ja, löschen
          </Button>
          <Button
            onClick={(e) => {
              setShowConfirmDeleteModal(false);
            }}
            variant="light"
          >
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        size="lg"
        show={showChangeEnddateModal}
        onHide={() => {
          setShowChangeEnddateModal(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="strong">
            <i className="fa fa-stopwatch" style={{ marginLeft: 0 }} />
            Projekt Enddatum einstellen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Bitte geben Sie das Enddatum ein</Form.Label>
          <Form.Group>
            <DatePicker
              locale={de}
              timeCaption="Uhrzeit"
              placeholderText=""
              showTimeSelect
              id="input-date"
              dateFormat="dd.MM.yyyy HH:mm"
              selected={endDate}
              minDate={startDate}
              onChange={(date) => setEndDate(date)}
              className="form-control txt-input"
              style={{ width: "100%" }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowChangeEnddateModal(false);
              setHasEndDate(true);
            }}
            variant="primary"
          >
            Speichern
          </Button>
          <Button
            onClick={(e) => {
              setShowChangeEnddateModal(false);
            }}
            variant="light"
          >
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        size="lg"
        show={showConfirmLocationModal}
        onHide={() => {
          setConfirmLocationModal(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="strong">Standort ändern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Möchten Sie den folgenden Standort übernehmen:{" "}
            <strong>{suggestedLocation}</strong> ?
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setConfirmLocationModal(false);
              setLocation(suggestedLocation);
            }}
            variant="primary"
          >
            Ja
          </Button>
          <Button
            onClick={(e) => {
              setConfirmLocationModal(false);
            }}
            variant="light"
          >
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        centered
        size="lg"
        show={showPreviewDeviceSetModal}
        onHide={() => {
          setShowPreviewDeviceSetModal(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="strong">
            <i className="fa fa-stopwatch" style={{ marginLeft: 0 }} />
            Projekt Enddatum einstellen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <DeviceSetPreview DevicesStatusList={previewDevicesTree}/>
        </Modal.Body>
      </Modal> */}
    </>
  );
  //#endregion
};

export default CreateGroupModel;
