import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  ToggleButton,
  ButtonGroup,
  InputGroup,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import * as moment from "moment";
import "moment/locale/de";
import "../../assets/css/modals.css";
import Axios from "axios";
import { api_base_url, api_headers } from "../../Configs/Configs";
import {
  DevicesContext,
  DevicesActionsContext,
} from "../../Contexts/DevicesContext";
import { UserContext } from "../../Contexts/UserContext";
import { NotificationContext } from "../../Contexts/NotificationContext";
import de from "date-fns/locale/de";
import { DeviceSetsContext } from "../../Contexts/DeviceSetsContext";
registerLocale("de", de);

const EditDeviceModal = ({
  handleClose,
  handleSuccessDeleted,
  handleUpdated,
}) => {
  const { ShowNotification } = useContext(NotificationContext);
  const { SelectedDevice, DevicesTreeList, AssociatedDevicesList } = useContext(
    DevicesContext
  );
  const { DeviceSetsList } = useContext(DeviceSetsContext);
  const { User, checkUserSession } = useContext(UserContext);
  const { dispatchDevice } = useContext(DevicesActionsContext);
  const [isSaving, setIsSaving] = useState(false);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showDeleteValidationModal, setShowDeleteValidationModal] = useState(
    false
  );

  const [validationMessage, setValidationMessage] = useState("");
  const [screenNo, setScreenNo] = useState(0);

  const [name, setName] = useState(SelectedDevice.name);
  const [deviceSetId, setDeviceSetId] = useState(SelectedDevice.device_set_id);
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(null);

  const [xfer, setXfer] = useState("6");
  const [slave, setSlave] = useState("3");
  const [tx, setTx] = useState("5");

  useEffect(() => {
    const fetchData = async () => {
      if (!checkUserSession()) return;
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDevices/read_information/${SelectedDevice.associated_id}`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          let device = response.data;
          setName(device.name);
          setDeviceSetId(SelectedDevice.device_set_id);
          if (device.device_info) {
            setEmail(device.device_info.notificationEmailAddress);
            if (
              device.device_type_id == 1 &&
              device.device_info.configurationParams &&
              device.device_info.configurationParams.mqd &&
              device.device_info.configurationParams.srd
            ) {
              let val =
                parseFloat(
                  device.device_info.configurationParams.mqd
                    .data_xfer_interval_ms
                ) / 1000;
              setXfer(val === "NaN" ? 0 : val);
              let sval =
                parseFloat(
                  device.device_info.configurationParams.srd
                    .m_slave_cmd_req_interval_ms
                ) / 1000;
              setSlave(sval === "NaN" ? 0 : sval);
            }
            if (
              device.device_type_id == 2 &&
              device.device_info.configurationParams &&
              device.device_info.configurationParams.bwb
            ) {
              let xval =
                parseFloat(
                  device.device_info.configurationParams.bwb
                    .slave_cmd_req_interval_ms
                ) / 1000;
              setSlave(xval === "NaN" ? 0 : xval);
            }
            if (
              device.device_info.configurationParams &&
              device.device_info.configurationParams.mqd &&
              device.device_info.configurationParams.mqd.txInterval
            ) {
              let x =
                parseFloat(
                  device.device_info.configurationParams.mqd.txInterval
                ) / 1000;
              setTx(x === "NaN" ? 0 : x);
            }
          }
          if (response.data.uts_start) {
            setStartDate(moment.unix(response.data.uts_start).toDate());
          }
        }
      });
    };

    fetchData();
  }, []);

  const handleEditDeviceName = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    // SelectedDevice["device_info"]["notificationEmailAddress"] = email;
    // SelectedDevice["uts_end"] = endDate ? moment.unix(endDate) : null;
    let newName = name;
    if (!name || name === "") {
      newName =
        (SelectedDevice.device_type.short
          ? SelectedDevice.device_type.short.slice(5)
          : SelectedDevice.device_type
          ? SelectedDevice.device_type.toUpperCase()
          : "") +
        " " +
        SelectedDevice.deviceId;
      setName(newName);
    }
    var formData = new FormData();
    formData.append("user_given_name", newName);
    formData.append("device_set_id", deviceSetId);
    await Axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/apiDevices/associated_properties_edited/${SelectedDevice.associated_id}`,
    })
      .then(async (response) => {
        if (response.data && response.status === 200) {
          // dispatchDevice({ type: "EDIT", device: SelectedDevice });
          if (
            deviceSetId != "null" &&
            deviceSetId != SelectedDevice.device_set_id
          ) {
            await handleAddToProject();
          }
          SelectedDevice["name"] = newName;
          SelectedDevice["device_set_id"] = deviceSetId;
          ShowNotification({
            type: "success",
            title: "Geräteinformationen wurden erfolgreich aktualisiert",
          });
          handleUpdated();
          handleClose();
        }
      })
      .catch(() => {
        setValidationMessage("");
      });
    setIsSaving(false);
  };

  const handleAddToProject = async () => {
    let set = DeviceSetsList.find((c) => c.id == deviceSetId);
    if (!set.project_id || set.project_id == "null") return;

    await Axios({
      method: "get",
      withCredentials: true,
      url: `${api_base_url}/apiMeasurementConfiguration/configuration/${set.project_id}`,
    }).then(async (response) => {
      if (
        response.data &&
        response.status === 200 &&
        response.data.customer_field_1 != "ARCHIV"
      ) {
        let project = response.data;
        // let measurands = response.data.measurands.filter((c) => c.active == 1);
        await Axios({
          method: "get",
          withCredentials: true,
          url: `${api_base_url}/apiMeasurementConfiguration/measurands/${User.id}`,
        }).then(async (response) => {
          if (response.data && response.status === 200) {
            if (
              response.data.status_ok &&
              Array.isArray(response.data.status_ok)
            ) {
              let newDeviceMeasurands = [];
              let status_ok = response.data.status_ok.find(
                (c) => c.deviceId == SelectedDevice.deviceId
              );
              if (
                status_ok &&
                status_ok.measurands &&
                Array.isArray(status_ok.measurands)
              ) {
                newDeviceMeasurands = newDeviceMeasurands.concat(
                  status_ok.measurands
                );
              }
              let actually_owned = response.data.actually_owned.find(
                (c) => c.deviceId == SelectedDevice.deviceId
              );
              if (
                actually_owned &&
                actually_owned.measurands &&
                Array.isArray(actually_owned.measurands)
              ) {
                newDeviceMeasurands = newDeviceMeasurands.concat(
                  actually_owned.measurands
                );
              }
              let associated = response.data.associated.find(
                (c) => c.deviceId == SelectedDevice.deviceId
              );
              if (
                associated &&
                associated.measurands &&
                Array.isArray(associated.measurands)
              ) {
                newDeviceMeasurands = newDeviceMeasurands.concat(
                  associated.measurands
                );
              }
              if (newDeviceMeasurands.length > 0) {
                let measurands_ = [];
                await Promise.all(
                  newDeviceMeasurands.map(async (newMeasurand) => {
                    let formData2 = new FormData();
                    for (var key in newMeasurand) {
                      formData2.append(key, newMeasurand[key]);
                    }
                    formData2.append("user_id", User.id);
                    await Axios({
                      method: "post",
                      withCredentials: true,
                      data: formData2,
                      url: `${api_base_url}/apiMeasurementConfiguration/measurand_identification_id/1`,
                    }).then(async (response2) => {
                      if (response2.data && response2.status === 200) {
                        measurands_.push(response2.data);
                      }
                    });
                  })
                );
                var formData = new FormData();
                formData.append("title", project.title);
                formData.append("cost_center", "");
                formData.append("branch_office", "");
                formData.append("customer_field_1", "");
                formData.append("customer_field_2", "");
                formData.append("customer_field_3", "");
                for await (let m of measurands_) {
                  formData.append("active[]", "1");
                  formData.append("measurand_display_id[]", 0);
                  formData.append("measurand_identification_id[]", m);
                }
                formData.append("user_id", User.id);
                await Axios({
                  method: "post",
                  data: formData,
                  withCredentials: true,
                  url: `${api_base_url}/apiMeasurementConfiguration/edit/${project.id}`,
                });
              }
            }
          }
        });
      }
    });
  };

  const handleDeleteDevice = (e) => {
    e.preventDefault();

    //validate device deleting
    let canDelete = true;
    if (SelectedDevice.device_type_id == 1) {
      //DTB device : check if this device has MTBs then prevent deleting
      let dtb = DevicesTreeList.find(
        (c) => c.deviceId == SelectedDevice.deviceId
      );
      if (dtb && dtb.mtbs && Array.isArray(dtb.mtbs) && dtb.mtbs.length > 0) {
        dtb.mtbs.forEach((mtb) => {
          if (AssociatedDevicesList.find((c) => c.deviceId == mtb.deviceId)) {
            canDelete = false;
            return;
          }
        });
      }
    }
    if (SelectedDevice.device_type_id == 2) {
      DevicesTreeList.forEach((dtb) => {
        if (dtb.mtbs && Array.isArray(dtb.mtbs)) {
          let mtb = dtb.mtbs.find((c) => c.deviceId == SelectedDevice.deviceId);
          //mtb device : check if this device has bwbs then prevent deleting
          if (
            mtb &&
            mtb.bwbs &&
            Array.isArray(mtb.bwbs) &&
            mtb.bwbs.length > 0
          ) {
            mtb.bwbs.forEach((bwb) => {
              if (AssociatedDevicesList.find((c) => c.deviceId == bwb.deviceId)) {
                canDelete = false;
                return;
              }
            });
          }
        }
      });
    }
    if (!canDelete) {
      setShowConfirmDeleteModal(false);
      setShowDeleteValidationModal(true);
      return;
    }

    Axios({
      method: "post",
      withCredentials: true,
      url: `${api_base_url}/apiDevices/remove_associate_user_device_by_device_id/${SelectedDevice.deviceId}/${User.id}`,
    })
      .then((response) => {
        if (response.data && response.status === 200) {
          ShowNotification({
            type: "success",
            title: "Gerät wurde erfolgreich gelöscht",
          });
          handleSuccessDeleted(SelectedDevice.associated_id);
        } else {
          ShowNotification({
            type: "error",
            title:
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal.",
          });
        }
      })
      .catch(() => {
        ShowNotification({
          type: "error",
          title:
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal.",
        });
      });
  };
  const handleEditDeviceProps = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("xfer", parseFloat(xfer) * 1000);
    formData.append("slave", parseFloat(slave) * 1000);
    formData.append("tx", parseFloat(tx) * 1000);
    Axios({
      method: "post",
      data: formData,
      withCredentials: true,
      url: `${api_base_url}/apiDevices/device_properties_edited/${SelectedDevice.associated_id}`,
    })
      .then((response) => {
        if (response.data && response.status === 200) {
          // dispatchDevice({ type: "EDIT", device: SelectedDevice });
          handleClose();
        } else {
          setValidationMessage("");
        }
      })
      .catch(() => {
        setValidationMessage("");
      });
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <ButtonGroup toggle>
          <ToggleButton
            type="radio"
            variant="primary"
            name="radio"
            checked={screenNo === 0}
            onChange={(e) => setScreenNo(0)}
          >
            Einstellungen
          </ToggleButton>
          {(SelectedDevice.device_type_id == 1 ||
            SelectedDevice.device_type_id == 2) && (
            <ToggleButton
              type="radio"
              variant="primary"
              name="radio"
              checked={screenNo === 1}
              onChange={(e) => setScreenNo(1)}
            >
              Erweitert
            </ToggleButton>
          )}
        </ButtonGroup>
        <Button
          onClick={() => {
            setShowConfirmDeleteModal(true);
          }}
          variant="danger"
          style={{ float: "right" }}
        >
          Gerät löschen
        </Button>
      </div>
      {screenNo === 0 && (
        <>
          <Form>
            <Container>
              <Row>
                <Col md={12}>
                  <Form.Label>Angezeigter Name</Form.Label>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      id="input-name"
                      type="input"
                      name="user-given_name"
                      value={name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {SelectedDevice.device_type_id == 1 && (
                <Row>
                  <Col md={12}>
                    <Form.Label>Geräte-Set</Form.Label>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        custom
                        onChange={(e) => {
                          setDeviceSetId(e.target.value);
                        }}
                        id="input-name"
                        type="input"
                        name="device_set_id"
                        value={deviceSetId}
                      >
                        <option value="null">Kein Geräte-Set auswählen</option>
                        {DeviceSetsList.map((deviceset) => {
                          return (
                            <option value={deviceset.id}>
                              {deviceset.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={12}>
                  <Form.Label>Startdatum</Form.Label>
                  <Form.Group>
                    <DatePicker
                      locale={de}
                      timeCaption="Uhrzeit"
                      placeholderText=""
                      showTimeSelect
                      disabled={true}
                      id="input-date"
                      dateFormat="dd.MM.yyyy HH:mm"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control txt-input"
                      style={{ width: "100%" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12} lg={12} sm={12}>
                  <div
                    style={{ textAlign: "right", margin: 15, marginRight: 0 }}
                  >
                    <button
                      onClick={handleEditDeviceName}
                      type="submit"
                      className={"btn btn-primary"}
                    >
                      Speichern
                    </button>
                    <p style={{ float: "left", color: "#f55" }}>
                      {validationMessage}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
          <Modal
            centered
            size="lg"
            show={showConfirmDeleteModal}
            onHide={() => {
              setShowConfirmDeleteModal(false);
            }}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-trash" style={{ marginLeft: 0 }} />
                Löschung bestätigen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Diese Aktion kann nicht rückgängig gemacht werden. Indem Sie
                bestätigen, wird dieses Gerät unwiederbringlich gelöscht.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleDeleteDevice} variant="danger">
                Ja, löschen
              </Button>
              <Button
                onClick={(e) => {
                  setShowConfirmDeleteModal(false);
                }}
                variant="light"
              >
                Abbrechen
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            centered
            size="lg"
            show={showDeleteValidationModal}
            onHide={() => {
              setShowDeleteValidationModal(false);
            }}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title as="strong">
                <i className="fa fa-info" style={{ marginLeft: 0 }} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Bevor Sie das Gerät entfernen können, müssen Sie die daran angeschlossenen Geräte zuerst entfernen!</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={(e) => {
                  setShowDeleteValidationModal(false);
                }}
                variant="light"
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {screenNo === 1 && (
        <Form>
          <Container>
            <Row>
              <Col md={12} lg={12} sm={12}>
                <Form.Label>Synchronisations-Intervall</Form.Label>
              </Col>
            </Row>
            <Row>
              {SelectedDevice.device_type_id == 1 && (
                <Col md={6} lg={6} sm={12}>
                  <Form.Group>
                    <Form.Label
                      as="small"
                      style={{ height: 50, fontStyle: "italic" }}
                    >
                      Häufigkeit der Synchronisationsintervalle zwischen der DTB
                      (Communication Bridge) und dem Server
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        id="xfer"
                        onChange={(e) => {
                          setXfer(e.target.value);
                        }}
                        type="number"
                        value={xfer}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">Sek</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              )}
              {(SelectedDevice.device_type_id == 1 ||
                SelectedDevice.device_type_id == 2) && (
                <Col md={6} lg={6} sm={12}>
                  <Form.Group>
                    <Form.Label
                      as="small"
                      style={{ height: 50, fontStyle: "italic" }}
                    >
                      {SelectedDevice.device_type_id == 1
                        ? "Nahbereichsfunk ISM"
                        : "Häufigkeit der Synchronisationsintervalle zwischen der MTB (Measurement Bridge) und BWB's (Sensor Bridge)"}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        onChange={(e) => {
                          setSlave(e.target.value);
                        }}
                        id="slave"
                        type="number"
                        value={slave}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">Sek</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              )}
              {SelectedDevice.device_type_id != 1 &&
                SelectedDevice.device_type_id != 2 && (
                  <Col md={6} lg={6} sm={12}>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          onChange={(e) => {
                            setTx(e.target.value);
                          }}
                          id="tx"
                          type="number"
                          value={tx}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2">
                            Sek
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                )}
            </Row>
            <hr />
            <Row>
              <Col md={12} lg={12} sm={12}>
                <div style={{ textAlign: "right", margin: 15, marginRight: 0 }}>
                  <button
                    disabled={isSaving}
                    onClick={handleEditDeviceProps}
                    type="submit"
                    className={"btn btn-primary"}
                  >
                    {isSaving ? (
                      <>
                        <Spinner size="sm" animation="border" />
                        {"  Bitte warten..."}
                      </>
                    ) : (
                      "Speichern"
                    )}
                  </button>
                  <p style={{ float: "left", color: "#f55" }}>
                    {validationMessage}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </div>
  );
};

export default EditDeviceModal;
