import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ButtonGroup,
  ToggleButton,
  Spinner,
} from "react-bootstrap";
import { api_base_url, api_headers } from "../../Configs/Configs";
import { NotificationContext } from "../../Contexts/NotificationContext";
import { UserContext } from "../../Contexts/UserContext";
import SensorDefaultValue from "./SensorDefaultValue";

const ResetPasswordModal = ({ handleClose, handleSuccess }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [validated, setValidated] = useState(false);

  const [Email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      //send reset password request
      setIsSaving(true);
      let formData = new FormData();
      formData.append("email", Email);
      Axios({
        method: "post",
        url: `${api_base_url}/login/send_new_password_email`,
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          if (response.status === 200 && response.data == false) {
            setValidationMessage("Email-Adresse ist nicht vorhanden!");
            setIsSaving(false);
            return;
          }
          if (response.status === 200 && response.data == true) {
            //successfull
            handleSuccess();
          }
          setIsSaving(false);
        })
        .catch(function (response) {
          setValidationMessage(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal."
          );
          setIsSaving(false);
          return;
        });
    }
  };

  return (
    <Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <Form.Label>Deine E-Mail Adresse:</Form.Label>
            <Form.Group>
              <Form.Control
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                name="Name"
                value={Email}
                bsClass="form-control txt-input"
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige E-Mail-Adresse ein.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            md={12}
            lg={12}
            sm={12}
          >
            <p>{validationMessage}</p>
            <Button disabled={isSaving} type="submit" className="btn-primary">
              {isSaving ? (
                <>
                  <Spinner size="sm" animation="border" />
                  {"  Bitte warten..."}
                </>
              ) : (
                "Zurücksetzen"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ResetPasswordModal;
