import React, {
  createContext,
  useState,
  useReducer,
  useEffect,
  useContext,
} from "react";
import Axios from "axios";
import { api_base_url, api_headers } from "../Configs/Configs";
import { UserContext } from "./UserContext";

export const GroupInfoModalContext = React.createContext();
export const DeviceStatusContext = React.createContext();

const DtbsReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.dtb];
    default:
      return state;
  }
};

const MeasurandReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.element];
    default:
      return state;
  }
};

export const GroupInfoModalContextProvider = (props) => {
  const { checkUserSession } = useContext(UserContext);
  const [IsLoading, setIsLoading] = useState(false);
  const [ThresholdsModalOpened, setThresholdsModalOpened] = useState(false);
  const [SelectedSensor, setSelectedSensor] = useState(null);
  const [DtbsList, addDtb] = useReducer(DtbsReducer, []);
  const [MeasurandsList, dispatchMeasurand] = useReducer(MeasurandReducer, []);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      if (!checkUserSession()) return;
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiMeasurementConfiguration/configuration/${props.DeviceGroup.id}`,
      }).then((response) => {
        if (response.data && response.status === 200) {
          var confGroup = response.data;

          var filtered = confGroup.measurands.filter(c=>c.active==1); //.filter(c=>c.bwb===Bwb.deviceId&&c.dtb==Dtb.deviceId&&c.mtb==Dtb.mtbs[0].deviceId);
          for (let index = 0; index < filtered.length; index++) {
            const element = filtered[index];
            // var port_2=filtered.find(c=>c.port!=element.port);
            // if(port_2){element['port_2']=port_2;filtered=filtered.filter(c=>c.id!=port_2.id);}
            dispatchMeasurand({ type: "ADD", element: element });
          }
        }
      });
      await Axios({
        method: "get",
        withCredentials: true,
        url: `${api_base_url}/apiDeviceConfiguration/device_overview/0/0?step=0`,
      }).then((response) => {
        if (
          response.data &&
          response.data.own_devices &&
          Array.isArray(response.data.own_devices) &&
          response.status === 200
        ) {
          response.data.own_devices.forEach((dtb) => {
            addDtb({ type: "ADD", dtb });
          });
        }
      });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <GroupInfoModalContext.Provider
      value={{
        IsLoading,
        setIsLoading,
        DtbsList,
        MeasurandsList,
        dispatchMeasurand,
        SelectedSensor,
        setSelectedSensor,
        ThresholdsModalOpened,
        setThresholdsModalOpened,
      }}
    >
      {props.children}
    </GroupInfoModalContext.Provider>
  );
};

export const DeviceStatusContextProvider = (props) => {
  return (
    <DeviceStatusContext.Provider value={{ Dtb: props.Dtb }}>
      {props.children}
    </DeviceStatusContext.Provider>
  );
};
