import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  let location = useLocation();
  return (
    <>{location.pathname!='/imprint'&&<div
      style={{
          padding:'0 100px 0 100px',
        height: 80,
        width:'100%',
        backgroundColor: "white",
        position: "absolute",
        bottom: 0,
      }}
      
    >
      <Row>
        <Col md={12}
          style={{
              height:'100%',
              paddingTop:20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            borderTop:'solid 1px #eee'
          }}
        >
          <p>
            <strong>Telefon: +49 2452 962 0</strong>
            {" | "} Mo - Do: 8:00 - 17:30 | Freitag: 8:00 - 17:00
          </p>
          <div> 
            <Link to="/imprint">Impressum</Link>
          </div>
        </Col>
      </Row>
    </div>}</>
  );
};

export default Footer;
